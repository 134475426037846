import { atom } from "jotai";
import { userAtom } from "../Atoms/UserAtom";
import UserProfileImage from "@assets/Images/user-profile.png";
import { getUserById } from "@/Queries/UserQueries";
import { handleReactQueryApiResponseWithJson } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";

export const getUserImage = atom((get) => {
  const user = get(userAtom);
  if (!user) return UserProfileImage;
  return user.image === "" ? UserProfileImage : `${process.env.REACT_APP_USER_API_ADDRESS}${user.image}`;
});

// 멘티에는 basic, standard, professional이 개인 요금제로 추가되고, 기존의 mentee는 단체 요금제이다.
export const getUserPosition = atom((get) => {
  const user = get(userAtom);
  if (!user) return "멘티";
  switch (user.membership) {
    case "mentor":
      return "멘토";
    case "admin":
      return "관리자";
    case "mentee":
      return "멘티";
    case "teacher":
      return "교사";
    case "free":
      return "무료";
    case "basic":
      return "basic";
    case "standard":
      return "standard";
    case "professional":
      return "professional";
    default:
      return "멘티";
  }
});

export const getUserMembershipAtom = atom((get) => {
  const user = get(userAtom);
  if (!user) return "mentee";
  return user.membership;
});

export const getUserId = atom((get) => {
  const user = get(userAtom);
  if (!user) return 0;
  return user.id;
});

export const getUserNickname = atom((get) => {
  const user = get(userAtom);
  if (!user) return "";
  return user.nickname;
});

export const getUserEmailAtom = atom((get) => {
  const user = get(userAtom);
  if (!user) return "";
  return user.email;
})

export const userLanguageAtom = atom((get): "KR" | "US" => {
  const user = get(userAtom);
  if (!user) return "KR";
  return user.language;
}, (get, set, language: "KR" | "US") => {
  const user = get(userAtom);
  if (!user) return;
  set(userAtom, { ...user, language });
})

export const getUserGroupsAtom = atom((get) => {
  const user = get(userAtom);
  if (!user) return [];
  return user.groups;
});

export const userMembershipEndDateAtom = atom((get) => {
  const user = get(userAtom);
  if (!user) return new Date();
  if (user.groups.length === 0) return user.membership_end_date;
  const groupsSubscriptionEndDate = user.groups.map(group => group.subscriptionEndDate);
  groupsSubscriptionEndDate.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
  return new Date(groupsSubscriptionEndDate[0]);
});

// userId를 통해 user 정보를 가져올 수 있도록 하는 atom
export const getUserByIdAtom = atom(null, async (get, set, userId: number) => {
  const handleError401Modal = () => set(error401ModalAtom, true);
  const response = await handleReactQueryApiResponseWithJson(getUserById, handleError401Modal, userId);
  if (!response) return null;
  const user = {
    id: response.results.user_id,
    email: response.results.email,
    nickname: response.results.nickname,
    membership: response.results.membership,
    image: response.results.image_url,
    language: "KR" as "KR" | "US",
    membership_end_date: new Date(response.results.end_date),
    groups: [],
  }
  set(userAtom, user);
});
