import theme from "@/Styles/theme";
import { lighten } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  width: 100%;
  height: auto;
  border: 1px solid #c0c0c0;
  justify-content: space-around;
  border-radius: 5px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: ${theme.colors.primary};
`;

export const GridItem = {
  border: "1px solid #c0c0c0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "10px",
  borderRadius: "5px",
  padding: "5px",
  cursor: "pointer",

  "&:hover": {
    border: "1px solid #000",
    backgroundColor: "#f0f0f0",
  },
};

export const Image = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MoreButton = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: ${lighten(0.1, theme.colors.primary)};
  }
`;
