import React, { useEffect } from "react";
import * as S from "./styled";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  connectedRoutinesAtom,
  createTaskInRoutineStateAtom,
  routineSidebarAtom,
  RoutineSidebarProps,
} from "@/Atoms/Plan/RoutineAtom";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  Popover,
  TextField,
} from "@mui/material";
import { ChromePicker } from "react-color";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "@/Components/Common/CustomDatePicker";
import TitleValueInEachRow from "../Common/TitleValueInEachRow";
import TitleValueInARow from "../Common/TitleValueInARow";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import {
  createNewTaskToRoutineAtom,
  createTaskInRoutineCreateTaskAtom,
  createTaskInRoutineEditRoutineApplyAtom,
  createTaskInRoutineEditRoutineClearStateAtom,
  createTaskInRoutineEditRoutineDataAtom,
  createTaskInRoutineEditRoutineStartAtom,
} from "@/ViewModels/Plan/Routine/CreateTaskViewModel";
import theme from "@/Styles/theme";
import { getTextColorBasedOnBackground } from "@/Utils/GetRandomColor";
import { getRoutinesInAutocompleteAtom } from "@/ViewModels/Plan/Routine/DataViewModel";
import { openCreateRoutineInRoutineModalAtom } from "@/ViewModels/Plan/Routine/CreateRoutineViewModel";

const weekDays = ["월", "화", "수", "목", "금", "토", "일"];

// interface GetRoutineInAutocompleteAtomProps {
//   id?: number;
//   label: string;
//   isCreate: boolean;
//   color: string;
// }

interface GetRoutineInAutocompleteAtomProps {
  id?: string;
  backendId?: number;
  label: string;
  isCreate: boolean;
  color: string;
}

interface Props {
  data: RoutineSidebarProps;
}

const filter = createFilterOptions<GetRoutineInAutocompleteAtomProps>();

const CreateTaskInRoutine = ({ data }: Props) => {
  // const sidebar = useAtomValue(routineSidebarAtom);
  // const data = sidebar[sidebar.length - 1];
  const [createTaskState, setCreateTaskState] = useAtom(
    createTaskInRoutineStateAtom
  );
  const { t } = useTranslation();
  const [editRoutineData, setEditRoutineData] = useAtom(
    createTaskInRoutineEditRoutineDataAtom
  );
  const editRoutineStart = useSetAtom(createTaskInRoutineEditRoutineStartAtom);
  const editRoutineEnd = useSetAtom(
    createTaskInRoutineEditRoutineClearStateAtom
  );
  const saveTermData = useSetAtom(createTaskInRoutineEditRoutineApplyAtom);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const routines = useAtomValue(getRoutinesInAutocompleteAtom);
  const [value, setValue] =
    React.useState<GetRoutineInAutocompleteAtomProps | null>(null);
  const openCreateRoutine = useSetAtom(openCreateRoutineInRoutineModalAtom);
  const [connectedRoutines, setConnectedRoutines] = useAtom(
    connectedRoutinesAtom
  );
  const textFieldRef = React.useRef<HTMLInputElement | null>(null);
  const createTask = useSetAtom(createTaskInRoutineCreateTaskAtom);
  const createTaskInRoutine = useSetAtom(createNewTaskToRoutineAtom);

  useEffect(() => {
    if (connectedRoutines && connectedRoutines.length >= 1) {
      setValue({
        ...connectedRoutines[0],
        isCreate: false,
      });
    }
  }, [connectedRoutines]);

  const addHashtag = (tag: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }) => {
    setCreateTaskState((prev) => ({
      ...prev,
      hashtags: [...prev.hashtags, tag],
    }));
  };

  const removeHashtag = () => {
    setCreateTaskState((prev) => ({
      ...prev,
      hashtags: prev.hashtags.slice(0, prev.hashtags.length - 1),
    }));
  };

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault();
      const textColor = getTextColorBasedOnBackground(
        createTaskState.color || theme.colors.primary
      );
      if (
        !createTaskState.hashtags.find((tag) => tag.value === inputValue.trim())
      ) {
        addHashtag({
          backgroundColor: createTaskState.color || theme.colors.primary,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === "Backspace" && inputValue === "") {
      if (createTaskState.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag();
      }
    }
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (field: string, value: any) => {
    setCreateTaskState((prev) => ({ ...prev, [field]: value }));
  };

  const handleDateUpdate = (date: Dayjs | null, type: "start" | "end") => {
    setCreateTaskState((prev) => {
      if (type === "start") {
        let termData = prev.termData;

        const tempDate = dayjs(date?.toISOString()).locale("ko");

        if (
          prev.termType === 1 &&
          prev.termData &&
          (!prev.termData || prev.termData?.length <= 1)
        ) {
          const weekday = tempDate.format("ddd");
          termData = [weekday];
        } else if (
          prev.termType === 2 &&
          (!prev.termData || prev.termData?.length <= 1)
        ) {
          const dayOfMonth = tempDate.format("D");
          termData = [dayOfMonth];
        }

        return {
          ...prev,
          startDate: date ? date : null,
          termData: date ? termData : null,
        };
      } else if (type === "end") {
        return {
          ...prev,
          endDate: date ? date.endOf("day") : null,
        };
      }

      return prev;
    });
  };

  const handleRoutineOptionChange = (key: number) => {
    setCreateTaskState((prev) => {
      let updatedNode;

      if (prev.termType === key) {
        updatedNode = {
          ...prev,
          termType: null,
          termData: null,
        };

        return updatedNode;
      } else {
        let termData = null;

        if (prev.startDate) {
          const date = dayjs(prev.startDate).locale("ko");

          if (key === 1) {
            const weekday = date.format("ddd");
            termData = [weekday];
          } else if (key === 2) {
            const dayOfMonth = date.format("D");
            termData = [dayOfMonth];
          }
        }

        updatedNode = {
          ...prev,
          termType: key,
          termData: termData,
        };

        return updatedNode;
      }
    });
  };

  const shouldDisableDateInStartDate = (day: dayjs.Dayjs) => {
    // 오늘 이전 날짜 비활성화 + endDate 이후 날짜 비활성화
    const isBeforeToday = day.isBefore(dayjs(), "day"); // 오늘 이전 날짜
    const isAfterEndDate = createTaskState.endDate
      ? day.isAfter(createTaskState.endDate, "day")
      : false; // endDate 이후 날짜
    return isBeforeToday || isAfterEndDate;
  };

  const shouldDisableDateInEndDate = (day: dayjs.Dayjs) => {
    // 오늘 이전 날짜 비활성화 + startDate 이전 날짜 비활성화
    const isBeforeToday = day.isBefore(dayjs(), "day"); // 오늘 이전 날짜
    const isBeforeStartDate = createTaskState.startDate
      ? day.isBefore(createTaskState.startDate, "day")
      : false; // startDate 이전 날짜
    return isBeforeToday || isBeforeStartDate;
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        position: "relative",
      }}
    >
      {/* 할 일 이름 */}
      <S.CustomInput
        placeholder={t("plan.contents.routine.sidebar.content.taskLabel")}
        value={createTaskState.label}
        onChange={(e) => handleInputChange("label", e.target.value)}
      />

      {/* 일정 */}
      <CustomDatePicker
        label={t("plan.contents.routine.sidebar.content.startDate")}
        value={createTaskState.startDate}
        onChange={(date) => handleDateUpdate(date, "start")}
        shouldDisableDate={shouldDisableDateInStartDate}
      />
      <CustomDatePicker
        label={t("plan.contents.routine.sidebar.content.endDate")}
        value={createTaskState.endDate}
        onChange={(date) => handleDateUpdate(date, "end")}
        shouldDisableDate={shouldDisableDateInEndDate}
      />

      {/* 시간 설정 */}
      <TitleValueInEachRow
        title={t("plan.contents.routine.sidebar.content.setTime")}
        value={
          <div
            style={{
              width: "100%",
              height: "50px",
              overflowX: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {Array.from({ length: 24 }).map((_, index) => (
              <div
                key={index}
                style={{
                  minWidth: "30px",
                  height: "100%",
                  fontSize: "10px",
                  fontWeight: "bold",
                  backgroundColor: createTaskState.times.includes(
                    index.toString()
                  )
                    ? theme.colors.primary
                    : "#e9e9e9",
                  color: createTaskState.times.includes(index.toString())
                    ? "white"
                    : "black",
                }}
                onClick={() => {
                  const newTimes = createTaskState.times.includes(
                    index.toString()
                  )
                    ? createTaskState.times.filter(
                        (time) => time !== index.toString()
                      )
                    : [...createTaskState.times, index.toString()];
                  handleInputChange("times", newTimes);
                }}
              >
                {index}
              </div>
            ))}
          </div>
        }
      />

      {/* 반복 주기 */}
      <TitleValueInARow
        title={t("plan.contents.routine.sidebar.content.repeat")}
        value={
          <S.StyledToggleButtonGroup
            exclusive
            value={createTaskState.termType}
            // onChange={(event, newViewType) =>
            //   handleInputChange("termType", newViewType)
            // }
          >
            <S.StyledToggleButton
              value={0}
              selected={createTaskState.termType === 0}
              onClick={() => handleRoutineOptionChange(0)}
            >
              {t("dialogs.task.create.routine.daily")}
            </S.StyledToggleButton>
            <S.StyledToggleButton
              value={1}
              selected={createTaskState.termType === 1}
              onClick={() => handleRoutineOptionChange(1)}
            >
              {t("dialogs.task.create.routine.weekly")}
            </S.StyledToggleButton>
            <S.StyledToggleButton
              value={2}
              selected={createTaskState.termType === 2}
              onClick={() => handleRoutineOptionChange(2)}
            >
              {t("dialogs.task.create.routine.monthly")}
            </S.StyledToggleButton>
          </S.StyledToggleButtonGroup>
        }
      />

      {/* 반복 요일 */}
      {editRoutineData.termType === null &&
        createTaskState.termType !== null && (
          <TitleValueInARow
            title={
              createTaskState.termType === 0
                ? t("plan.contents.routine.sidebar.content.everyday")
                : createTaskState.termData &&
                  createTaskState.termData.length > 0
                ? createTaskState.termType === 1
                  ? t("plan.contents.routine.sidebar.content.everyweek")
                  : createTaskState.termType === 2
                  ? t("plan.contents.routine.sidebar.content.everymonth")
                  : t("plan.contents.routine.sidebar.content.repeat2")
                : t("plan.contents.routine.sidebar.content.repeat2")
            }
            value={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  flex: 1,
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {createTaskState.termData?.map((interval: string) => (
                  <div
                    key={interval}
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    {createTaskState.termType === 1
                      ? t(`dialogs.task.create.routine.${interval}`)
                      : `${interval}`}
                  </div>
                ))}
                {(createTaskState.termType === 1 ||
                  createTaskState.termType === 2) && (
                  <CustomColorButton
                    text={t("plan.contents.routine.sidebar.content.edit2")}
                    fontSize="14px"
                    onClick={editRoutineStart}
                  />
                )}
              </div>
            }
          />
        )}

      {/* 반복 주기 수정 */}
      {editRoutineData.termType !== null && (
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              fontSize: "14px",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            {createTaskState.termType &&
              (createTaskState.termType === 1
                ? t("plan.contents.routine.sidebar.content.everyweek")
                : t("plan.contents.routine.sidebar.content.everymonth"))}
          </div>
          {createTaskState.termType === 1 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {weekDays.map((day) => (
                <div
                  key={day}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: editRoutineData.termData?.includes(day)
                      ? theme.colors.primary
                      : "white",
                    borderRadius: "50%",
                    color: editRoutineData.termData?.includes(day)
                      ? "white"
                      : "black",
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    justifyContent: "center",
                  }}
                  onClick={() => setEditRoutineData(day)}
                >
                  {t(`dialogs.task.create.routine.${day}`)}
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                gap: "5px",
              }}
            >
              {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                <div
                  key={day}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: editRoutineData.termData?.includes(
                      day.toString()
                    )
                      ? theme.colors.primary
                      : "white",
                    borderRadius: "50%",
                    color: editRoutineData.termData?.includes(day.toString())
                      ? "white"
                      : "black",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    justifyContent: "center",
                  }}
                  onClick={() => setEditRoutineData(day.toString())}
                >
                  {day}
                </div>
              ))}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "flex-end",
            }}
          >
            <CustomColorButton
              text={t("plan.contents.routine.sidebar.content.cancel")}
              fontSize="14px"
              onClick={editRoutineEnd}
              color="#999"
            />
            <CustomColorButton
              text={t("plan.contents.routine.sidebar.content.save")}
              fontSize="14px"
              onClick={saveTermData}
            />
          </div>
        </div>
      )}

      {/* 색상 선택 */}
      <TitleValueInARow
        title={t("plan.contents.routine.sidebar.content.color")}
        value={
          <div
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: createTaskState.color || theme.colors.primary,
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onClick={handlePopoverOpen}
          />
        }
      />
      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handlePopoverClose}
      >
        <ChromePicker
          color={createTaskState.color}
          onChangeComplete={(color) => handleInputChange("color", color.hex)}
        />
      </Popover>

      {/* 해시태그 */}
      <TitleValueInEachRow
        title={t("plan.contents.routine.sidebar.content.hashtag")}
        value={
          <S.TagInputContainer>
            {createTaskState.hashtags.map((tag, idx) => {
              const backgroundColor =
                createTaskState.color || theme.colors.primary;
              const textColor = getTextColorBasedOnBackground(backgroundColor);
              return (
                <S.TagItem
                  key={idx}
                  style={{ backgroundColor: backgroundColor, color: textColor }}
                >
                  <span>{tag.value}</span>
                </S.TagItem>
              );
            })}
            <S.TagInput
              placeholder={
                createTaskState.hashtags.length === 0
                  ? t("dialogs.task.create.hashtagPlaceholder")
                  : ""
              }
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </S.TagInputContainer>
        }
      />

      {data.type === "createTask" && (
        <>
          {/* 루틴 선택 */}
          <TitleValueInARow
            title={t("plan.contents.routine.sidebar.content.addToRoutine")}
            value={
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  if (
                    newValue &&
                    typeof newValue !== "string" &&
                    newValue.isCreate
                  ) {
                    const extractedName = newValue.label
                      .replace(/["'](.+?)["'](?: 루틴 만들기| Routine)$/, "$1")
                      .replace("Create ", "");
                    openCreateRoutine(extractedName);
                  } else if (newValue && typeof newValue !== "string") {
                    if (newValue.id) {
                      setConnectedRoutines((prev) => [
                        {
                          id: newValue.id as string,
                          backendId: newValue.backendId as number,
                          label: newValue.label,
                          color: newValue.color,
                        },
                      ]);

                      setValue(newValue); // 선택된 값 업데이트
                    }

                    // if (textFieldRef.current) {
                    //   textFieldRef.current.value = "";
                    //   setTimeout(() => setValue(null), 1000);
                    // }
                  } else {
                    setValue(null);
                    setConnectedRoutines([]);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );

                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      label: t(
                        "plan.contents.routine.sidebar.content.makeARoutine",
                        { name: inputValue }
                      ),
                      isCreate: true,
                      color: theme.colors.primary,
                    });
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="routine-autocomplete"
                options={routines}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.label
                }
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                freeSolo
                renderInput={(params) => (
                  <S.CustomTextField
                    {...params}
                    label={t(
                      "plan.contents.routine.sidebar.content.selectRoutine"
                    )}
                    variant="standard"
                    inputRef={textFieldRef}
                    value={value ? value.label : ""} // 선택된 값이 있을 경우 label 표시
                  />
                )}
                sx={{ width: 200 }}
              />
            }
          />

          {/* {connectedRoutines.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              {connectedRoutines.map((routine) => {
                const textColor = getTextColorBasedOnBackground(
                  routine.color || theme.colors.primary
                );
                return (
                  <S.TagItem
                    key={routine.id}
                    style={{ backgroundColor: routine.color, color: textColor }}
                  >
                    <span>{routine.label}</span>
                    <Close
                      sx={{ cursor: "pointer", width: "15px", height: "15px" }}
                      onClick={() =>
                        setConnectedRoutines((prev) =>
                          prev.filter((r) => r.id !== routine.id)
                        )
                      }
                    />
                  </S.TagItem>
                );
              })}
            </div>
          )} */}
        </>
      )}

      <TitleValueInEachRow
        title={t("plan.contents.routine.sidebar.content.content")}
        value={
          <S.CustomInputField
            placeholder={t("plan.contents.routine.sidebar.content.content")}
            value={createTaskState.content}
            onChange={(e) => handleInputChange("content", e.target.value)}
          />
        }
      />

      <div style={{ width: "100%", minHeight: "40px" }} />

      {/* 버튼 - 하단 고정  */}
      <Button
        variant="contained"
        style={{
          backgroundColor: createTaskState.isLoading
            ? "#ccc"
            : theme.colors.primary,
          color: "white",
          fontWeight: "bold",
          cursor: "pointer",
          width: "calc(100% - 40px)",
          position: "fixed",
          bottom: "20px",
        }}
        disabled={createTaskState.isLoading}
        onClick={
          data.type === "createTask"
            ? () => createTask(t)
            : () => createTaskInRoutine(t)
        }
      >
        {t("plan.contents.routine.sidebar.content.add")}
      </Button>
    </div>
  );
};

export default CreateTaskInRoutine;
