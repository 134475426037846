import {
  chatRoomListAtom,
  currentChatRoomAtom,
  isChatbotTypingAtom,
} from "@/Atoms/ChatAtom";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import {
  chatInputValueAtom,
  chattingTypeAtom,
  ChatType,
  currentSearchIndexAtom,
  isAIMakingResponseAtom,
  isChattingAskAtom,
  isOpenRecommendQuestionAtom,
  searchInputValueAtom,
} from "@/Atoms/DokgabiChat/Atom";
import { requestChatbot } from "@/Queries/ChatbotQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import dayjs from "dayjs";
import { atom } from "jotai";
import { getUserEmailAtom } from "../UserViewModel";

export const InputMessageHandleCloseAndChangeTypeAtom = atom(
  null,
  (get, set, type: ChatType) => {
    const chatType = get(chattingTypeAtom);
    if (chatType !== type) {
      set(isChattingAskAtom, null);
      set(chattingTypeAtom, type);
      set(currentSearchIndexAtom, 0);
    }
  }
);

export const InputMessageHandleInputValueAtom = atom(
  null,
  (get, set, value: string) => {
    const chatType = get(chattingTypeAtom);

    if (chatType === ChatType.ASK) set(chatInputValueAtom, value);
    else {
      set(currentSearchIndexAtom, 0);
      set(searchInputValueAtom, value);
    }
  }
);

const updateUserChatMessageAtom = atom(
  null,
  async (get, set, message: string) => {
    set(currentChatRoomAtom, (prev) => {
      if (!prev) return prev;
      const nextChatId = prev.messages.length;
      const userMessage = {
        id: nextChatId,
        text: message,
        type: "user" as const,
        jobButtons: null,
        date: dayjs().format("MM.DD HH:mm"),
      };
      return { ...prev, messages: [...prev.messages, userMessage] };
    });
  }
);

export const InputMessageHandleInputFieldKeyDownAtom = atom(
  null,
  async (
    get,
    set,
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const inputValue = get(chatInputValueAtom).trim();
      if (inputValue === "") return;

      const currentChatRoom = get(currentChatRoomAtom);
      const chatRoomList = get(chatRoomListAtom);

      const firstChatBot =
        chatRoomList[currentChatRoom ? currentChatRoom.id : 0].chatbots[0];
      set(isAIMakingResponseAtom, true);

      if (firstChatBot) {
        set(updateUserChatMessageAtom, inputValue);
        set(chatInputValueAtom, "");
        set(isOpenRecommendQuestionAtom, false);

        // // 여기에서 backend랑 통신을 하도록 하자.
        // const handleStreamMessage = (
        //   message: string,
        //   isFirst: boolean,
        //   isNotStream: boolean,
        //   flow_socket_name: string
        // ) => {
        //   const currentChatbot = get(currentChatbotAtom);

        //   if (
        //     currentChatbot &&
        //     currentChatbot.chatbot_socket_parameter !== flow_socket_name
        //   )
        //     return;
        //   set(chattingListAtom, (current) => {
        //     const lastItem = current[current.length - 1];

        //     if (isFirst) set(scrollStateAtom, true);
        //     if (lastItem) {
        //       if (lastItem.chattingUser === "user") {
        //         return [
        //           ...current,
        //           {
        //             chattingUser: "chatbot",
        //             text: message,
        //             date: dayjs().format("MM.DD HH:mm"),
        //             image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${currentChatbot!.character_image!.file_path
        //               }`,
        //             name: currentChatbot!.name,
        //           },
        //         ];
        //       } else {
        //         return [
        //           ...current.slice(0, -1),
        //           {
        //             ...lastItem,
        //             text: message,
        //           },
        //         ];
        //       }
        //     }

        //     return current;
        //   });
        // };
        // 여기에서 backend랑 통신을 하도록 하자.
        const handleStreamMessage = (
          message: string,
          isFirst: boolean,
          character_image: string
        ) => {
          if (isFirst)
            set(currentChatRoomAtom, (prev) => {
              if (!prev) return prev;
              return {
                ...prev,
                messages: [
                  ...prev.messages,
                  {
                    id: prev.messages.length,
                    text: message,
                    type: "chatbot",
                    jobButtons: null,
                    date: new Date().toISOString(),
                  },
                ],
              };
            });
          else {
            set(currentChatRoomAtom, (prev) => {
              if (!prev) return prev;

              const lastItem = prev.messages[prev.messages.length - 1]; // 마지막 메시지 가져오기

              if (lastItem && lastItem.type === "chatbot") {
                return {
                  ...prev,
                  messages: [
                    ...prev.messages.slice(0, -1), // 마지막 메시지 이전의 메시지들은 그대로
                    {
                      ...lastItem,
                      text: `${message}`, // 마지막 메시지의 텍스트에 새 메시지 추가
                    },
                  ],
                };
              }

              // lastItem이 없거나 type이 "chatbot"이 아닌 경우, 새 메시지
              return {
                ...prev,
                messages: [
                  ...prev.messages,
                  {
                    id: prev.messages.length,
                    text: message, // 새로운 메시지 텍스트
                    type: "chatbot",
                    jobButtons: null,
                    date: new Date().toISOString(),
                  },
                ],
              };
            });
          }
        };

        const handleUsingDocs = (data: { job: string }[]) => {
          if (data.length === 0) return;
          if ((data as unknown as string).includes("오류")) return;
          setTimeout(() => {
            set(currentChatRoomAtom, (prev) => {
              if (!prev) return prev;
              return {
                ...prev,
                messages: [
                  ...prev.messages,
                  {
                    id: prev.messages.length,
                    text: "아래 직업들을 추천해드릴게요! 눌러서 확인해보세요.",
                    type: "system",
                    jobButtons: data
                      ? data.map((job: { job: string }) => job.job)
                      : [],
                    date: new Date().toISOString(),
                  },
                ],
              };
            });
          }, 100);
        };

        const handleError401Modal = () => set(error401ModalAtom, true);
        set(isChatbotTypingAtom, true);

        await handleReactQueryApiResponse(
          requestChatbot,
          handleError401Modal,
          inputValue,
          get(getUserEmailAtom),
          firstChatBot.flow,
          "",
          handleStreamMessage,
          handleUsingDocs
        );
      }

      set(isAIMakingResponseAtom, false);
      set(isChatbotTypingAtom, false);
    }
  }
);

export const InputMessageHandleSendBtnAtom = atom(null, async (get, set) => {
  const inputValue = get(chatInputValueAtom).trim();
  if (inputValue === "") return;

  const currentChatRoom = get(currentChatRoomAtom);
  const chatRoomList = get(chatRoomListAtom);

  const firstChatBot =
    chatRoomList[currentChatRoom ? currentChatRoom.id : 0].chatbots[0];

  set(isAIMakingResponseAtom, true);

  if (firstChatBot) {
    // const newMessage = {
    //   name: flow.name,
    //   image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow!.character_image!.file_path}`,
    //   text: inputValue,
    //   date: dayjs().format("MM.DD HH:mm"),
    //   chattingUser: "user",
    // };

    // set(chattingListAtom, (prevChatting) => [...prevChatting, newMessage]);
    set(updateUserChatMessageAtom, inputValue);
    set(chatInputValueAtom, "");
    set(isOpenRecommendQuestionAtom, false);

    // set(chattingListAtom, (current) => [
    //   ...current,
    //   {
    //     name: flow.name,
    //     image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${flow!.character_image!.file_path
    //       }`,
    //     text: "",
    //     date: dayjs().format("MM.DD HH:mm"),
    //     chattingUser: "chatbot",
    //   },
    // ]);

    // 여기에서 backend랑 통신을 하도록 하자.
    const handleStreamMessage = (
      message: string,
      isFirst: boolean,
      character_image: string
    ) => {
      if (isFirst)
        set(currentChatRoomAtom, (prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            messages: [
              ...prev.messages,
              {
                id: prev.messages.length,
                text: message,
                type: "chatbot",
                jobButtons: null,
                date: new Date().toISOString(),
              },
            ],
          };
        });
      else {
        set(currentChatRoomAtom, (prev) => {
          if (!prev) return prev;

          const lastItem = prev.messages[prev.messages.length - 1]; // 마지막 메시지 가져오기

          if (lastItem && lastItem.type === "chatbot") {
            return {
              ...prev,
              messages: [
                ...prev.messages.slice(0, -1), // 마지막 메시지 이전의 메시지들은 그대로
                {
                  ...lastItem,
                  text: `${message}`, // 마지막 메시지의 텍스트에 새 메시지 추가
                },
              ],
            };
          }

          // lastItem이 없거나 type이 "chatbot"이 아닌 경우, 새 메시지
          return {
            ...prev,
            messages: [
              ...prev.messages,
              {
                id: prev.messages.length,
                text: message, // 새로운 메시지 텍스트
                type: "chatbot",
                jobButtons: null,
                date: new Date().toISOString(),
              },
            ],
          };
        });
      }
    };

    const handleUsingDocs = (data: { job: string }[]) => {
      if (data.length === 0) return;
      if ((data as unknown as string).includes("오류")) return;
      setTimeout(() => {
        set(currentChatRoomAtom, (prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            messages: [
              ...prev.messages,
              {
                id: prev.messages.length,
                text: "아래 직업들을 추천해드릴게요! 눌러서 확인해보세요.",
                type: "system",
                jobButtons: data
                  ? data.map((job: { job: string }) => job.job)
                  : [],
                date: new Date().toISOString(),
              },
            ],
          };
        });
      }, 100);
    };

    const handleError401Modal = () => set(error401ModalAtom, true);
    set(isChatbotTypingAtom, true);

    await handleReactQueryApiResponse(
      requestChatbot,
      handleError401Modal,
      inputValue,
      get(getUserEmailAtom),
      firstChatBot.flow,
      "",
      handleStreamMessage,
      handleUsingDocs
    );
  }

  set(isAIMakingResponseAtom, false);
  set(isChatbotTypingAtom, false);
});

export const InputMessageWhenDownButtonClickAtom = atom(null, (get, set) => {
  const currentIdx = get(currentSearchIndexAtom);
  const highlightsLength = document.getElementsByClassName(
    "highlight-search-field"
  ).length;

  setTimeout(() => {
    if (currentIdx + 1 < highlightsLength) {
      // currentIdx가 highlight 길이를 넘지 않도록
      const firstHighlight = document.getElementsByClassName(
        "highlight-search-field"
      )[currentIdx + 1];
      if (firstHighlight) {
        firstHighlight.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      set(currentSearchIndexAtom, (current) => current + 1);
    }
  }, 100);
});

export const InputMessageWhenUpButtonClickAtom = atom(null, (get, set) => {
  const currentIdx = get(currentSearchIndexAtom);

  setTimeout(() => {
    const firstHighlight = document.getElementsByClassName(
      "highlight-search-field"
    )[currentIdx - 1];
    if (firstHighlight) {
      firstHighlight.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    set(currentSearchIndexAtom, (current) =>
      current !== 0 ? current - 1 : current
    );
  }, 100);
});
