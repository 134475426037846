import { selectedNodeAtom } from "@/Atoms/Plan/MindmapAtom";
import useIsMobile from "@/Hooks/useIsMobile";
import theme from "@/Styles/theme";
import { updateNodeAtom } from "@/ViewModels/Plan/Mindmap/FlowViewModel";
import { detachNodeInGroupAtom } from "@/ViewModels/Plan/Mindmap/GroupViewModel";
import {
  addHashtagNodeAtom,
  removeHashtagNodeAtom,
  updateTaskStatusNodeAtom,
} from "@/ViewModels/Plan/Mindmap/JobNodeViewModel";
import { checkPlanUserIdAtom } from "@/ViewModels/Plan/PlanViewModel";
import {
  Box,
  Button,
  Input,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { NodeToolbar, Position } from "@xyflow/react";
import dayjs, { Dayjs } from "dayjs";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import * as S from "./styled";
import TextMarkdown from "@/Components/Common/Markdown";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import { textEditorOpenAtom } from "@/ViewModels/Dialogs/TextEditorViewModel";
import { languageAtom } from "@/Atoms/RootAtom";
import { editRoutineIntervalsDialogAtom } from "@/Atoms/Dialogs/Edit/RoutineOption";
import getRandomColorWithText from "@/Utils/GetRandomColor";
import { togetherDialogAtom } from "@/Atoms/Dialogs/Together";
import inactivateDialogAtom from "@/Atoms/Dialogs/Inactivate";
import { deleteTaskAtom } from "@/ViewModels/Plan/Mindmap/ViewModel";

export const handlePositions = (isMobile: boolean) => {
  return [
    {
      id: "left",
      position: Position.Left,
      additionalStyle: {
        top: "50%",
        transform: "translateY(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "left-top",
      position: Position.Left,
      additionalStyle: {
        left: "0",
        top: "0",
        transform: "translate(0%, 0%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "left-bottom",
      position: Position.Left,
      additionalStyle: {
        left: "0",
        bottom: "0",
        transform: "translate(0%, 0%)",
        right: "auto",
        top: "auto",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "top",
      position: Position.Top,
      additionalStyle: {
        left: "50%",
        transform: "translateX(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "right",
      position: Position.Right,
      additionalStyle: {
        top: "50%",
        transform: "translateY(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "right-top",
      position: Position.Right,
      additionalStyle: {
        right: "0",
        top: "0",
        transform: "translate(0%, 0%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "right-bottom",
      position: Position.Right,
      additionalStyle: {
        right: "0",
        bottom: "0",
        transform: "translate(0%, 0%)",
        left: "auto",
        top: "auto",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "bottom",
      position: Position.Bottom,
      additionalStyle: {
        left: "50%",
        transform: "translateX(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
  ];
};

interface NodeToolbarsProps {
  data: any;
}

export const NodeToolbars = ({ data }: NodeToolbarsProps) => {
  const { isMobile } = useIsMobile();
  const isMine = useAtomValue(checkPlanUserIdAtom);
  const selectedNode = useAtomValue(selectedNodeAtom);
  const handleDeleteNode = useSetAtom(deleteTaskAtom);
  const detachNodeInGroup = useSetAtom(detachNodeInGroupAtom);
  const handleDetachNodeInGroup = (e: React.MouseEvent) => {
    e.stopPropagation();
    detachNodeInGroup(data.id);
  };
  const updateStatus = useSetAtom(updateTaskStatusNodeAtom);
  const today = dayjs().format("YYYY-MM-DD");
  const isExistTodayStatus =
    data.data.taskStatus &&
    data.data.taskStatus.length !== 0 &&
    data.data.taskStatus.find((status: any) => status.date === today);
  const todayStatus = isExistTodayStatus
    ? data.data.taskStatus.find((status: any) => status.date === today)
    : null;

  return (
    isMine && (
      <>
        <NodeToolbar
          isVisible={selectedNode?.id === data.id}
          position={Position.Top}
        >
          {data.parentId !== undefined && (
            <Button
              onClick={handleDetachNodeInGroup}
              style={{
                fontSize: "0.8rem",
                fontWeight: "600",
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              그룹해제
            </Button>
          )}
          {isMobile && (
            <Button
              onClick={() => handleDeleteNode(data.id)}
              style={{
                fontSize: "0.8rem",
                fontWeight: "600",
                backgroundColor: theme.colors.primary,
                color: "white",
              }}
            >
              삭제
            </Button>
          )}
        </NodeToolbar>
        {/* 완료 버튼 숨겨둠 */}
        {/* <NodeToolbar isVisible={isExistTodayStatus} position={Position.Right}>
          <Button
            style={{
              fontSize: "0.8rem",
              fontWeight: "600",
              backgroundColor: theme.colors.primary,
              color: "white",
            }}
            onClick={(e) => {
              e.stopPropagation();
              devConsoleLog(todayStatus);

              // updateStatus(
              //   data.id,
              //   todayStatus.taskStatusId as number,
              //   (todayStatus.status as number) === 1 ? 0 : 1
              // );
            }}
          >
            {todayStatus?.status === 1 ? "대기" : "완료"}
          </Button>
        </NodeToolbar> */}
      </>
    )
  );
};

export const NodeContents = (data: any) => {
  const updateNode = useSetAtom(updateNodeAtom);
  const openTextEditor = useSetAtom(textEditorOpenAtom);
  const language = useAtomValue(languageAtom);
  const setEditRoutineDialog = useSetAtom(editRoutineIntervalsDialogAtom);
  const [inputValue, setInputValue] = React.useState("");
  const addHashtag = useSetAtom(addHashtagNodeAtom);
  const removeHashtag = useSetAtom(removeHashtagNodeAtom);
  const setTogetherDialog = useSetAtom(togetherDialogAtom);
  const setInactivateDialog = useSetAtom(inactivateDialogAtom);

  const handleUpdateNode = (key: keyof typeof data.data, value: any) => {
    const updatedNode = {
      ...data.data,
      [key]: value,
    };
    updateNode(updatedNode);
  };

  const handleDateUpdate = (date: Dayjs | null, type: "start" | "end") => {
    const updatedNode = {
      ...data.data,
      startDate: type === "start" ? date?.toISOString() : data.data.startDate,
      endDate: type === "end" ? date?.toISOString() : data.data.endDate,
    };
    updateNode(updatedNode);
  };

  const handleLocationUpdate = (address: string) => {
    const updatedNode = {
      ...data.data,
      location: {
        ...data.data.location,
        address: address,
      },
    };
    updateNode(updatedNode);
  };

  const setRoutineOption = (key: number) => {
    let updatedNode;
    if (data.data.termType === key) {
      updatedNode = {
        ...data.data,
        termType: null,
        termData: null,
      };
    } else {
      updatedNode = {
        ...data.data,
        termType: key,
        termData: [],
      };
    }
    updateNode(updatedNode);
  };

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (
        !data.data.hashtags.find(
          (tag: {
            backgroundColor: string;
            textColor: string;
            value: string;
          }) => tag.value === inputValue.trim()
        )
      ) {
        addHashtag(data, {
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === "Backspace" && inputValue === "") {
      if (data.data.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag(data);
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Input
          value={data.data.label}
          onChange={(e) => handleUpdateNode("label", e.target.value)}
          style={{ display: "flex", width: "100%", fontSize: "1.3rem" }}
        />
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          fontSize: "1rem",
          fontWeight: "bold",
        }}
      >
        진행 일자 설정
      </div>
      <DatePicker
        label="시작일"
        sx={{ width: "100%" }}
        value={dayjs(data.data.startDate)}
        onChange={(date) => handleDateUpdate(date, "start")}
        shouldDisableDate={
          data.data.endDate
            ? (day) => day.isAfter(data.data.endDate)
            : undefined
        }
        format="YYYY.MM.DD"
      />
      <DatePicker
        label="종료일"
        sx={{ width: "100%" }}
        value={dayjs(data.data.endDate)}
        onChange={(date) => handleDateUpdate(date, "end")}
        shouldDisableDate={
          data.data.startDate
            ? (day) => day.isBefore(data.data.startDate)
            : undefined
        }
        format="YYYY.MM.DD"
      />
      <S.ContentContainer
        onClick={() => openTextEditor(data.id, data.data.content as string)}
      >
        {data.data.content === "" ? (
          <Typography sx={{ fontSize: "0.8rem" }}>설명</Typography>
        ) : (
          <TextMarkdown text={data.data.content as string} />
        )}
      </S.ContentContainer>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ width: "20%", fontSize: "1rem", fontWeight: "bold" }}>
          루틴 간격
        </Typography>
        <ToggleButtonGroup
          exclusive
          value={data.data.termType}
          onChange={(event, newViewType) => setRoutineOption(newViewType)}
          color="primary"
        >
          <ToggleButton
            value={0}
            selected={data.data.termType === 0}
            onClick={() => setRoutineOption(0)}
          >
            매일
          </ToggleButton>
          <ToggleButton
            value={1}
            selected={data.data.termType === 1}
            onClick={() => setRoutineOption(1)}
          >
            매주
          </ToggleButton>
          <ToggleButton
            value={2}
            selected={data.data.termType === 2}
            onClick={() => setRoutineOption(2)}
          >
            매월
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {(data.data.termType === 1 || data.data.termType === 2) && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
              반복
            </Typography>
            <CustomBackgroundColorButton
              text={language === "KR" ? "수정" : "edit"}
              onClick={() =>
                setEditRoutineDialog({
                  open: data.id,
                  termType: data.data.termType,
                  termData: data.data.termData,
                })
              }
              fontSize="1rem"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
              {data.data.termType === 1 ? "매주" : "매월"}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `repeat(${
                  data.data.termData && data.data.termData.length < 7
                    ? data.data.termData.length
                    : 7
                }, 1fr)`,
                gap: "5px",
              }}
            >
              {data.data.termData?.map((interval: string) => (
                <Typography
                  key={interval}
                  sx={{ fontSize: "1rem", fontWeight: "bold" }}
                >
                  {interval}
                  {data.data.termType === 1 ? "" : "일"}
                </Typography>
              ))}
            </Box>
          </Box>
        </>
      )}

      <TextField
        id="outlined-basic"
        label="장소"
        variant="outlined"
        value={(data.data.location.address as string) || ""}
        onChange={(e) => handleLocationUpdate(e.target.value)}
        style={{ width: "100%" }}
      />

      <S.TagInputContainer>
        {data.data.hashtags.map(
          (
            tag: { backgroundColor: string; textColor: string; value: string },
            idx: number
          ) => {
            return (
              <S.TagItem
                key={idx}
                style={{
                  backgroundColor: tag.backgroundColor,
                  color: tag.textColor,
                }}
              >
                <span>{tag.value}</span>
              </S.TagItem>
            );
          }
        )}
        <S.TagInput
          placeholder={data.data.hashtags.length === 0 ? "해시태그" : ""}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </S.TagInputContainer>

      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {data.data.activate === 1 ? (
          <CustomColorButton
            text="비활성화 해제"
            onClick={() => setInactivateDialog({ open: true, node: data })}
            fontSize="1.2rem"
          />
        ) : (
          <CustomColorButton
            text="비활성화"
            onClick={() => setInactivateDialog({ open: true, node: data })}
            fontSize="1.2rem"
          />
        )}
        <CustomColorButton
          text="함께하기"
          onClick={() => setTogetherDialog({ node: data })}
          fontSize="1.2rem"
        />
      </div>
    </>
  );
};
