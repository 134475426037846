import { useQuery } from "react-query";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { getUserId } from "@/ViewModels/UserViewModel";
import { useNotificationAtom } from "@/Atoms/HookControllerAtom";
import {
  notificationAtom,
  notificationDataAtom,
} from "@/Atoms/Notification/atom";
import { dummyNotificationData } from "@/Atoms/Notification/dummyData";

const useNotification = () => {
  const userId = useAtomValue(getUserId);
  const [controller, setController] = useAtom(useNotificationAtom);
  const setNotificationData = useSetAtom(notificationDataAtom);
  const setNotification = useSetAtom(notificationAtom);

  return useQuery(["root", userId], () => {}, {
    enabled: userId !== 0 && controller,
    onSuccess: async () => {
      setController(false);
      const dummyData = dummyNotificationData(); // TODO: 나중에 실제 데이터를 서버에서 받아와야함
      setNotificationData(dummyData);
      setNotification((prev) => ({
        ...prev,
        isUnread: dummyData.some((notification) => notification.unread),
      }));
    },
  });
};

export default useNotification;
