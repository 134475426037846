import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import { createNewNodeGroupModalAtom } from "@/Atoms/Plan/MindmapAtom";
import snackbarAtom from "@/Atoms/Snackbar";
import { createNewNodeGroup } from "@/Queries/PlanQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { userLanguageAtom } from "@/ViewModels/UserViewModel";
import { TFunction } from "i18next";
import { atom } from "jotai";
import { setInnerTabDataAtom } from "../../InnerTabViewModel";
import { Node } from "@xyflow/react";

export const isExistParentNodeTypeGroupAtom = atom((get) => (node: Node) => {
  const nodeType = node.type;
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const planData = get(innerTabDataAtom);
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );

  if (!currentPlanData) return false;

  const nodes = currentPlanData.nodes;
  const edges = currentPlanData.edges;

  // modal과 연결된 edge가 있는지 확인
  const connectedEdges = edges.filter((edge: any) => edge.target === node.id);
  if (connectedEdges.length === 0) return false;

  // modal과 연결된 edge의 source들에 대한 node가 있는지 확인
  const connectedNodes = nodes.filter((n: any) =>
    connectedEdges.some((edge: any) => edge.source === n.id)
  );
  if (connectedNodes.length === 0) return false;

  // modal과 연결된 edge의 source들 중 group-${modal.type}이 있는지 확인
  const groupNodes = connectedNodes.filter(
    (n: any) => n.type === `group-${nodeType}`
  );

  return groupNodes.length > 0;
});

export const isExistNodeTypeGroupAtom = atom((get) => {
  const modal = get(createNewNodeGroupModalAtom);
  if (!modal) return false;
  const nodeType = modal.node.type;
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const planData = get(innerTabDataAtom);
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );
  if (!currentPlanData) return false;
  const nodes = currentPlanData.nodes;
  const edges = currentPlanData.edges;

  // modal과 연결된 edge가 있는지 확인
  const connectedEdges = edges.filter(
    (edge: any) => edge.target === modal.node.id
  );
  if (connectedEdges.length === 0) return false;

  // modal과 연결된 edge의 source들에 대한 node가 있는지 확인
  const connectedNodes = nodes.filter((node: any) =>
    connectedEdges.find((edge: any) => edge.source === node.id)
  );
  if (connectedNodes.length === 0) return false;

  // modal과 연결된 edge의 source들 중 group-${modal.type}이 있는지 확인
  const groupNodes = connectedNodes.filter(
    (node: any) => node.type === `group-${nodeType}`
  );

  return groupNodes.length > 0;
});

export const createNewNodeGroupAtom = atom(
  null,
  async (get, set, t: TFunction) => {
    const modal = get(createNewNodeGroupModalAtom);
    if (!modal) return;
    const backendId = modal.node.data.backendId as number;
    const innerTabId = GetIdFromQuerystring("inner_tab_id");

    try {
      set(createNewNodeGroupModalAtom, { node: modal.node, isLoading: true });
      const response = await handleReactQueryApiResponse(
        createNewNodeGroup,
        () => set(error401ModalAtom, true),
        innerTabId,
        backendId,
        get(userLanguageAtom)
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseJson = await response.json();
      const { nodes, edges } = responseJson;

      set(setInnerTabDataAtom, { innerTabId, nodes, edges });
      set(snackbarAtom, {
        open: true,
        message: t("plan.modals.createNewNodeGroup.success"),
        severity: "success",
      });
    } catch (error) {
      set(snackbarAtom, {
        open: true,
        message: t("plan.modals.createNewNodeGroup.fail"),
        severity: "error",
      });
    }
    set(createNewNodeGroupModalAtom, null);
  }
);
