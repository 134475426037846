import React, { useEffect, useRef, useState } from "react";
import * as S from "./styled";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Input,
  InputBase,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Handle, HandleType, NodeToolbar, Position } from "@xyflow/react";
import dayjs from "dayjs";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import theme from "../../../../../../Styles/theme";
import { togetherDialogAtom } from "@/Atoms/Dialogs/Together";
import getRemainingDays from "@/Utils/GetRemainingDays";
import getProgressPercentage from "@/Utils/GetProgressPercentage";
import {
  careerPathAtom,
  createNewNodeGroupModalAtom,
  currentZoomAtom,
  selectedNodeAtom,
} from "@/Atoms/Plan/MindmapAtom";
import { detachNodeInGroupAtom } from "@/ViewModels/Plan/Mindmap/GroupViewModel";
import {
  createJobDialogOpenAtom,
  recomendJobNodeDataAtom,
  recommendJobNodeNextPageAtom,
  recommendJobNodePrevPageAtom,
  updateJobNodeDataAtom,
  updateTaskStatusNodeAtom,
} from "@/ViewModels/Plan/Mindmap/JobNodeViewModel";
import jobNodeAtom, {
  alertNoJobDataModalAtom,
} from "@/Atoms/Nodes/JobNodeAtom";
import inactivateDialogAtom from "@/Atoms/Dialogs/Inactivate";
import { getTextColorBasedOnBackground } from "@/Utils/GetRandomColor";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import { lighten } from "polished";
import useIsMobile from "@/Hooks/useIsMobile";
import { useLocation } from "react-router-dom";
import { deleteTaskAtom } from "@/ViewModels/Plan/Mindmap/ViewModel";
import {
  NavigateBefore,
  NavigateNext,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { isExistParentNodeTypeGroupAtom } from "@/ViewModels/Plan/Mindmap/Modals/CreateNewNodeGroupViewModel";
import { set } from "lodash";
import { updateNodeAtom } from "@/ViewModels/Plan/Mindmap/FlowViewModel";

const JobNode = (data: any) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const jobNodes = useAtomValue(jobNodeAtom);
  const jobNode = jobNodes.find((node) => node.id === data.id) || null;
  const contentRef = useRef<HTMLDivElement>(null); // 확장된 높이를 참조할 수 있는 useRef
  const detachNodeInGroup = useSetAtom(detachNodeInGroupAtom);

  const updateValue = useSetAtom(updateJobNodeDataAtom);
  const [, updateNode] = useAtom(updateNodeAtom);

  const selectedNode = useAtomValue(selectedNodeAtom);
  const careerPath = useAtomValue(careerPathAtom);
  const type = data.id.split("-")[0];
  const setTogetherDialog = useSetAtom(togetherDialogAtom);
  const createJob = useSetAtom(createJobDialogOpenAtom);
  const recomendJob = useSetAtom(recomendJobNodeDataAtom);
  const setInactivateDialog = useSetAtom(inactivateDialogAtom);
  const updateStatus = useSetAtom(updateTaskStatusNodeAtom);
  const today = dayjs().format("YYYY-MM-DD");
  const isExistTodayStatus =
    data.data.taskStatus.length !== 0 &&
    data.data.taskStatus.find((status: any) => status.date === today);
  const todayStatus = isExistTodayStatus
    ? data.data.taskStatus.find((status: any) => status.date === today)
    : null;
  const handleDeleteNode = useSetAtom(deleteTaskAtom);
  const path = useLocation().pathname;
  const jobPrevPage = useSetAtom(recommendJobNodePrevPageAtom);
  const jobNextPage = useSetAtom(recommendJobNodeNextPageAtom);
  const setNoJobDataModal = useSetAtom(alertNoJobDataModalAtom);
  const currentZoom = useAtomValue(currentZoomAtom);
  const isExpanded = selectedNode?.id === data.id || data.data.isNewCreated;
  const openCreateNewNodeGroupModal = useSetAtom(createNewNodeGroupModalAtom);
  const isExistNodeTypeGroup = useAtomValue(isExistParentNodeTypeGroupAtom)(
    data
  );

  const handlePositions = [
    {
      id: "left",
      position: Position.Left,
      additionalStyle: {
        top: "50%",
        transform: "translateY(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "left-top",
      position: Position.Left,
      additionalStyle: {
        left: "0",
        top: "0",
        transform: "translate(0%, 0%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "left-bottom",
      position: Position.Left,
      additionalStyle: {
        left: "0",
        bottom: "0",
        transform: "translate(0%, 0%)",
        right: "auto",
        top: "auto",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "top",
      position: Position.Top,
      additionalStyle: {
        left: "50%",
        transform: "translateX(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "right",
      position: Position.Right,
      additionalStyle: {
        top: "50%",
        transform: "translateY(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "right-top",
      position: Position.Right,
      additionalStyle: {
        right: "0",
        top: "0",
        transform: "translate(0%, 0%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "right-bottom",
      position: Position.Right,
      additionalStyle: {
        right: "0",
        bottom: "0",
        transform: "translate(0%, 0%)",
        left: "auto",
        top: "auto",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
    {
      id: "bottom",
      position: Position.Bottom,
      additionalStyle: {
        left: "50%",
        transform: "translateX(-50%)",
        width: isMobile ? "25px" : "10px",
        height: isMobile ? "25px" : "10px",
      },
    },
  ];

  // 경로에 있는지 여부에 따른 활성/비활성 스타일 결정
  const isPathActive = careerPath?.path.includes(data.id);
  const isCareerPathNull = careerPath === null;

  const handleDetachNodeInGroup = (e: React.MouseEvent) => {
    e.stopPropagation();
    detachNodeInGroup(data.id);
  };

  const getButtonSize = (baseSize: number) => {
    const scaledSize = baseSize * currentZoom;
    return scaledSize;
  };

  const JobButtonGroup = ({
    jobList,
  }: {
    jobList: { job: string; rank: number; status: boolean }[];
  }) => {
    const colorMap = [
      theme.colors.primary,
      theme.colors.customPink,
      theme.colors.customYellow,
      theme.colors.customSky,
      theme.colors.customPurple,
    ];
    const itemsPerPage = 5; // 한 페이지에 보여줄 항목 수
    const totalPages = Math.ceil(jobList.length / itemsPerPage); // 총 페이지 수

    const handleNextPage = (e: React.MouseEvent) => {
      e.stopPropagation();
      jobNextPage(data);
    };

    const handlePrevPage = (e: React.MouseEvent) => {
      e.stopPropagation();
      jobPrevPage(data);
    };

    const currentItems = jobNode
      ? jobList.slice(
          jobNode.state.currentPage * itemsPerPage,
          (jobNode.state.currentPage + 1) * itemsPerPage
        )
      : [];

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {jobList.length === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              padding: "5px 10px",
              borderRadius: "10px",
              backgroundColor: "white",
              border: `1px solid ${theme.colors.primary}`,
              fontSize: "0.8rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>{t("plan.contents.mindmap.nodes.job.noRecommend")}</div>
            <div>{t("plan.contents.mindmap.nodes.job.noRecommend2")}</div>
          </div>
        )}
        {currentItems.map((job, index) => {
          return (
            <Chip
              key={job.job}
              label={`${job.job}`}
              sx={{
                backgroundColor: colorMap[index % colorMap.length],
                color: getTextColorBasedOnBackground(
                  colorMap[index % colorMap.length]
                ),
                "&:hover": {
                  backgroundColor: lighten(
                    0.1,
                    colorMap[index % colorMap.length]
                  ),
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                job.status
                  ? createJob(data, job.job)
                  : setNoJobDataModal(job.job);
              }}
            />
          );
        })}
        {jobNode && totalPages > 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavigateBefore
              onClick={(e) => handlePrevPage(e)}
              sx={{
                fontSize: "1.1rem",
                fontWeight: "600",
                backgroundColor:
                  jobNode.state.currentPage === 0
                    ? "#e0e0e0"
                    : theme.colors.primary,
                color: jobNode.state.currentPage === 0 ? "#9e9e9e" : "white",
                cursor:
                  jobNode.state.currentPage === 0 ? "not-allowed" : "pointer",
                borderRadius: "50%",
                padding: "5px",
              }}
            />
            <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
              {jobNode.state.currentPage + 1} / {totalPages}
            </Typography>
            <NavigateNext
              onClick={(e) => handleNextPage(e)}
              sx={{
                fontSize: "1.1rem",
                fontWeight: "600",
                backgroundColor:
                  jobNode.state.currentPage === totalPages - 1
                    ? "#e0e0e0"
                    : theme.colors.primary,
                color:
                  jobNode.state.currentPage === totalPages - 1
                    ? "#9e9e9e"
                    : "white",
                cursor:
                  jobNode.state.currentPage === totalPages - 1
                    ? "not-allowed"
                    : "pointer",
                borderRadius: "50%",
                padding: "5px",
              }}
            />
          </div>
        )}
      </div>
    );
  };

  // 1. 로컬에 저장 후 그 다음에 update시키기
  const [localLabel, setLocalLabel] = useState(data.data.label);

  useEffect(() => {
    updateValue(data, "label", localLabel);
  }, [localLabel]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalLabel(e.target.value);
  };

  return (
    <>
      <NodeToolbar
        isVisible={true}
        position={Position.Top}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {jobNode &&
          (jobNode.state.status === 0 ? (
            <CircularProgress size={40} />
          ) : (
            <JobButtonGroup jobList={jobNode.state.jobList} />
          ))}
        {jobNode === null &&
          data.data.label &&
          data.data.label !== "" &&
          data.data.label !== "직업을 입력해주세요." &&
          !(data.data.label as string).includes("Please enter") &&
          path === "/plan" && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                recomendJob(data);
              }}
              sx={{
                fontSize: `${getButtonSize(2)}rem`,
                fontWeight: "600",
                backgroundColor: theme.colors.primary,
                color: "white",
                padding: `5px`,
              }}
            >
              {t("plan.contents.mindmap.nodes.job.buttons.recommend")}
            </Button>
          )}
        {selectedNode?.id === data.id && data.parentId !== undefined && (
          <Button
            onClick={handleDetachNodeInGroup}
            style={{
              fontSize: "0.8rem",
              fontWeight: "600",
              backgroundColor: theme.colors.primary,
              color: "white",
            }}
          >
            {t("plan.contents.mindmap.nodes.job.buttons.detachGroup")}
          </Button>
        )}
        {selectedNode?.id === data.id && isMobile && (
          <Button
            onClick={() => handleDeleteNode(data.id)}
            style={{
              fontSize: "0.8rem",
              fontWeight: "600",
              backgroundColor: theme.colors.primary,
              color: "white",
            }}
          >
            {t("plan.contents.mindmap.nodes.job.buttons.delete")}
          </Button>
        )}
      </NodeToolbar>
      <NodeToolbar isVisible={isExistTodayStatus} position={Position.Right}>
        <Button
          sx={{
            fontSize: "0.8rem",
            fontWeight: "600",
            backgroundColor: theme.colors.primary,
            color: "white",
            "&:hover": { backgroundColor: lighten(0.1, theme.colors.primary) },
          }}
          onClick={() =>
            updateStatus(
              data.id,
              todayStatus.taskStatusId as number,
              (todayStatus.status as number) === 1 ? 0 : 1
            )
          }
        >
          {todayStatus?.status === 1
            ? t("plan.contents.mindmap.nodes.job.wait")
            : t("plan.contents.mindmap.nodes.job.done")}
        </Button>
      </NodeToolbar>
      <Stack
        className="nowheel"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
          transition: "filter 0.5s ease", // 어두워지는 효과
          filter: isCareerPathNull || isPathActive ? "none" : "brightness(50%)", // 경로에 없으면 어둡게
        }}
        onClick={(e) => {
          if (selectedNode?.id === data.id) {
            e.stopPropagation();
          }
        }}
      >
        <S.Container
          id={`${data.id}`}
          $isActivate={data.data.activate === 1}
          $isSelecting={selectedNode?.id === data.id}
          $color={theme.colors[type]}
          style={{
            transition: "max-height 0.5s ease", // 애니메이션 추가
            overflow: "hidden", // 넘치는 내용 숨기기
          }}
          ref={contentRef} // contentRef를 참조
        >
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  background: theme.colors[data.type],
                  padding: "5px 10px",
                  color: "white",
                  borderRadius: "20px",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                {t(`plan.contents.mindmap.nodes.job.typeLabel.${data.type}`)}
              </Box>
              {!isExistNodeTypeGroup && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    openCreateNewNodeGroupModal({
                      node: data,
                      isLoading: false,
                    });
                  }}
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    padding: "0px",
                    color: theme.colors.primary,
                  }}
                >
                  {t("plan.contents.mindmap.nodes.createNewNodeGroup")}
                </Button>
              )}
            </div>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setInactivateDialog({ open: true, node: data });
              }}
            >
              {data.data.activate === 1 ? (
                <VisibilityOffOutlined sx={{ width: "30px", height: "30px" }} />
              ) : (
                <VisibilityOutlined sx={{ width: "30px", height: "30px" }} />
              )}
            </IconButton>
            {/* {isExpanded && (
              
            )} */}
          </Stack>
          {/* 핸들: source/target을 동적으로 설정 */}
          {handlePositions.map((handle) =>
            ["source", "target"].map((type) => (
              <Handle
                key={`${data.id}-${handle.id}-${type}`}
                type={type as HandleType}
                position={handle.position}
                id={`${data.id}-${handle.id}-${type}`}
                className="handle"
                style={{
                  ...handle.additionalStyle,
                  backgroundColor:
                    selectedNode && selectedNode.id === data.id
                      ? "#d9d9d9"
                      : "transparent",
                }}
              />
            ))
          )}
          {isExpanded ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Input
                  value={localLabel}
                  onChange={handleChange}
                  style={{ display: "flex", width: "100%", fontSize: "1.3rem" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  alignItems: "flex-start",
                  gap: "10px",
                }}
              >
                {t("plan.contents.mindmap.nodes.job.relatedJob")}
                {jobNode && jobNode.state.status !== 0 && (
                  <List
                    component="nav"
                    aria-label="job list"
                    sx={{ width: "100%", height: "300px", overflow: "auto" }}
                  >
                    {jobNode.state.jobList.map((job, index) => (
                      <ListItemButton
                        key={job.job + index}
                        onClick={() =>
                          job.status
                            ? createJob(data, job.job)
                            : setNoJobDataModal(job.job)
                        }
                      >
                        <ListItemText primary={job.job} />
                      </ListItemButton>
                    ))}
                  </List>
                )}
                {jobNode === null &&
                  data.data.label &&
                  data.data.label !== "" &&
                  data.data.label !== "직업을 입력해주세요." &&
                  !(data.data.label as string).includes("Please enter") &&
                  path === "/plan" && (
                    <p
                      style={{
                        fontSize: "0.9rem",
                        color: "black",
                        textAlign: "center",
                        width: "100%",
                        fontWeight: "600",
                      }}
                    >
                      {t("plan.contents.mindmap.nodes.job.clickRelatedJob")}
                    </p>
                  )}
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <CustomBackgroundColorButton
                  text={t("plan.contents.mindmap.nodes.job.together")}
                  onClick={() => setTogetherDialog({ node: data })}
                  fontSize="1.2rem"
                  backgroundColor="transparent"
                  style={{ color: theme.colors.primary }}
                />
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography style={{ fontSize: "1.2rem", textAlign: "start" }}>
                  {data.data.label}
                </Typography>
              </div>
            </>
          )}
        </S.Container>
      </Stack>
    </>
  );
};

export default JobNode;
