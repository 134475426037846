import { useQuery } from "react-query";
import {
  DokgabiFlowProps,
  DokgabiFrontElementProps,
} from "../Models/DokgabiFlow";
import { ChattingProps } from "../Components/Helper/Balloon";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { useSetAtom } from "jotai";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { devConsoleError } from "@/Utils/ConsoleLogInDevelopment";
import { getCookie } from "@/Utils/CookieUtils";
import { getVerifyRefreshToken } from "./AuthQueries";

async function getMyFlow(
  access: string,
  id: string
): Promise<DokgabiFlowProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      chatbot_type: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getChatbotTypeFlow`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}

export function useGetFlowDataQuery(myId: string) {
  const setError401Modal = useSetAtom(error401ModalAtom);
  return useQuery({
    queryKey: [`${myId}-flow`],
    queryFn: async () =>
      (await handleReactQueryApiResponse(
        getMyFlow,
        () => setError401Modal(true),
        myId
      )) as Promise<DokgabiFlowProps[] | null>,
  });
}

export async function requestChatbot(
  access: string,
  input_message: string,
  user_id: string,
  flow_socket_name: string,
  character: string,
  handleStreamMessage: (
    message: string,
    isFirst: boolean,
    isNotStream: boolean,
    flow_socket_name: string
  ) => void,
  handleJson: (data: any) => void
): Promise<any> {
  const requestBody = {
    character: -1,
    flow_socket_name: flow_socket_name,
    input_message: input_message,
    user_id: user_id,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify(requestBody),
  };

  try {
    let response = await fetch(
      `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/chatbot`,
      requestOptions
    );

    if (response.status === 403) {
      // return response;
      const refreshCookie = getCookie(
        process.env.REACT_APP_USER_REFRESH_COOKIE_ID
      );
      try {
        const newAccessToken = await getVerifyRefreshToken(refreshCookie ?? "");
        const newRequestOptions = {
          ...requestOptions,
          headers: {
            ...requestOptions.headers,
            Authorization: `Bearer ${newAccessToken}`,
          },
        };
        response = await fetch(
          `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/chatbot`,
          newRequestOptions
        );
      } catch (error) {
        devConsoleError("토큰 갱신 중 오류 발생:", error);
      }
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let isFirst = true;
    let message = "";

    while (true) {
      const { done, value } = await reader!.read();

      if (done) {
        // 여기에 음성을 출력하는 기능 추가
        break;
      }

      const chunk = decoder.decode(value, { stream: true });

      if (chunk.startsWith("JSON:")) {
        // JSON 문자열 파싱 시도
        const jsonString = chunk.replace(/^JSON:/, "").replace(/:JSON$/, "");
        const json = JSON.parse(jsonString);
        handleJson(json.data as { job: string }[]);
      } else {
        message += chunk;
        handleStreamMessage(message, isFirst, false, flow_socket_name);

        isFirst = false;
      }
    }
  } catch (error) {
    // return ResponseStatus.ERROR;
  }
}

async function getMyChatbotFrontElement(
  access: string,
  id: number
): Promise<DokgabiFrontElementProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMyChatbotFrontElement`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}

export function useGetMyChatbotFrontElement(myId: number) {
  const setError401Modal = useSetAtom(error401ModalAtom);

  return useQuery({
    queryKey: [`${myId}-front-element`],
    queryFn: () =>
      handleReactQueryApiResponse(
        getMyChatbotFrontElement,
        () => setError401Modal(true),
        myId
      ),
  });
}

export async function getChatbotHistory(
  access: string,
  flow_id: number,
  user_id: string,
  numpage: number
): Promise<ChattingProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json", // JSON 형식으로 지정
    },
    body: JSON.stringify({
      flow_id: flow_id,
      user_id: user_id,
      numpage: numpage,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getChatHistory`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();
  return result.results;
}
