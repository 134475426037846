import { selectedNodeAtom } from "@/Atoms/Plan/MindmapAtom";
import useIsMobile from "@/Hooks/useIsMobile";
import theme from "@/Styles/theme";
import { detachNodeInGroupAtom } from "@/ViewModels/Plan/Mindmap/GroupViewModel";
import { updateTaskStatusNodeAtom } from "@/ViewModels/Plan/Mindmap/JobNodeViewModel";
import { checkPlanUserIdAtom } from "@/ViewModels/Plan/PlanViewModel";
import { Button } from "@mui/material";
import { NodeToolbar, Position } from "@xyflow/react";
import dayjs from "dayjs";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { deleteTaskAtom } from "@/ViewModels/Plan/Mindmap/ViewModel";

export const handlePositions = (isMobile: boolean) => {
  return [
    { id: "left", position: Position.Left, additionalStyle: { top: "50%", transform: "translateY(-50%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "left-top", position: Position.Left, additionalStyle: { left: "0", top: "0", transform: "translate(0%, 0%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "left-bottom", position: Position.Left, additionalStyle: { left: "0", bottom: "0", transform: "translate(0%, 0%)", right: "auto", top: "auto", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "top", position: Position.Top, additionalStyle: { left: "50%", transform: "translateX(-50%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "right", position: Position.Right, additionalStyle: { top: "50%", transform: "translateY(-50%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "right-top", position: Position.Right, additionalStyle: { right: "0", top: "0", transform: "translate(0%, 0%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "right-bottom", position: Position.Right, additionalStyle: { right: "0", bottom: "0", transform: "translate(0%, 0%)", left: "auto", top: "auto", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
    { id: "bottom", position: Position.Bottom, additionalStyle: { left: "50%", transform: "translateX(-50%)", width: isMobile ? "25px" : "10px", height: isMobile ? "25px" : "10px" } },
  ];
};

interface NodeToolbarsProps {
  data: any;
}

export const NodeToolbars = ({ data }: NodeToolbarsProps) => {
  const { isMobile } = useIsMobile();
  const isMine = useAtomValue(checkPlanUserIdAtom);
  const selectedNode = useAtomValue(selectedNodeAtom);
  const handleDeleteNode = useSetAtom(deleteTaskAtom);
  const detachNodeInGroup = useSetAtom(detachNodeInGroupAtom);
  const handleDetachNodeInGroup = (e: React.MouseEvent) => {
    e.stopPropagation();
    detachNodeInGroup(data.id);
  }
  const updateStatus = useSetAtom(updateTaskStatusNodeAtom);
  const today = dayjs().format('YYYY-MM-DD');
  const isExistTodayStatus = data.data.taskStatus && data.data.taskStatus.length !== 0 && data.data.taskStatus.find((status: any) => status.date === today);
  const todayStatus = isExistTodayStatus ? data.data.taskStatus.find((status: any) => status.date === today) : null;

  return (
    isMine &&
    <>
      <NodeToolbar
        isVisible={selectedNode?.id === data.id}
        position={Position.Top}
      >
        {
          data.parentId !== undefined &&
          <Button onClick={handleDetachNodeInGroup} style={{ fontSize: "0.8rem", fontWeight: "600", backgroundColor: theme.colors.primary, color: "white" }}>그룹해제</Button>
        }
        {
          isMobile &&
          <Button onClick={() => handleDeleteNode(data.id)} style={{ fontSize: "0.8rem", fontWeight: "600", backgroundColor: theme.colors.primary, color: "white" }}>삭제</Button>
        }
      </NodeToolbar>
      <NodeToolbar
        isVisible={isExistTodayStatus}
        position={Position.Right}
      >
        <Button style={{ fontSize: "0.8rem", fontWeight: "600", backgroundColor: theme.colors.primary, color: "white" }} onClick={() => updateStatus(data.id, todayStatus.taskStatusId as number, todayStatus.status as number === 1 ? 0 : 1)}>{todayStatus?.status === 1 ? "대기" : "완료"}</Button>
      </NodeToolbar>
    </>
  )
}
