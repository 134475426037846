import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { useRootAtom } from "@/Atoms/HookControllerAtom";
import { getLatestVersion } from "@/Queries/Version";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const getCurrentBuildVersion = (): string | null => {
  const metaTag = document.querySelector("meta[name='build-version']");
  return metaTag ? metaTag.getAttribute("content") : null;
};

const useVersionCheck = () => {
  const [isOutdated, setIsOutdated] = useState(false); // 업데이트 필요 여부
  const currentVersion = getCurrentBuildVersion(); // 현재 빌드 버전
  const setError401Modal = useSetAtom(error401ModalAtom);
  const [controller, setController] = useAtom(useRootAtom);

  // React Query를 사용해 최신 버전 가져오기
  const { data, isLoading, refetch } = useQuery(
    "latestVersion",
    async () => handleReactQueryApiResponse(getLatestVersion, () => setError401Modal(true)),
    {
      refetchInterval: 600000, // ✅ 10분(600000ms) 마다 실행
      refetchOnWindowFocus: false, // ✅ 창이 다시 포커스를 받을 때 refetch 방지
      refetchOnReconnect: false, // ✅ 네트워크 재연결 시 refetch 방지
      staleTime: 600000, // ✅ 10분 동안 데이터를 "새로운 데이터"로 간주하여 불필요한 재요청 방지
      cacheTime: 900000, // ✅ 15분 동안 캐시 유지 (선택 사항)
      enabled: !!currentVersion && !controller, // 현재 버전이 존재할 때만 동작
      onSuccess: async (response) => {
        if (!response.ok) return; // 요청 실패 처리
        const responseJson = await response.json();
        const isDevelopment = process.env.NODE_ENV === 'development';
        // 버전 비교
        if (responseJson.version !== currentVersion && !isDevelopment) {
          setIsOutdated(true); // 최신 버전과 다르면 상태 업데이트
        }
      },
    }
  );

  // 수동으로 버전 확인을 강제 실행하고 싶을 때 refetch 사용 가능
  const checkVersionManually = () => {
    refetch();
  };

  return { isOutdated, isLoading, checkVersionManually };
};

export default useVersionCheck;
