import React from "react";
import * as S from "./styled";
import { useAtomValue } from "jotai";
import {
  routineSidebarAtom,
  RoutineSidebarProps,
} from "@/Atoms/Plan/RoutineAtom";
import SidebarTitle from "./Common/Title";
import MyRoutineInRoutine from "./MyRoutine";
import MyTaskInRoutine from "./MyTask";
import CreateTaskInRoutine from "./CreateTask";
import EditTaskInRoutine from "./EditTask";
import EditRoutineInRoutine from "./EditRoutine";
import AddTaskToRoutine from "./AddTaskToRoutine";

const RoutineSidebar = () => {
  const sidebar = useAtomValue(routineSidebarAtom);
  const [sidebarData, setSidebarData] =
    React.useState<RoutineSidebarProps | null>(null);

  React.useEffect(() => {
    if (sidebar.length === 0) return;
    setSidebarData(sidebar[sidebar.length - 1]);
  }, [sidebar]);

  return (
    sidebarData && (
      <S.Container $isOpen={sidebar.length !== 0}>
        <SidebarTitle data={sidebarData} />

        {/* 내 루틴 */}
        {sidebarData.type === "routine" && (
          <MyRoutineInRoutine data={sidebarData} />
        )}

        {/* 내 할 일 */}
        {[
          "task",
          "taskInRoutine",
          "calendarTodayTask",
          "calendarTask",
        ].includes(sidebarData.type) && <MyTaskInRoutine data={sidebarData} />}

        {/* 할 일 추가 */}
        {["createTask", "addNewTaskToRoutine"].includes(sidebarData.type) && (
          <CreateTaskInRoutine data={sidebarData} />
        )}

        {/* 할 일 수정 */}
        {["editTask", "ganttEditTask"].includes(sidebarData.type) && (
          <EditTaskInRoutine data={sidebarData} />
        )}

        {/* 루틴 수정 */}
        {["editRoutine", "ganttEditRoutine"].includes(sidebarData.type) && (
          <EditRoutineInRoutine data={sidebarData} />
        )}

        {/* 루틴에 할 일 추가 */}
        {["addTaskToRoutine", "ganttAddTaskToRoutine"].includes(
          sidebarData.type
        ) && <AddTaskToRoutine data={sidebarData} />}
      </S.Container>
    )
  );
};

export default RoutineSidebar;
