import React from "react";
import * as S from "./styled";
import * as C from "./components";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import { useAtomValue, useSetAtom } from "jotai";
import { ganttChartDoubleClickAtom } from "@/ViewModels/Plan/GanttChartViewModel";
import "gantt-task-react/dist/index.css";
import { useTranslation } from "react-i18next";
import {
  ganttExpandListAtom,
  routineDateModeAtom,
  routineHeaderModeAtom,
} from "@/Atoms/Plan/RoutineAtom";
import {
  ganttChartBarClickAtom,
  ganttChartChangeTaskPositionAtom,
  ganttChartExpanderClickAtom,
  ganttChartOrderTasksAtom,
} from "@/ViewModels/Plan/Routine/GanttChartViewModel";
import { getGanttChartDataAtom } from "@/ViewModels/Plan/Routine/DataViewModel";
import { userLanguageAtom } from "@/ViewModels/UserViewModel";
import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { GanttTask } from "@/Utils/ConvertNodesToGanttTasks";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import {
  moveAnotherRoutine,
  moveIndependentTaskIntoRoutine,
  moveOutRoutineInRoutine,
  moveOutTaskInRoutine,
  updateRoutineOrder,
  updateRoutineTaskOrder,
} from "@/Queries/PlanQueries";
import snackbarAtom from "@/Atoms/Snackbar";
import { setInnerTabDataAtom } from "@/ViewModels/Plan/InnerTabViewModel";
import ConditionSelectionModal from "@/Components/Plan/Dialogs/ConditionSelection";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";

const GanttChart = () => {
  const userLanguage = useAtomValue(userLanguageAtom);
  const [height, setHeight] = React.useState<number>(window.innerHeight - 250);
  const expandList = useAtomValue(ganttExpandListAtom);
  const dateMode = useAtomValue(routineDateModeAtom);
  const { t } = useTranslation();
  const tasks = useAtomValue(getGanttChartDataAtom);
  const [orderTasks, setOrderTasks] = React.useState<GanttTask[]>(tasks);
  const barClick = useSetAtom(ganttChartBarClickAtom);
  const expanderClick = useSetAtom(ganttChartExpanderClickAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const setOrderTasksInGanttChart = useSetAtom(ganttChartOrderTasksAtom);
  const headerMode = useAtomValue(routineHeaderModeAtom);
  const taskChange = useSetAtom(ganttChartChangeTaskPositionAtom);
  const setSnackbar = useSetAtom(snackbarAtom);
  const setInnerTabData = useSetAtom(setInnerTabDataAtom);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalConditions, setModalConditions] = React.useState<string[]>([]);
  const [modalCallback, setModalCallback] = React.useState<
    ((selectedCondition: string) => void) | null
  >(null);

  /** ✅ MUI Dialog 기반 모달을 호출하는 함수 */
  const showConditionSelectionModal = (
    conditions: string[],
    callback: (selectedCondition: string) => void
  ) => {
    setModalConditions(conditions);
    setModalCallback(() => callback);
    setModalOpen(true);
  };

  /** ✅ 사용자가 선택한 조건 처리 */
  const handleConditionSelect = (selectedCondition: string) => {
    if (modalCallback) {
      modalCallback(selectedCondition);
    }
    setModalOpen(false);
  };

  /** ✅ 모달 닫기 */
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleTaskChange = (task: Task) => {
    taskChange(task as GanttTask);
  };

  const handleClick = (task: Task) => {
    barClick(task.id);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = orderTasks.findIndex((task) => task.id === active.id);
    const ni = orderTasks.findIndex((task) => task.id === over.id);
    const newIndex = ni - (ni !== 0 && ni < oldIndex ? 1 : 0);
    const nextOverIndex =
      newIndex + 1 < orderTasks.length ? newIndex + 1 : null;

    devConsoleLog("drag event", oldIndex, ni);

    const activeTask = orderTasks[oldIndex];
    const overTask = orderTasks[newIndex];
    const nextOverTask =
      nextOverIndex !== null ? orderTasks[nextOverIndex] : null;

    let updatedTasks = [...orderTasks];
    let matchingConditions: string[] = [];

    /** ✅ 루틴 간 이동 **/
    // if (activeTask.order.type === "routine") {
    //   const isFirst = newIndex === 0;
    //   const isLast = !nextOverTask;
    //   const isInSameRoutine =
    //     activeTask.order.routineId === activeTask.order.taskId;

    //   /** ✅ 첫 번째 또는 마지막 위치로 이동하는 경우 **/
    //   if (isFirst || isLast) {
    //     if (isInSameRoutine) {
    //       matchingConditions.push("moveRoutine"); // 루틴 순서만 변경
    //     } else {
    //       matchingConditions.push("moveRoutineOutOfRoutine"); // 루틴이 기존 루틴에서 빠져나감
    //     }
    //   } else {
    //     // 루틴 내부 이동 (새로운 루틴으로 들어가는 경우)
    //     if (
    //       overTask.order.type === "routine" ||
    //       overTask.order.type === "task_in_routine"
    //     ) {
    //       matchingConditions.push("moveRoutineIntoRoutine");
    //     }

    //     // 루틴이 빠져나가는 경우
    //     if (
    //       nextOverTask &&
    //       (nextOverTask.order.type === "routine" ||
    //         nextOverTask.order.type === "independent_task") &&
    //       activeTask.order.routineId !== activeTask.order.taskId
    //     ) {
    //       matchingConditions.push("moveRoutineOutOfRoutine");
    //     }

    //     // 루틴 위치 이동 (routine과 routine, independent_task와 routine, task_in_routine과 routine 사이)
    //     if (
    //       (overTask.order.type === "routine" &&
    //         nextOverTask?.order.type === "routine") ||
    //       (overTask.order.type === "independent_task" &&
    //         nextOverTask?.order.type === "routine") ||
    //       (overTask.order.type === "task_in_routine" &&
    //         nextOverTask?.order.type === "routine")
    //     ) {
    //       matchingConditions.push("moveRoutine");
    //     }
    //   }
    // }

    // // 루틴 안 할일 이동
    // if (activeTask.order.type === "task_in_routine") {
    //   const isFirst = newIndex === 0;
    //   const isLast = !nextOverTask;
    //   const isInSameRoutine =
    //     activeTask.order.routineId === activeTask.order.taskId;

    //   /** ✅ 첫 번째 또는 마지막 위치로 이동하는 경우 **/
    //   if (isInSameRoutine) {
    //     if (isFirst) {
    //       matchingConditions.push("moveTaskOutOfRoutine");
    //     } else if (isLast) {
    //       matchingConditions.push("moveTaskOutOfRoutine");
    //       matchingConditions.push("moveTaskWithinRoutine");
    //     }
    //   } else {
    //     /** ✅ 루틴 내 Task 간 이동 **/
    //     if (activeTask.order.routineId === overTask.order.routineId) {
    //       matchingConditions.push("moveTaskWithinRoutine");
    //     }

    //     /** ✅ Task가 다른 루틴으로 이동 **/
    //     if (
    //       activeTask.order.routineId !== overTask.order.routineId &&
    //       (overTask.order.type === "routine" ||
    //         overTask.order.type === "task_in_routine")
    //     ) {
    //       matchingConditions.push("moveTaskToAnotherRoutine");
    //     }

    //     /** ✅ Task가 루틴을 빠져나가는 경우 **/
    //     if (
    //       !nextOverTask ||
    //       nextOverTask?.order.type === "routine" ||
    //       nextOverTask?.order.type === "independent_task"
    //     ) {
    //       matchingConditions.push("moveTaskOutOfRoutine");
    //     }
    //   }
    // }

    // // 독립 할 일 이동
    // if (activeTask.order.type === "independent_task") {
    //   const isFirst = newIndex === 0;
    //   const isLast = !nextOverTask;

    //   /** ✅ 첫 번째 또는 마지막 위치로 이동하는 경우 **/
    //   if (isFirst || isLast) {
    //     matchingConditions.push("moveIndependentTask");
    //   } else {
    //     /** ✅ 독립 Task 간 이동 **/
    //     if (
    //       overTask.order.type === "independent_task" ||
    //       (overTask.order.type === "routine" &&
    //         nextOverTask?.order.type !== "task_in_routine") ||
    //       (overTask.order.type === "task_in_routine" &&
    //         (nextOverTask?.order.type === "routine" ||
    //           nextOverTask?.order.type === "independent_task"))
    //     ) {
    //       matchingConditions.push("moveIndependentTask");
    //     }

    //     /** ✅ Task가 루틴으로 들어가는 경우 **/
    //     if (
    //       overTask.order.type === "routine" ||
    //       overTask.order.type === "task_in_routine"
    //     ) {
    //       matchingConditions.push("moveTaskToRoutine");
    //     }
    //   }
    // }

    // /** ⚠️ 두 개 이상의 조건을 만족하는 경우 - 모달 표시 **/
    // if (matchingConditions.length > 1) {
    //   showConditionSelectionModal(matchingConditions, (selectedCondition) => {
    //     executeReorder(
    //       selectedCondition,
    //       activeTask,
    //       overTask,
    //       updatedTasks,
    //       oldIndex,
    //       newIndex,
    //       ni
    //     );
    //   });
    //   return;
    // }

    // /** ✅ 단일 조건일 경우 바로 실행 **/
    // if (matchingConditions.length === 1) {
    //   executeReorder(
    //     matchingConditions[0],
    //     activeTask,
    //     overTask,
    //     updatedTasks,
    //     oldIndex,
    //     newIndex,
    //     ni
    //   );
    // }
  };

  /** ✅ 정렬 로직을 실행하는 함수 **/
  const executeReorder = async (
    condition: string,
    activeTask: GanttTask,
    overTask: GanttTask,
    updatedTasks: GanttTask[],
    oldIndex: number,
    newIndex: number,
    ni: number
  ) => {
    switch (condition) {
      case "moveRoutine":
        updatedTasks = await moveRoutine(
          activeTask,
          updatedTasks,
          oldIndex,
          ni
        );
        break;
      case "moveRoutineIntoRoutine":
        updatedTasks = await moveRoutineIntoRoutine(
          activeTask,
          overTask,
          updatedTasks,
          oldIndex,
          newIndex
        );
        break;
      case "moveRoutineOutOfRoutine":
        updatedTasks = await moveRoutineOutOfRoutine(
          activeTask,
          updatedTasks,
          oldIndex,
          newIndex
        );
        break;
      case "moveTaskWithinRoutine":
        updatedTasks = await moveTaskInRoutine(
          activeTask,
          updatedTasks,
          oldIndex,
          ni
        );
        break;
      case "moveTaskToAnotherRoutine":
        updatedTasks = await moveTaskToAnotherRoutine(
          activeTask,
          overTask,
          arrayMove(updatedTasks, oldIndex, newIndex)
        );
        break;
      case "moveTaskOutOfRoutine":
        updatedTasks = await moveTaskOutOfRoutine(activeTask, updatedTasks);
        break;
      case "moveTaskToRoutine":
        updatedTasks = await moveTaskToRoutine(
          activeTask,
          overTask,
          updatedTasks
        );
        break;
      case "moveIndependentTask":
        updatedTasks = await moveIndependentTask(
          activeTask,
          arrayMove(updatedTasks, oldIndex, newIndex),
          oldIndex,
          newIndex
        );
        break;
      default:
        return;
    }

    const newOrders = updatedTasks.map((task) => task.order);

    try {
      setOrderTasksInGanttChart({
        innerTabId: GetIdFromQuerystring("inner_tab_id"),
        order: newOrders,
      });
      setOrderTasks(updatedTasks);
    } catch (error) {
      console.error(error);
    }
  };

  /** ✅ 독립 할 일 이동 (단순 순서 변경) **/
  const moveIndependentTask = async (
    activeTask: GanttTask,
    updatedTasks: GanttTask[],
    oldIndex: number,
    newIndex: number
  ): Promise<GanttTask[]> => {
    let order = 0;
    let subOrder = 0;

    const beforeTasks = updatedTasks.map((task) => {
      if (task.order.type === "routine") {
        subOrder = 0;
      }
      return {
        ...task,
        order: {
          ...task.order,
          routineId: task.order.routineId,
          order: task.order.type !== "task_in_routine" ? ++order : null,
          subOrder: task.order.type === "task_in_routine" ? ++subOrder : null,
        },
      };
    });

    const prevOrder = activeTask.order.order as number;
    const newOrder = beforeTasks.find((task) => task.id === activeTask.id)
      ?.order.order as number;
    const diff = prevOrder - newOrder;

    try {
      if (prevOrder === newOrder) {
        throw new Error("Failed to update routine order");
      }
      const response = await handleReactQueryApiResponse(
        updateRoutineOrder,
        () => setError401Modal(true),
        activeTask.order.taskId,
        prevOrder,
        newOrder +
          (diff !== 1 && newOrder !== 1 && prevOrder > newOrder ? 1 : 0),
        innerTabId
      );

      if (!response.ok) {
        throw new Error("Failed to update routine order");
      }

      const responseJson = await response.json();
      const { nodes, edges, order } = responseJson;
      setInnerTabData({ innerTabId: innerTabId, nodes: nodes, edges: edges });
      setOrderTasksInGanttChart({ innerTabId: innerTabId, order: order });
      const updatedOrder = order.find(
        (o: any) => o.task_id === activeTask.order.taskId
      );
      if (!updatedOrder) {
        throw new Error("Failed to update order");
      }
      const tasks = updatedTasks.map((task) => {
        const taskOrder = order.find(
          (o: any) => o.task_id === task.order.taskId
        );
        if (!taskOrder) {
          return task;
        }
        return {
          ...task,
          order: {
            ...task.order,
            routineId: taskOrder.routine_id,
            subOrder: taskOrder.sub_order,
            order: taskOrder.order,
            type: taskOrder.type,
          },
        };
      });
      return tasks;
    } catch (error) {}
    return updatedTasks;
  };

  /** ✅ 루틴 이동 (단순 순서 변경) **/
  const moveRoutine = async (
    activeTask: GanttTask,
    updatedTasks: GanttTask[],
    oldIndex: number,
    newIndex: number
  ): Promise<GanttTask[]> => {
    // 🔹 이동할 루틴 및 관련 Task 찾기
    const movingTasks = [activeTask];
    const relatedTasks = updatedTasks.filter(
      (task) =>
        task.order.routineId === activeTask.order.taskId &&
        task.id !== activeTask.id
    );
    movingTasks.push(...relatedTasks);

    // 🔹 기존 위치에서 제거
    movingTasks.forEach((task) => {
      const currentIndex = updatedTasks.findIndex((t) => t.id === task.id);
      if (currentIndex !== -1) {
        updatedTasks.splice(currentIndex, 1);
      }
    });

    // 🔹 새로운 위치에 삽입
    updatedTasks.splice(newIndex, 0, ...movingTasks);

    // 🔹 새로운 order 값 계산
    let order = 0;
    let subOrder = 0;
    const tasks = updatedTasks.map((task) => {
      if (task.order.type === "routine") {
        subOrder = 0;
      }
      return {
        ...task,
        order: {
          ...task.order,
          order: task.order.type !== "task_in_routine" ? ++order : null,
          subOrder: task.order.type === "task_in_routine" ? ++subOrder : null,
        },
      };
    });

    // ✅ 기존 order와 변경된 order 찾기
    const prevOrder = activeTask.order.order; // 이동 전 order 값
    const newOrder = tasks.find((task) => task.id === activeTask.id)?.order
      .order; // 이동 후 order 값

    try {
      if (prevOrder === newOrder) {
        throw new Error("Failed to update routine order");
      }
      const response = await handleReactQueryApiResponse(
        updateRoutineOrder,
        () => setError401Modal(true),
        activeTask.order.taskId,
        prevOrder,
        newOrder,
        innerTabId
      );

      if (!response.ok) {
        throw new Error("Failed to update routine order");
      }

      const responseJson = await response.json();
      const { nodes, edges, order } = responseJson;
      setInnerTabData({ innerTabId: innerTabId, nodes: nodes, edges: edges });
      setOrderTasksInGanttChart({ innerTabId: innerTabId, order: order });
      const updatedOrder = order.find(
        (o: any) => o.task_id === activeTask.order.taskId
      );
      if (!updatedOrder) {
        throw new Error("Failed to update order");
      }
      const tasks = updatedTasks.map((task) => {
        const taskOrder = order.find(
          (o: any) => o.task_id === task.order.taskId
        );
        if (!taskOrder) {
          return task;
        }
        return {
          ...task,
          order: {
            ...task.order,
            routineId: taskOrder.routine_id,
            subOrder: taskOrder.sub_order,
            order: taskOrder.order,
            type: taskOrder.type,
          },
        };
      });
      return tasks;
    } catch (error) {}
    return updatedTasks;
  };

  /** ✅ 루틴 내부로 이동 **/
  const moveRoutineIntoRoutine = async (
    activeTask: GanttTask,
    overTask: GanttTask,
    updatedTasks: GanttTask[],
    oldIndex: number,
    newIndex: number
  ): Promise<GanttTask[]> => {
    const overTaskRoutine = updatedTasks.find(
      (task) =>
        task.order.type === "routine" &&
        task.order.taskId === overTask.order.routineId
    );
    if (!overTaskRoutine) return updatedTasks;
    if (activeTask.order.taskId === overTask.order.routineId)
      return updatedTasks;
    let movingTasks = [activeTask];
    const relatedTasks = updatedTasks.filter(
      (task) =>
        task.order.routineId === activeTask.order.taskId &&
        task.id !== activeTask.id
    );
    movingTasks.push(...relatedTasks);
    movingTasks.forEach((task) => {
      const currentIndex = updatedTasks.findIndex((t) => t.id === task.id);
      if (currentIndex !== -1) {
        updatedTasks.splice(currentIndex, 1);
      }
    });
    const insertIndex =
      updatedTasks.findIndex((task) => task.id === overTask.id) +
      (oldIndex < newIndex ? 1 : 0);
    updatedTasks.splice(insertIndex, 0, ...movingTasks);

    try {
      const response = await handleReactQueryApiResponse(
        moveAnotherRoutine,
        () => setError401Modal(true),
        activeTask.order.taskId,
        activeTask.order.routineId,
        overTask.order.routineId,
        innerTabId
      );
      if (!response.ok) {
        throw new Error("Failed to move task to another routine");
      }
      const responseJson = await response.json();
      const { nodes, edges, order } = responseJson;
      setInnerTabData({ innerTabId: innerTabId, nodes: nodes, edges: edges });
      setOrderTasksInGanttChart({ innerTabId: innerTabId, order: order });
      const updatedOrder = order.find(
        (o: any) => o.task_id === activeTask.order.taskId
      );
      if (!updatedOrder) {
        throw new Error("Failed to update order");
      }
      const tasks = updatedTasks.map((task) => {
        const taskOrder = order.find(
          (o: any) => o.task_id === task.order.taskId
        );
        if (!taskOrder) {
          return task;
        }
        return {
          ...task,
          order: {
            ...task.order,
            routineId: taskOrder.routine_id,
            subOrder: taskOrder.sub_order,
            order: taskOrder.order,
            type: taskOrder.type,
          },
        };
      });
      return tasks;
    } catch (error) {}
    return updatedTasks;
  };

  /** ✅ 루틴이 다른 루틴에서 빠져나감 **/
  // 빠져나갈경우 무조건 마지막으로 이동
  const moveRoutineOutOfRoutine = async (
    activeTask: GanttTask,
    updatedTasks: GanttTask[],
    oldIndex: number,
    newIndex: number
  ): Promise<GanttTask[]> => {
    let movingTasks = [activeTask];
    const relatedTasks = updatedTasks.filter(
      (task) =>
        task.order.routineId === activeTask.order.taskId &&
        task.id !== activeTask.id
    );
    movingTasks.push(...relatedTasks);
    movingTasks.forEach((task) => {
      const currentIndex = updatedTasks.findIndex((t) => t.id === task.id);
      if (currentIndex !== -1) {
        updatedTasks.splice(currentIndex, 1);
      }
    });
    updatedTasks.push(...movingTasks); // 마지막으로 이동

    try {
      const response = await handleReactQueryApiResponse(
        moveOutRoutineInRoutine,
        () => setError401Modal(true),
        activeTask.order.taskId,
        activeTask.order.routineId,
        activeTask.order.taskId,
        innerTabId
      );
      if (!response.ok) {
        throw new Error("Failed to move task to another routine");
      }
      const responseJson = await response.json();
      const { nodes, edges, order } = responseJson;
      setInnerTabData({ innerTabId: innerTabId, nodes: nodes, edges: edges });
      setOrderTasksInGanttChart({ innerTabId: innerTabId, order: order });
      const updatedOrder = order.find(
        (o: any) => o.task_id === activeTask.order.taskId
      );
      if (!updatedOrder) {
        throw new Error("Failed to update order");
      }
      const tasks = updatedTasks.map((task) => {
        const taskOrder = order.find(
          (o: any) => o.task_id === task.order.taskId
        );
        if (!taskOrder) {
          return task;
        }
        return {
          ...task,
          order: {
            ...task.order,
            routineId: taskOrder.routine_id,
            subOrder: taskOrder.sub_order,
            order: taskOrder.order,
            type: taskOrder.type,
          },
        };
      });
      return tasks;
    } catch (error) {}
    return updatedTasks;
  };

  /** ✅ Task가 다른 루틴으로 이동 **/
  const moveTaskToAnotherRoutine = async (
    activeTask: GanttTask,
    overTask: GanttTask,
    updatedTasks: GanttTask[]
  ): Promise<GanttTask[]> => {
    // ✅ 새로운 루틴에서 기존 task_in_routine 개수를 계산
    const newSubOrder =
      overTask.order.type === "routine"
        ? 1
        : (overTask.order.subOrder || 0) + 1;

    // 기존 루틴과 연결된 relation을 끊음 && 새로운 루틴과 연결
    try {
      const response = await handleReactQueryApiResponse(
        moveAnotherRoutine,
        () => setError401Modal(true),
        activeTask.order.taskId,
        activeTask.order.routineId, // ✅ 기존 루틴
        overTask.order.routineId, // ✅ 새 루틴
        innerTabId,
        newSubOrder // ✅ 새로운 subOrder 추가
      );

      if (!response.ok) {
        throw new Error("Failed to move task to another routine");
      }
      const responseJson = await response.json();
      const { nodes, edges, order } = responseJson;
      setInnerTabData({ innerTabId: innerTabId, nodes: nodes, edges: edges });
      setOrderTasksInGanttChart({ innerTabId: innerTabId, order: order });
      const updatedOrder = order.find(
        (o: any) => o.task_id === activeTask.order.taskId
      );
      if (!updatedOrder) {
        throw new Error("Failed to update order");
      }
      const tasks = updatedTasks.map((task) => {
        const taskOrder = order.find(
          (o: any) => o.task_id === task.order.taskId
        );
        if (!taskOrder) {
          return task;
        }
        return {
          ...task,
          order: {
            ...task.order,
            routineId: taskOrder.routine_id,
            subOrder: taskOrder.sub_order,
            order: taskOrder.order,
            type: taskOrder.type,
          },
        };
      });
      return tasks;
    } catch (error) {}
    return updatedTasks;
  };

  /** ✅ independent_task가 다른 루틴으로 이동 **/
  const moveTaskToRoutine = async (
    activeTask: GanttTask,
    overTask: GanttTask,
    updatedTasks: GanttTask[]
  ): Promise<GanttTask[]> => {
    // ✅ 새로운 루틴에서 기존 task_in_routine 개수를 계산
    const newSubOrder =
      overTask.order.type === "routine"
        ? 1
        : (overTask.order.subOrder || 0) + 1;

    // 기존 루틴과 연결된 relation을 끊음 && 새로운 루틴과 연결
    try {
      const response = await handleReactQueryApiResponse(
        moveIndependentTaskIntoRoutine,
        () => setError401Modal(true),
        activeTask.order.taskId,
        overTask.order.routineId, // ✅ 새 루틴
        innerTabId,
        newSubOrder // ✅ 새로운 subOrder 추가
      );

      if (!response.ok) {
        throw new Error("Failed to move task to another routine");
      }
      const responseJson = await response.json();
      const { nodes, edges, order } = responseJson;
      setInnerTabData({ innerTabId: innerTabId, nodes: nodes, edges: edges });
      setOrderTasksInGanttChart({ innerTabId: innerTabId, order: order });
      const updatedOrder = order.find(
        (o: any) => o.task_id === activeTask.order.taskId
      );
      if (!updatedOrder) {
        throw new Error("Failed to update order");
      }
      const tasks = updatedTasks.map((task) => {
        const taskOrder = order.find(
          (o: any) => o.task_id === task.order.taskId
        );
        if (!taskOrder) {
          return task;
        }
        return {
          ...task,
          order: {
            ...task.order,
            routineId: taskOrder.routine_id,
            subOrder: taskOrder.sub_order,
            order: taskOrder.order,
            type: taskOrder.type,
          },
        };
      });
      return tasks;
    } catch (error) {}
    return updatedTasks;
  };

  /** ✅ Task가 루틴에서 빠져나감 **/
  const moveTaskOutOfRoutine = async (
    activeTask: GanttTask,
    updatedTasks: GanttTask[]
  ): Promise<GanttTask[]> => {
    let movingTasks = [activeTask];
    movingTasks.forEach((task) => {
      const currentIndex = updatedTasks.findIndex((t) => t.id === task.id);
      if (currentIndex !== -1) {
        updatedTasks.splice(currentIndex, 1);
      }
    });
    updatedTasks.push(...movingTasks); // 마지막으로 이동

    try {
      const response = await handleReactQueryApiResponse(
        moveOutTaskInRoutine,
        () => setError401Modal(true),
        activeTask.order.taskId,
        activeTask.order.routineId,
        innerTabId
      );
      if (!response.ok) {
        throw new Error("Failed to move task to another routine");
      }
      const responseJson = await response.json();
      const { nodes, edges, order } = responseJson;
      setInnerTabData({ innerTabId: innerTabId, nodes: nodes, edges: edges });
      setOrderTasksInGanttChart({ innerTabId: innerTabId, order: order });
      const updatedOrder = order.find(
        (o: any) => o.task_id === activeTask.order.taskId
      );
      if (!updatedOrder) {
        throw new Error("Failed to update order");
      }
      const tasks = updatedTasks.map((task) => {
        const taskOrder = order.find(
          (o: any) => o.task_id === task.order.taskId
        );
        if (!taskOrder) {
          return task;
        }
        return {
          ...task,
          order: {
            ...task.order,
            routineId: taskOrder.routine_id,
            subOrder: taskOrder.sub_order,
            order: taskOrder.order,
            type: taskOrder.type,
          },
        };
      });
      return tasks;
    } catch (error) {}
    return updatedTasks;
  };

  /** ✅ 루틴 안에 Task가 순서 변경 */
  const moveTaskInRoutine = async (
    activeTask: GanttTask,
    updatedTasks: GanttTask[],
    oldIndex: number,
    newIndex: number
  ): Promise<GanttTask[]> => {
    const newUpdatedTasks = arrayMove(updatedTasks, oldIndex, newIndex);

    let order = 0;
    let subOrder = 0;

    const beforeTasks = newUpdatedTasks.map((task) => {
      if (task.order.type === "routine") {
        subOrder = 0;
      }
      return {
        ...task,
        order: {
          ...task.order,
          routineId: task.order.routineId,
          order: task.order.type !== "task_in_routine" ? ++order : null,
          subOrder: task.order.type === "task_in_routine" ? ++subOrder : null,
        },
      };
    });

    try {
      const response = await handleReactQueryApiResponse(
        updateRoutineTaskOrder,
        () => setError401Modal(true),
        activeTask.order.taskId,
        activeTask.order.routineId,
        activeTask.order.subOrder,
        beforeTasks.find((task) => task.id === activeTask.id)?.order.subOrder,
        innerTabId
      );
      if (!response.ok) {
        throw new Error("Failed to update task subOrder");
      }

      // ✅ 업데이트 성공 시 상태 반영
      const responseJson = await response.json();
      const { nodes, edges, order } = responseJson;

      devConsoleLog("routine move", nodes, edges, order);

      setInnerTabData({ innerTabId: innerTabId, nodes: nodes, edges: edges });
      setOrderTasksInGanttChart({ innerTabId: innerTabId, order: order });
      const updatedOrder = order.find(
        (o: any) => o.task_id === activeTask.order.taskId
      );
      if (!updatedOrder) {
        throw new Error("Failed to update order");
      }
      const tasks = updatedTasks.map((task) => {
        const taskOrder = order.find(
          (o: any) => o.task_id === task.order.taskId
        );
        if (!taskOrder) {
          return task;
        }
        return {
          ...task,
          order: {
            ...task.order,
            routineId: taskOrder.routine_id,
            subOrder: taskOrder.sub_order,
            order: taskOrder.order,
            type: taskOrder.type,
          },
        };
      });
      return tasks;
    } catch (error) {}
    return updatedTasks;
  };

  React.useEffect(() => {
    const ganttChartElement = document.querySelector(".gantt-chart");
    if (ganttChartElement) {
      setHeight(
        ganttChartElement.clientHeight - 85 - (headerMode === "small" ? 75 : 0)
      );
    }
  }, [setHeight, headerMode]);

  React.useEffect(() => {
    const handleResize = () => {
      const ganttChartElement = document.querySelector(".gantt-chart");
      if (ganttChartElement) {
        setHeight(
          ganttChartElement.clientHeight -
            85 -
            (headerMode === "small" ? 75 : 0)
        );
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setHeight, headerMode]);

  React.useEffect(() => {
    setOrderTasks(tasks);

    devConsoleLog(tasks);
  }, [tasks]);

  return (
    <S.Container className="gantt-chart">
      {orderTasks.length === 0 ? (
        <div>{t("plan.contents.gantt.noData")}</div>
      ) : (
        <DndContext
          collisionDetection={closestCenter}
          autoScroll={false}
          onDragEnd={handleDragEnd}
        >
          <Gantt
            tasks={orderTasks}
            viewMode={
              dateMode === "Month"
                ? ViewMode.Month
                : dateMode === "Week"
                ? ViewMode.Week
                : ViewMode.Day
            }
            onDateChange={handleTaskChange}
            onDelete={() => {}}
            // onProgressChange={handleProgressChange}
            onClick={() => {}}
            onDoubleClick={handleClick}
            // onSelect={handleSelect}
            onExpanderClick={expanderClick}
            TaskListHeader={C.TaskListHeader}
            TaskListTable={C.CustomListTable}
            // TooltipContent={C.StandardTooltipContent}
            TooltipContent={() => null}
            listCellWidth={expandList ? "100px" : ""}
            columnWidth={
              dateMode === "Month" ? 300 : dateMode === "Week" ? 120 : 70
            }
            handleWidth={10}
            locale={userLanguage === "KR" ? "ko" : "en"}
            ganttHeight={height}
          />
        </DndContext>
      )}
      <ConditionSelectionModal
        open={modalOpen}
        conditions={modalConditions}
        onClose={handleClose}
        onSelect={handleConditionSelect}
      />
    </S.Container>
  );
};

export default GanttChart;
