import innerTabDataAtom, { InnerTabData } from "@/Atoms/Plan/InnerTabDataAtom";
import {
  deleteInnerTab,
  getPlanData,
  parseGetPlanDataResponse,
  updatePlanData,
} from "@/Queries/PlanQueries";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { atom } from "jotai";
import { Edge, Node } from "@xyflow/react";
import snackbarAtom from "@/Atoms/Snackbar";
import deleteRoutineDialogAtom from "@/Atoms/Dialogs/Delete/RoutineAtom";
import { getPlansAtom } from "./PlanViewModel";
import { getUserId } from "../UserViewModel";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { debounceAsync } from "@/Utils/DebounceAPI";
import { waitingModalAtom } from "@/Atoms/RootAtom";

export const setInnerTabDataAtom = atom(
  (get) => {
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const innerTabData = get(innerTabDataAtom).find(
      (data) => data.innerTabId === innerTabId
    );
    if (!innerTabData) {
      return {
        innerTabId: 0,
        nodes: [],
        edges: [],
      };
    }
    return innerTabData;
  },
  (get, set, innerTabData: InnerTabData) => {
    set(innerTabDataAtom, (prev) => {
      // 기존 id가 존재하는지 확인하고 인덱스를 얻음
      const existingIndex = prev.findIndex(
        (item) => item.innerTabId === innerTabData.innerTabId
      );

      if (existingIndex !== -1) {
        // id가 존재하면 해당 요소를 업데이트
        return prev.map((item, index) =>
          index === existingIndex ? innerTabData : item
        );
      } else {
        // id가 존재하지 않으면 새 데이터를 추가
        return [...prev, innerTabData];
      }
    });

    // 중복된 id 값을 필터링하여 고유한 항목들만 남기기
    set(innerTabDataAtom, (prev) => {
      // Set을 이용해 중복 제거
      const uniqueItems = Array.from(
        new Map(prev.map((item) => [item.innerTabId, item])).values()
      );
      return uniqueItems;
    });
  }
);

// viewType === "mindmap"일 때, 데이터 가져오기
export const getPlanDataMindmapAtom = atom(null, async (get, set) => {
  setTimeout(async () => {
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const response = await handleReactQueryApiResponse(
      getPlanData,
      () => set(error401ModalAtom, true),
      innerTabId
    );
    if (!response.ok) {
      set(snackbarAtom, (prev) => ({
        ...prev,
        open: true,
        message: "데이터를 가져오는데 실패하였습니다.",
      }));
      return;
    }
    const responseJson = await response.json();
    const parsedResponse = parseGetPlanDataResponse(responseJson, innerTabId);
    set(setInnerTabDataAtom, parsedResponse);
  }, 10);
});

export const debounceUpdatePlanDataMindmapAtom = debounceAsync(
  async (set, innerTabId: number, nodes: Node[], edges: Edge[]) => {
    set(updatePlanDataMindmapAtom, innerTabId, nodes, edges);
  }
);

// mindmap에서 Plan 데이터 업데이트 로직
export const updatePlanDataMindmapAtom = atom(
  null,
  async (
    get,
    set,
    innerTabId: number,
    nodes: Node[],
    edges: Edge[],
    deleteEdge: {
      type: "inactivate" | "activate";
      edge: Edge[];
    } | null = null
  ) => {
    const userId = get(getUserId);
    const res = await handleReactQueryApiResponse(
      updatePlanData,
      () => set(error401ModalAtom, true),
      userId,
      innerTabId,
      nodes,
      edges
    );
    if (!res.ok) {
      set(snackbarAtom, (prev) => ({
        ...prev,
        open: true,
        message: "데이터 업데이트에 실패하였습니다.",
      }));
      return;
    }
    const resJson = await res.json();

    const response = resJson.nodes_edges as {
      nodes: {
        id: string;
        backendId: number;
      }[];
      edges: {
        id: string;
        backendId: number;
      }[];
    };
    const newNodes = nodes.map((node) =>
      response.nodes.find((n) => n.id === node.id)
        ? {
            ...node,
            data: {
              ...node.data,
              backendId: response.nodes.find((n) => n.id === node.id)
                ?.backendId,
            },
          }
        : node
    );

    const newEdges = edges.map((edge) =>
      response.edges.find((e) => e.id === edge.id)
        ? {
            ...edge,
            data: {
              ...edge.data,
              backendId: response.edges.find((e) => e.id === edge.id)
                ?.backendId,
            },
          }
        : edge
    );

    set(setInnerTabDataAtom, {
      innerTabId,
      nodes: newNodes,
      edges: deleteEdge
        ? deleteEdge.type === "inactivate"
          ? newEdges.map((edge) => {
              const isDelete = deleteEdge.edge.some(
                (del) => del.id === edge.id
              );

              if (isDelete) {
                edge.hidden = true;
              }

              return edge;
            })
          : newEdges.map((edge) => {
              const isDelete = deleteEdge.edge.some(
                (del) => del.id === edge.id
              );

              if (isDelete) {
                edge.hidden = false;
              }

              return edge;
            })
        : newEdges,
    });
    set(waitingModalAtom, { state: false, text: "" });
  }
);

// 기본 innerTab의 데이터 가져오기
export const defaultInnerTabDataAtom = atom(null, async (get, set) => {
  const innerTabId = GetIdFromQuerystring("id");
  const response = await handleReactQueryApiResponse(
    getPlanData,
    () => set(error401ModalAtom, true),
    innerTabId
  );
  const parsedResponse = parseGetPlanDataResponse(response, innerTabId);
  set(setInnerTabDataAtom, parsedResponse);
});

// 현재 innerTab의 데이터 가져오기
export const currentInnerTabDataAtom = atom((get) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const innerTabData = get(innerTabDataAtom).find(
    (data) => data.innerTabId === innerTabId
  );
  if (!innerTabData) {
    return {
      id: 0,
      nodes: [],
      edges: [],
    };
  }
  return innerTabData;
});

// delete routine (현재 innerTab 삭제)
export const deleteInnerTabAtom = atom(null, async (get, set) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const userId = get(getUserId);
  const response = await handleReactQueryApiResponse(
    deleteInnerTab,
    () => set(error401ModalAtom, true),
    userId,
    innerTabId
  );
  if (!response.ok) {
    set(snackbarAtom, (prev) => ({
      ...prev,
      open: true,
      message: "루틴 삭제에 실패하였습니다. 잠시 후 다시 시도해주세요.",
    }));
    return;
  }
  set(deleteRoutineDialogAtom, { open: true, complete: true });
  set(getPlansAtom);
});
