import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { atom, Setter } from "jotai";
import {
  clearRoutineSidebarDataAtom,
  pushRoutineSidebarDataAtom,
} from "./SidebarViewModel";
import { Edge, Node } from "@xyflow/react";
import { Task } from "gantt-task-react";
import {
  addTasksInRoutineAtom,
  connectedRoutinesAtom,
  editRoutineInRoutineStateAtom,
  editTaskInRoutineStateAtom,
  ganttExpandedRoutineGroupAtom,
  InnerTabTaskOrder,
  orderTasksInGanttChartAtom,
  routineGanttChartLongestLabelWidthAtom,
} from "@/Atoms/Plan/RoutineAtom";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { deleteNodesAndEdges, updateTaskTime } from "@/Queries/PlanQueries";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { getUserId } from "@/ViewModels/UserViewModel";
import { setInnerTabDataAtom } from "../InnerTabViewModel";
import snackbarAtom from "@/Atoms/Snackbar";
import { completeTasksInRoutine } from "@/Queries/RoutineQueries";
import { TaskStatus } from "@/Types/Plan";
import { getTaskOrderByInnerTabId } from "@/Queries/InnerTabQueries";
import { devConsoleError } from "@/Utils/ConsoleLogInDevelopment";
import { GanttTask } from "@/Utils/ConvertNodesToGanttTasks";
import { debounce } from "lodash";

const getMaxTextWidth = (texts: string[], fontSize: string): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const fontFamily = "NanumSquareNeoBrg";
  if (!context) return 0;

  // 폰트 스타일 설정
  context.font = `${fontSize} ${fontFamily}`;
  let maxWidth = 0;

  // 모든 텍스트의 너비 측정
  texts.forEach((text) => {
    const width = context.measureText(text).width;
    if (width > maxWidth) maxWidth = width;
  });

  return Math.ceil(maxWidth); // 소수점 올림
};

export const ganttChartSetLongestLabelWidthAtom = atom(null, (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );
  if (!currentPlanData) return;
  const nodes = currentPlanData.nodes;
  const labels = nodes.map((node) => node.data.label as string);
  const longestLabelWidth = getMaxTextWidth(labels, "0.8rem") + 50; // 30은 padding
  // set(routineGanttChartLongestLabelWidthAtom, longestLabelWidth);
  set(routineGanttChartLongestLabelWidthAtom, 200);
});

// 간트차트의 정렬 순서 가져오기
export const setGanttChartOrderTasksAtom = atom(null, async (get, set) => {
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  try {
    const response = await handleReactQueryApiResponse(
      getTaskOrderByInnerTabId,
      () => set(error401ModalAtom, true),
      innerTabId
    );
    if (!response.ok) {
      throw new Error("Failed to get task order");
    }
    const responseJson = await response.json();
    const order = responseJson.map((res: any) => ({
      routineId: res.routine_id,
      type: res.type,
      innerTabOrderId: res.inner_tab_order_id,
      innerTabId: res.inner_tab_id,
      taskId: res.task_id,
      order: res.order,
      subOrder: res.sub_order,
    }));
    set(ganttChartOrderTasksAtom, { innerTabId, order });
  } catch (error) {
    devConsoleError(error);
  }
});

// 간트 차트의 데이터 오더 내리는 명령어
export const ganttChartOrderTasksAtom = atom(
  (get) => {
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const innerTabData = get(orderTasksInGanttChartAtom).find(
      (data) => data.innerTabId === innerTabId
    );
    if (!innerTabData) {
      return {
        innerTabId: 0,
        order: [],
      };
    }
    return innerTabData;
  },
  (get, set, innerTabData: InnerTabTaskOrder) => {
    set(orderTasksInGanttChartAtom, (prev) => {
      // 기존 id가 존재하는지 확인하고 인덱스를 얻음
      const existingIndex = prev.findIndex(
        (item) => item.innerTabId === innerTabData.innerTabId
      );

      if (existingIndex !== -1) {
        // id가 존재하면 해당 요소를 업데이트
        return prev.map((item, index) =>
          index === existingIndex ? innerTabData : item
        );
      } else {
        // id가 존재하지 않으면 새 데이터를 추가
        return [...prev, innerTabData];
      }
    });

    // 중복된 id 값을 필터링하여 고유한 항목들만 남기기
    set(orderTasksInGanttChartAtom, (prev) => {
      // Set을 이용해 중복 제거
      const uniqueItems = Array.from(
        new Map(prev.map((item) => [item.innerTabId, item])).values()
      );
      return uniqueItems;
    });
  }
);

// 간트차트의 막대 클릭
export const ganttChartBarClickAtom = atom(null, (get, set, taskId: string) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );
  if (!currentPlanData) return;
  const taskNode = currentPlanData.nodes.find(
    (n) => n.id === taskId.split("|")[0]
  );
  if (!taskNode) return;
  const today = new Date();

  if (taskNode.type === "task") {
    const taskStatus = taskNode.data.taskStatus as TaskStatus[];
    const prevTaskStatus = taskStatus.filter((status) =>
      dayjs(status.date).isSameOrBefore(today)
    );
    const sortedTaskStatus = prevTaskStatus.sort((a, b) =>
      dayjs(b.date).diff(dayjs(a.date))
    );
    set(pushRoutineSidebarDataAtom, {
      type: "task",
      key: taskNode,
      tasks: [],
      taskStatus:
        sortedTaskStatus.length === 0 ? undefined : sortedTaskStatus[0],
    });
  } else if (taskNode.type === "routine") {
    const edges = currentPlanData.edges;
    const nodes = currentPlanData.nodes;
    const tasks = edges
      .filter((edge) => edge.source === taskNode.id)
      .map((edge) => nodes.find((node) => node.id === edge.target))
      .filter((node): node is Node => node !== undefined);
    const taskStatuses = tasks
      .map((task) => {
        const taskStatus = task.data.taskStatus as TaskStatus[];
        const prevTaskStatus = taskStatus.filter((status) =>
          dayjs(status.date).isSameOrBefore(today)
        );
        const sortedTaskStatus = prevTaskStatus.sort((a, b) =>
          dayjs(b.date).diff(dayjs(a.date))
        );
        if (sortedTaskStatus.length === 0) return null;
        return sortedTaskStatus[0]; // 가장 가까운 날짜의 taskStatus
      })
      .filter((status) => status !== null) as TaskStatus[];
    set(pushRoutineSidebarDataAtom, {
      type: "routine",
      key: taskNode,
      tasks: tasks,
      taskStatuses: taskStatuses,
    });
  }
});

// expander 클릭
export const ganttChartExpanderClickAtom = atom(
  null,
  (get, set, task: Task) => {
    const expanderList = get(ganttExpandedRoutineGroupAtom);
    set(
      ganttExpandedRoutineGroupAtom,
      expanderList.includes(task.id)
        ? expanderList.filter((id) => id !== task.id)
        : [...expanderList, task.id]
    );
  }
);

// 루틴 수정 모드 클릭
export const ganttChartStartEditRoutineAtom = atom(
  null,
  (get, set, task: Task) => {
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const currentPlanData = planData.find(
      (data) => data.innerTabId === innerTabId
    );
    if (!currentPlanData) return;
    const taskNode = currentPlanData.nodes.find((n) => n.id === task.id);
    if (!taskNode) return;
    set(editRoutineInRoutineStateAtom, {
      id: taskNode.id,
      label: taskNode.data.label as string,
      color: taskNode.data.color as string,
      isLoading: false,
      content: taskNode.data.content as string,
      backendId: taskNode.data.backendId as number,
    });

    set(pushRoutineSidebarDataAtom, {
      type: "ganttEditRoutine",
      key: taskNode,
      tasks: [],
    });
  }
);

// 루틴의 할 일 추가 모드 클릭
export const ganttChartStartAddTaskAtom = atom(null, (get, set, task: Task) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );
  if (!currentPlanData) return;
  const currentRoutine = currentPlanData.nodes.find((n) => n.id === task.id);
  if (!currentRoutine) return;
  const { nodes, edges } = currentPlanData;
  const tasks = nodes.filter((node) => node.type === "task");
  const routineTasks = edges
    .filter((edge) => edge.source === currentRoutine.id)
    .map((edge) => nodes.find((node) => node.id === edge.target))
    .filter((node): node is Node => node !== undefined);
  const notConnectedTasks = tasks.filter(
    (task) => !routineTasks.includes(task)
  );
  set(addTasksInRoutineAtom, []);
  set(pushRoutineSidebarDataAtom, {
    type: "ganttAddTaskToRoutine",
    key: currentRoutine,
    tasks: notConnectedTasks,
  });
});

type Hashtag = {
  backgroundColor: string;
  textColor: string;
  value: string;
};

// 할 일 수정 모드 클릭
export const ganttChartStartEditTaskAtom = atom(
  null,
  (get, set, task: Task) => {
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const currentPlanData = planData.find(
      (data) => data.innerTabId === innerTabId
    );
    if (!currentPlanData) return;
    const { nodes, edges } = currentPlanData;
    const taskId = task.id.split("|")[0];
    const editTask = nodes.find((node) => node.id === taskId);
    if (!editTask) return;
    const routines = nodes.filter((node) => node.type === "routine");
    const targetEdges = edges.filter((edge) => edge.target === taskId);
    const connectedRoutines = targetEdges
      .map((edge) => routines.find((routine) => routine.id === edge.source))
      .filter((routine): routine is Node => routine !== undefined);
    set(editTaskInRoutineStateAtom, {
      id: editTask.id,
      label: editTask.data.label as string,
      content: editTask.data.content as string,
      startDate: dayjs(editTask.data.startDate as string),
      originStartDate: dayjs(editTask.data.startDate as string),
      endDate: editTask.data.endDate
        ? dayjs(editTask.data.endDate as string)
        : null,
      termType: editTask.data.termType as number | null,
      termData: editTask.data.termData as string[] | null,
      hashtags: editTask.data.hashtags as Hashtag[],
      color: editTask.data.color as string,
      times: editTask.data.times as string[],
      isLoading: false,
      backendId: editTask.data.backendId as number,
    });
    const connected = connectedRoutines.map((routine) => ({
      id: routine.id,
      backendId: routine.data.backendId as number,
      label: routine.data.label as string,
      color: routine.data.color as string,
    }));
    set(connectedRoutinesAtom, connected);
    set(pushRoutineSidebarDataAtom, {
      type: "ganttEditTask",
      key: editTask,
      tasks: [],
    });
  }
);

// 할 일 삭제
export const ganttChartDeleteTaskAtom = atom(
  null,
  async (get, set, task: Task, t: TFunction) => {
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const currentPlanData = planData.find(
      (data) => data.innerTabId === innerTabId
    );
    if (!currentPlanData) return;
    const taskId = task.id.split("|")[0];
    const key = currentPlanData.nodes.find((node) => node.id === taskId);
    if (!key) return;
    const edges = currentPlanData.edges;
    const connectedEdges = edges
      .filter((edge) => edge.source === key.id || edge.target === key.id)
      .map((edge) => edge.data?.backendId as number);
    const notConnectedEdges = edges.filter(
      (edge) => edge.source !== key.id && edge.target !== key.id
    );
    const nodes = currentPlanData.nodes;
    const filteredNodes = nodes.filter((node) => node.id !== key.id);

    try {
      const response = await handleReactQueryApiResponse(
        deleteNodesAndEdges,
        () => set(error401ModalAtom, true),
        [key.data.backendId as number],
        connectedEdges,
        innerTabId,
        get(getUserId)
      );
      if (!response.ok) {
        throw new Error(t("plan.contents.routine.snackbar.deleteTaskFail"));
      }
      set(setInnerTabDataAtom, {
        innerTabId,
        nodes: filteredNodes,
        edges: notConnectedEdges,
      });
      set(snackbarAtom, {
        open: true,
        message: t("plan.contents.routine.snackbar.deleteTaskSuccess"),
        severity: "success",
      });
      set(clearRoutineSidebarDataAtom);
    } catch (error) {
      set(snackbarAtom, {
        open: true,
        message: t("plan.contents.routine.snackbar.deleteTaskFail"),
        severity: "error",
      });
    }
  }
);

// 할 일 완료 요청
export const ganttRequestChangeTaskStatusAtom = atom(
  null,
  async (get, set, task: Node, t: TFunction) => {
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const currentPlanData = planData.find(
      (data) => data.innerTabId === innerTabId
    );
    if (!currentPlanData) return;

    const upperTaskId = task.data?.backendId as number;
    const lowerTaskIds = [task.data?.backendId as number];

    try {
      const response = await handleReactQueryApiResponse(
        completeTasksInRoutine,
        () => set(error401ModalAtom, true),
        upperTaskId,
        lowerTaskIds
      );

      if (!response.ok) {
        set(snackbarAtom, {
          open: true,
          message: t("plan.contents.routine.snackbar.taskStatusChangeFail"),
          severity: "error",
        });
      }

      const responseJson = await response.json();
      const updatedTaskStatus =
        responseJson.lower_task_status_response as TaskStatus[];

      // 완료한 세부 할 일 상태 업데이트
      const prevTaskStatus = task.data?.taskStatus as TaskStatus[];

      const newTaskStatus = prevTaskStatus.map((prevStatus) => {
        // 기존 상태에 해당하는 업데이트가 있는지 확인
        const updatedStatus = updatedTaskStatus.find(
          (update) => update.taskStatusId === prevStatus.taskStatusId
        );

        // 업데이트가 있으면 대체, 없으면 기존 상태 유지
        return updatedStatus ? updatedStatus : prevStatus;
      });

      // 새로 추가된 상태를 필터링하여 병합
      const addedTaskStatus = updatedTaskStatus.filter(
        (update) =>
          !prevTaskStatus.some(
            (prevStatus) => prevStatus.taskStatusId === update.taskStatusId
          )
      );

      // 병합된 상태 배열
      const finalTaskStatus = [...newTaskStatus, ...addedTaskStatus];

      // 상태 업데이트
      const newTask = {
        ...task,
        data: {
          ...task.data,
          taskStatus: finalTaskStatus,
        },
      };

      const newNodes = currentPlanData.nodes.map((node) =>
        node.id === task.id ? newTask : node
      );

      set(setInnerTabDataAtom, {
        innerTabId,
        nodes: newNodes,
        edges: currentPlanData.edges,
      });
    } catch (error) {
      set(snackbarAtom, {
        open: true,
        message: t("plan.contents.routine.snackbar.taskStatusChangeFail"),
        severity: "error",
      });
    }
  }
);

export const ganttChartChangeTaskPositionAtom = atom(
  null,
  async (get, set, task: GanttTask) => {
    if (task.order.type === "routine") {
      return;
    }

    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const plan = planData.find((data) => data.innerTabId === innerTabId);
    if (!plan) return;

    const nodes = plan.nodes;
    const taskId = task.id.split("|")[0];
    const node = nodes.find((n) => n.id === taskId);
    if (!node) return;

    debouncedUpdateTaskTime(
      node.data.backendId as number,
      taskId,
      task.start.toISOString(),
      task.end.toISOString(),
      nodes,
      set,
      innerTabId,
      plan.edges
    );
  }
);

// ✅ Debounce 적용된 API 호출 함수
const debouncedUpdateTaskTime = debounce(
  async (
    backendId: number,
    taskId: string,
    start: string,
    end: string,
    nodes: Node[],
    set: Setter,
    innerTabId: number,
    edges: Edge[]
  ) => {
    try {
      const response = await handleReactQueryApiResponse(
        updateTaskTime,
        () => set(error401ModalAtom, true),
        backendId,
        start,
        end
      );

      if (!response.ok) {
        throw new Error("Failed to update task time");
      }

      await response.json().then((updatedTask: Node) => {
        const newNodes = nodes.map((n) => (n.id === taskId ? updatedTask : n));
        set(setInnerTabDataAtom, { innerTabId, nodes: newNodes, edges });
      });
    } catch (error) {
      devConsoleError(error);
    }
  },
  100 // ✅ Debounce 시간 (500ms)
);
