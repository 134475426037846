import { notSavedSignatureDialogAtom, signatureDialogAtom } from '@/Atoms/Signature/Atom';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { closeNotSavedSignatureDialogAtom } from '@/ViewModels/Signature/ViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotSaveSignatureDialog = () => {
  const { t } = useTranslation();
  const [dialog, setDialog] = useAtom(notSavedSignatureDialogAtom);
  const prev = useSetAtom(closeNotSavedSignatureDialogAtom);
  const signatureDialog = useAtomValue(signatureDialogAtom);

  return (
    <Dialog open={dialog} onClose={() => setDialog(false)} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>
        {t("signature.notSave.title")}
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: "20px", fontSize: "0.8rem" }}>
        <div>
          {t(signatureDialog.mode === "create" ? "signature.notSave.content1" : "signature.notSave.editContent1")}
        </div>
        <div>
          {t(signatureDialog.mode === "create" ? "signature.notSave.content2" : "signature.notSave.editContent2")}
        </div>
      </DialogContent>
      <DialogActions>
        <CustomColorButton text={t("signature.notSave.cancel")} color="gray" onClick={() => setDialog(false)} fontSize="0.8rem" />
        <CustomBackgroundColorButton text={t("signature.notSave.goBack")} backgroundColor="red" onClick={prev} fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  )
}

export default NotSaveSignatureDialog;
