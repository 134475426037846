import React from "react";
import * as S from "./styled";
import { Task } from "gantt-task-react";
import { useAtomValue, useSetAtom } from "jotai";
import { Menu, MenuItem } from "@mui/material";
import { DragIndicator, Menu as MenuIcon } from "@mui/icons-material";
import { Edge, Node } from "@xyflow/react";
import dayjs from "dayjs";
import {
  onEdgesChangeAtom,
  onNodesChangeAtom,
} from "@/ViewModels/Plan/Mindmap/FlowViewModel";
import { useTranslation } from "react-i18next";
import { checkPlanUserIdAtom } from "@/ViewModels/Plan/PlanViewModel";
import {
  ganttChartStartEditRoutineAtom,
  ganttChartStartEditTaskAtom,
  ganttChartDeleteTaskAtom,
  ganttChartStartAddTaskAtom,
  ganttRequestChangeTaskStatusAtom,
  ganttChartBarClickAtom,
} from "@/ViewModels/Plan/Routine/GanttChartViewModel";
import {
  deleteRoutineModalOpenAtom,
  routineGanttChartLongestLabelWidthAtom,
} from "@/Atoms/Plan/RoutineAtom";
import getClosestTaskStatus from "@/Utils/GetClosestTaskstatus";
import { TaskStatus } from "@/Types/Plan";
import isTaskIncludedRoutine from "@/Utils/Plan/IsTaskIncludedRoutine";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GanttTask } from "@/Utils/ConvertNodesToGanttTasks";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";

// TaskListHeader 인터페이스
interface ITaskListHeader {
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}

// TaskListHeader 커스터마이징
export const TaskListHeader: React.FC<ITaskListHeader> = ({
  headerHeight = 50,
  rowWidth = 50,
  fontSize = "1rem",
}) => {
  const { t } = useTranslation();
  const labelWidth = useAtomValue(routineGanttChartLongestLabelWidthAtom);

  return (
    <S.GanttTable>
      <S.GanttTableHeader
        style={{
          height: headerHeight - 2,
        }}
      >
        <S.GanttTableHeaderItem
          style={{
            minWidth: labelWidth,
          }}
        >
          &nbsp;{t("plan.contents.gantt.name")}&nbsp;
        </S.GanttTableHeaderItem>
        <S.GanttTableHeaderSeparator
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.2,
          }}
        />
        <S.GanttTableHeaderItem
          style={{
            minWidth: "100px",
          }}
        >
          &nbsp;{t("plan.contents.gantt.startDate")}&nbsp;
        </S.GanttTableHeaderItem>
        <S.GanttTableHeaderSeparator
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.25,
          }}
        />
        <S.GanttTableHeaderItem
          style={{
            minWidth: "100px",
          }}
        >
          &nbsp;{t("plan.contents.gantt.endDate")}&nbsp;
        </S.GanttTableHeaderItem>
        <S.GanttTableHeaderSeparator
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.25,
          }}
        />
        <S.GanttTableHeaderItem
          style={{
            minWidth: "70px",
          }}
        >
          &nbsp;{t("plan.contents.gantt.status")}&nbsp;
        </S.GanttTableHeaderItem>
        <S.GanttTableHeaderSeparator
          style={{
            height: headerHeight * 0.5,
            marginTop: headerHeight * 0.25,
          }}
        />
      </S.GanttTableHeader>
    </S.GanttTable>
  );
};

export const CustomListTable: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = ({
  rowHeight = 50,
  rowWidth = "100px",
  tasks,
  fontFamily,
  fontSize = "12px",
  locale,
  onExpanderClick,
}) => {
  const nodes = useAtomValue(onNodesChangeAtom);
  const edges = useAtomValue(onEdgesChangeAtom);
  const [routineMenuAnchorEl, setRoutineMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [stateAnchorEl, setStateAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [selectedTaskStatusComplete, setSelectedTaskStatusComplete] =
    React.useState<boolean>(false);
  const [selectedTask, setSelectedTask] = React.useState<Task | null>(null);
  const { t: tt } = useTranslation();
  const editTask = useSetAtom(ganttChartStartEditTaskAtom);
  const deleteTask = useSetAtom(ganttChartDeleteTaskAtom);
  const editRoutine = useSetAtom(ganttChartStartEditRoutineAtom);
  const openDeleteRoutineModal = useSetAtom(deleteRoutineModalOpenAtom);
  const addTaskInRoutine = useSetAtom(ganttChartStartAddTaskAtom);
  const changeTaskStatus = useSetAtom(ganttRequestChangeTaskStatusAtom);
  const labelWidth = useAtomValue(routineGanttChartLongestLabelWidthAtom);

  const handleMenuClick = (key: string) => {
    if (selectedTask) {
      if (key === "edit") {
        editTask(selectedTask);
      } else if (key === "delete") {
        deleteTask(selectedTask, tt);
      } else if (key === "done" || key === "wait") {
        const node = nodes.find((n) => n.id === selectedTask.id.split("|")[0]);
        if (!node) return;
        changeTaskStatus(node, tt);
      }
    }
    setSelectedTask(null);
    setAnchorEl(null);
    setStateAnchorEl(null);
  };

  const handleRoutineMenuClick = (key: string) => {
    if (selectedTask) {
      if (key === "edit") {
        editRoutine(selectedTask);
      } else if (key === "delete") {
        openDeleteRoutineModal(selectedTask.id);
      } else if (key === "add") {
        addTaskInRoutine(selectedTask);
      }
    }
    setSelectedTask(null);
    setRoutineMenuAnchorEl(null);
  };

  return (
    <S.TaskListWrapper
      style={{
        fontSize: fontSize,
      }}
    >
      <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
        {tasks.map((task, idx) => {
          return (
            <CustomListItem
              key={task.id}
              task={task}
              idx={idx}
              rowHeight={rowHeight}
              labelWidth={labelWidth}
              rowWidth={rowWidth}
              onExpanderClick={onExpanderClick}
              nodes={nodes}
              edges={edges}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              setRoutineMenuAnchorEl={setRoutineMenuAnchorEl}
              setAnchorEl={setAnchorEl}
              setStateAnchorEl={setStateAnchorEl}
              setSelectedTaskStatusComplete={setSelectedTaskStatusComplete}
            />
          );
        })}
      </SortableContext>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ style: { padding: 0 } }}
      >
        <MenuItem onClick={() => handleMenuClick("edit")}>
          {tt("plan.contents.routine.gantt.edit")}
        </MenuItem>
        <MenuItem onClick={() => handleMenuClick("delete")}>
          {tt("plan.contents.routine.gantt.delete")}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={routineMenuAnchorEl}
        open={Boolean(routineMenuAnchorEl)}
        onClose={() => setRoutineMenuAnchorEl(null)}
        MenuListProps={{ style: { padding: 0 } }}
      >
        <MenuItem onClick={() => handleRoutineMenuClick("add")}>
          {tt("plan.contents.routine.gantt.addTaskInRoutine")}
        </MenuItem>
        <MenuItem onClick={() => handleRoutineMenuClick("edit")}>
          {tt("plan.contents.routine.gantt.edit")}
        </MenuItem>
        <MenuItem onClick={() => handleRoutineMenuClick("delete")}>
          {tt("plan.contents.routine.gantt.delete")}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={stateAnchorEl}
        open={Boolean(stateAnchorEl)}
        onClose={() => setStateAnchorEl(null)}
        MenuListProps={{ style: { padding: 0 } }}
      >
        {selectedTaskStatusComplete ? (
          <MenuItem onClick={() => handleMenuClick("wait")}>
            {tt("plan.contents.routine.gantt.wait")}
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleMenuClick("done")}>
            {tt("plan.contents.routine.gantt.done")}
          </MenuItem>
        )}
      </Menu>
    </S.TaskListWrapper>
  );
};

interface CustomListItemProps {
  task: Task;
  idx: number;
  rowHeight: number;
  labelWidth: number;
  rowWidth: string;
  onExpanderClick: (task: Task) => void;
  nodes: Node[];
  edges: Edge[];
  selectedTask: Task | null;
  setSelectedTask: React.Dispatch<React.SetStateAction<Task | null>>;
  setRoutineMenuAnchorEl: (el: HTMLElement | null) => void;
  setAnchorEl: (el: HTMLElement | null) => void;
  setStateAnchorEl: (el: HTMLElement | null) => void;
  setSelectedTaskStatusComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomListItem = ({
  task,
  idx,
  rowHeight,
  labelWidth,
  rowWidth,
  nodes,
  edges,
  setSelectedTask,
  setRoutineMenuAnchorEl,
  setAnchorEl,
  setStateAnchorEl,
  setSelectedTaskStatusComplete,
  onExpanderClick,
}: CustomListItemProps) => {
  const handleNameClick = useSetAtom(ganttChartBarClickAtom);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: task.id, animateLayoutChanges: () => false });

  const dndStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const isMine = useAtomValue(checkPlanUserIdAtom);
  const handleMenuOpenClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    task: Task
  ) => {
    if (task.type === "project") {
      setSelectedTask(task);
      setRoutineMenuAnchorEl(e.currentTarget);
    } else {
      setSelectedTask(task);
      setAnchorEl(e.currentTarget);
    }
  };
  const handleStatusOpenClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    task: Task,
    isComplete: boolean
  ) => {
    if (task.type === "project") {
      setSelectedTask(task);
      // setStateAnchorEl(e.currentTarget);
    } else {
      setSelectedTask(task);
      setStateAnchorEl(e.currentTarget);
      setSelectedTaskStatusComplete(isComplete);
    }
  };
  let expanderSymbol = "";
  if (task.hideChildren === false) {
    expanderSymbol = "▼";
  } else if (task.hideChildren === true) {
    expanderSymbol = "▶";
  }
  const depth = (task as GanttTask).depth as number;
  const IsExpender = expanderSymbol
    ? S.TaskListExpander
    : S.TaskListEmptyExpander;
  const id = task.id.split("|")[0];
  const node = nodes.find((n) => n.id === id);
  if (!node) return null;
  const connectedNodes = edges
    .filter((edge) => edge.source === id)
    .map((edge) => nodes.find((n) => n.id === edge.target));

  const includedRoutine = isTaskIncludedRoutine(node, nodes, edges);
  const taskName = task.name ? task.name : (node?.data.label as string) || "";
  const taskStatus = node.data.taskStatus as TaskStatus[];
  const prevTaskStatus = taskStatus.filter((status) =>
    dayjs(status.date).isSameOrBefore(new Date())
  );
  const sortedTaskStatus = prevTaskStatus.sort((a, b) =>
    dayjs(b.date).diff(dayjs(a.date))
  );
  const isExistTodayStatus =
    sortedTaskStatus.length === 0 ? null : sortedTaskStatus[0];

  const type = task.type;

  let endDate = "-";
  let status = "-";
  if (type === "project") {
    // connectedNodes의 endDate 중 가장 큰 값을 찾아서 endDate로 설정
    const endDates = connectedNodes.map((node) =>
      node?.data.endDate ? dayjs(node?.data.endDate as string) : dayjs()
    );
    const maxEndDate = dayjs.max(endDates);
    if (maxEndDate) {
      endDate = maxEndDate.format("YYYY-MM-DD");
    }
    const taskStatusesInConnectedNodes = connectedNodes.map((node) => {
      if (node && Array.isArray(node.data.taskStatus)) {
        return getClosestTaskStatus(
          node?.data.taskStatus as TaskStatus[],
          new Date()
        );
      } else {
        return null;
      }
    });

    if (taskStatusesInConnectedNodes) {
      const taskStatuses = taskStatusesInConnectedNodes.filter(
        (status) => status !== null
      );
      const completedTaskStatuses = taskStatuses.filter(
        (status) => status.status === 1
      );
      if (completedTaskStatuses.length !== taskStatuses.length) {
        status = `${completedTaskStatuses.length}/${taskStatuses.length}`;
      } else if (
        completedTaskStatuses.length === taskStatuses.length &&
        taskStatuses.length !== 0
      ) {
        status = "완료";
      }
    }
  } else if (type === "task") {
    if (node?.data.endDate) {
      endDate = dayjs(node?.data.endDate as string).format("YYYY-MM-DD");
    }
    if (isExistTodayStatus) {
      status = isExistTodayStatus.status === 0 ? "대기" : "완료";
    }
  }

  return (
    <S.TaskListTableRow
      style={{ height: rowHeight, ...dndStyle }}
      key={`${task.id}row-${idx}`}
      ref={setNodeRef}
    >
      <S.TaskListCell
        style={{
          minWidth: labelWidth,
          maxWidth: labelWidth,
          cursor: "pointer",
        }}
      >
        <S.TaskListNameWrapper>
          <DragIndicator
            sx={{
              width: "20px",
              height: "20px",
              cursor: "move",
              marginLeft: "5px",
            }}
            {...listeners}
            {...attributes}
          />
          <IsExpender
            $includedRoutine={includedRoutine}
            $depth={depth}
            onClick={() => onExpanderClick(task)}
          >
            {expanderSymbol}
          </IsExpender>
          <S.TaskListName
            $includedRoutine={includedRoutine}
            onClick={(e) => {
              // setSelectedTask(t);
              // isMine && setAnchorEl(e.currentTarget)
              // task.type === "project" && onExpanderClick(task);
              // task.type === "task" && handleMenuOpenClick(e, task);
              handleNameClick(task.id);
            }}
          >
            {taskName}
          </S.TaskListName>
        </S.TaskListNameWrapper>
      </S.TaskListCell>
      <S.TaskListCell
        style={{
          minWidth: rowWidth,
          maxWidth: rowWidth,
          textAlign: "center",
          cursor: "pointer",
          fontSize: "0.8rem",
          fontWeight: "bold",
        }}
        onClick={(e) => {
          setSelectedTask(task);
          isMine && handleMenuOpenClick(e, task);
        }}
      >
        {/* &nbsp;{node?.data.startDate ? dayjs(node?.data.startDate as string).format("YYYY-MM-DD") : "-"} */}
        &nbsp;{dayjs(task.start).format("YYYY-MM-DD")}
      </S.TaskListCell>
      <S.TaskListCell
        style={{
          minWidth: rowWidth,
          maxWidth: rowWidth,
          textAlign: "center",
          cursor: "pointer",
          fontSize: "0.8rem",
          fontWeight: "bold",
        }}
        onClick={(e) => {
          setSelectedTask(task);
          isMine && handleMenuOpenClick(e, task);
        }}
      >
        &nbsp;{endDate}
      </S.TaskListCell>
      <S.TaskListCell
        style={{
          minWidth: "70px",
          maxWidth: "70px",
          textAlign: "center",
          cursor: "pointer",
          fontSize: "0.8rem",
          fontWeight: "bold",
        }}
        onClick={(e) => {
          isMine &&
            status !== "-" &&
            handleStatusOpenClick(e, task, status === "완료");
        }}
      >
        &nbsp;{status}
      </S.TaskListCell>
    </S.TaskListTableRow>
  );
};
