import React, { useEffect, useState } from "react";
import * as S from "./styled";
import {
  Box,
  Button,
  IconButton,
  Input,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Handle, HandleType, Node } from "@xyflow/react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import theme from "../../../../../../Styles/theme";
import { togetherDialogAtom } from "@/Atoms/Dialogs/Together";
import {
  careerPathAtom,
  createNewNodeGroupModalAtom,
  selectedNodeAtom,
} from "@/Atoms/Plan/MindmapAtom";
import { updateNodeAtom } from "@/ViewModels/Plan/Mindmap/FlowViewModel";
import inactivateDialogAtom from "@/Atoms/Dialogs/Inactivate";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import useIsMobile from "@/Hooks/useIsMobile";
import { checkPlanUserIdAtom } from "@/ViewModels/Plan/PlanViewModel";
import * as C from "./container";
import { useTranslation } from "react-i18next";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { getCurrentCurriculumStateAtom } from "@/ViewModels/Plan/Mindmap/Nodes/CurriculumViewModel";
import { isExistParentNodeTypeGroupAtom } from "@/ViewModels/Plan/Mindmap/Modals/CreateNewNodeGroupViewModel";

const CurriculumNode = (data: any) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const contentRef = React.useRef<HTMLDivElement>(null); // 확장된 높이를 참조할 수 있는 useRef
  const [, updateNode] = useAtom(updateNodeAtom);
  const selectedNode = useAtomValue(selectedNodeAtom);
  const careerPath = useAtomValue(careerPathAtom);
  const type = data.type;
  const setTogetherDialog = useSetAtom(togetherDialogAtom);
  const setInactivateDialog = useSetAtom(inactivateDialogAtom);
  const isMine = useAtomValue(checkPlanUserIdAtom);
  const isExpanded = selectedNode?.id === data.id || data.data.isNewCreated;
  const curriculumState = useAtomValue(getCurrentCurriculumStateAtom);
  const isVisible =
    curriculumState?.state.find((state) => state.id === data.id)?.visible ||
    false;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [popoverNode, setPopoverNode] = React.useState<Node | null>(null);
  const openCreateNewNodeGroupModal = useSetAtom(createNewNodeGroupModalAtom);
  const isExistNodeTypeGroup = useAtomValue(isExistParentNodeTypeGroupAtom)(
    data
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>, node: Node) => {
    setAnchorEl(event.currentTarget as HTMLButtonElement);
    setPopoverNode(node);
  };

  const handleClose = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.MouseEvent<Document, MouseEvent>
  ) => {
    event?.stopPropagation();
    setAnchorEl(null);
    setPopoverNode(null);
  };

  const [localLabel, setLocalLabel] = useState(data.data.label);
  const [localContent, setLocalContent] = useState(data.data.content);

  useEffect(() => {
    updateNode({
      ...data.data,
      label: localLabel,
    });
  }, [localLabel]);

  useEffect(() => {
    updateNode({
      ...data.data,
      content: localContent,
    });
  }, [localContent]);

  // 경로에 있는지 여부에 따른 활성/비활성 스타일 결정
  const isPathActive = careerPath?.path.includes(data.id);
  const isCareerPathNull = careerPath === null;

  return (
    // isVisible &&
    // curriculumState &&
    data.data.activate !== 2 && (
      <>
        <C.NodeToolbars data={data} />
        <Stack
          className="nowheel"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            transition: "filter 0.5s ease", // 어두워지는 효과
            filter:
              isCareerPathNull || isPathActive ? "none" : "brightness(50%)", // 경로에 없으면 어둡게
          }}
          onClick={(e) => {
            if (selectedNode?.id === data.id) {
              e.stopPropagation();
            }
          }}
        >
          <S.Container
            id={`${data.id}`}
            $isActivate={data.data.activate === 1}
            $isSelecting={selectedNode?.id === data.id}
            $color={theme.colors[type]}
            style={{
              transition: "max-height 0.5s ease", // 애니메이션 추가
              // maxHeight: maxHeight, // 동적 max-height 설정
              overflow: "hidden", // 넘치는 내용 숨기기
            }}
            ref={contentRef} // contentRef를 참조
          >
            <Stack
              direction={"row"}
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    background: theme.colors[data.type],
                    padding: "5px 10px",
                    color: "white",
                    borderRadius: "20px",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  {t(`plan.contents.mindmap.nodes.job.typeLabel.${data.type}`)}
                </Box>
                {!isExistNodeTypeGroup && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      openCreateNewNodeGroupModal({
                        node: data,
                        isLoading: false,
                      });
                    }}
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      padding: "0px",
                      color: theme.colors.primary,
                    }}
                  >
                    {t("plan.contents.mindmap.nodes.createNewNodeGroup")}
                  </Button>
                )}
              </div>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setInactivateDialog({ open: true, node: data });
                }}
              >
                {data.data.activate === 1 ? (
                  <VisibilityOffOutlined
                    sx={{ width: "30px", height: "30px" }}
                  />
                ) : (
                  <VisibilityOutlined sx={{ width: "30px", height: "30px" }} />
                )}
              </IconButton>
            </Stack>
            {/* 핸들: source/target을 동적으로 설정 */}
            {C.handlePositions(isMobile).map((handle) =>
              ["source", "target"].map((type) => (
                <Handle
                  key={`${data.id}-${handle.id}-${type}`}
                  type={type as HandleType}
                  position={handle.position}
                  id={`${data.id}-${handle.id}-${type}`}
                  className="handle"
                  style={{
                    ...handle.additionalStyle,
                    backgroundColor:
                      selectedNode && selectedNode.id === data.id
                        ? "#d9d9d9"
                        : "transparent",
                  }}
                />
              ))
            )}
            {isMine && isExpanded ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Input
                    value={localLabel}
                    onChange={(e) => setLocalLabel(e.target.value)}
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "1.3rem",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    value={localContent}
                    onChange={(e) => setLocalContent(e.target.value)}
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "1rem",
                    }}
                    multiline
                    maxRows={5}
                  />
                </div>

                {curriculumState &&
                  curriculumState.notVisibleNodes &&
                  curriculumState.notVisibleNodes.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        alignItems: "flex-start",
                        gap: "10px",
                      }}
                    >
                      {t(
                        "plan.contents.mindmap.nodes.curriculum.additionalProgram"
                      )}
                      <List
                        component="nav"
                        aria-label="curriculum-list"
                        sx={{
                          width: "100%",
                          height: "300px",
                          overflow: "auto",
                        }}
                      >
                        {curriculumState.notVisibleNodes.map(
                          (node: Node, index) => (
                            <ListItemButton
                              key={index}
                              onClick={(e) => handleClick(e, node)}
                            >
                              <ListItemText
                                primary={node.data.label as string}
                              />
                            </ListItemButton>
                          )
                        )}
                      </List>
                    </div>
                  )}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomColorButton
                    text={t("plan.contents.mindmap.nodes.job.together")}
                    onClick={() => setTogetherDialog({ node: data })}
                    fontSize="1.2rem"
                  />
                </div>
              </>
            ) : !isMine && selectedNode?.id === data.id ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{ fontSize: "1.2rem", textAlign: "start" }}
                  >
                    {data.data.label}
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{ fontSize: "1.2rem", textAlign: "start" }}
                  >
                    {data.data.label}
                  </Typography>
                </div>
              </>
            )}
          </S.Container>
        </Stack>
        <Popover
          id={Boolean(anchorEl) ? "simple-popover" : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {popoverNode && (
            <Box
              sx={{
                width: "300px",
                padding: "10px",
                gap: "10px",
                display: "flex",
                flexDirection: "column",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                {popoverNode.data.label as string}
              </div>
              <div style={{ fontSize: "0.7rem" }}>
                {popoverNode.data.content as string}
              </div>
            </Box>
          )}
        </Popover>
      </>
    )
  );
};

export default CurriculumNode;
