import React from "react";
import DokgabiCreateHeader from "../../Header/Create";
import { Box, Divider, IconButton, TextField, Tooltip } from "@mui/material";
import { mentorListAtom } from "@/Atoms/MentorAtom";
import { useAtomValue, useSetAtom } from "jotai";
import { getUserImage } from "@/ViewModels/UserViewModel";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import CancelIcon from "@mui/icons-material/Cancel";
import useIsMobile from "@/Hooks/useIsMobile";
import { dokgabiChatCreateChatRoomAtom } from "@/ViewModels/Chat/CreateRoomViewModel";
import { useTranslation } from "react-i18next";

export const DokgabiChatCreateRoom = () => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const [searchText, setSearchText] = React.useState<string>("");
  const userImage = useAtomValue(getUserImage);
  const mentorList = useAtomValue(mentorListAtom);
  const [selectedMentorList, setSelectedMentorList] = React.useState<number[]>(
    []
  );
  const handleSelectMentor = (mentorId: number) => {
    setSelectedMentorList([...selectedMentorList, mentorId]);
  };
  const createChatRoom = useSetAtom(dokgabiChatCreateChatRoomAtom);

  const handleRemoveMentor = (mentorId: number) => {
    setSelectedMentorList(selectedMentorList.filter((id) => id !== mentorId));
  };

  const filteredMentorList = React.useMemo(() => {
    return mentorList
      .filter((mentor) => selectedMentorList.indexOf(mentor.id) === -1)
      .filter((mentor) =>
        mentor.name.toLowerCase().includes(searchText.toLowerCase())
      );
  }, [mentorList, selectedMentorList, searchText]);

  return (
    <>
      <DokgabiCreateHeader />
      <Box
        flexGrow={1}
        sx={{
          display: "flex",
          width: "calc(100% - 40px)",
          flexDirection: "column",
          // height: "calc(100% - 100px)",
          justifyContent: "space-between",
          gap: "20px",
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
          {t("chat.contents.create.description")}
        </div>
        <Box display="flex" flexDirection="column" width="100%" gap={2}>
          <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
            {t("chat.contents.create.list")}
          </div>
          <TextField
            label={t("chat.contents.create.search")}
            variant="outlined"
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100px",
              maxHeight: isMobile ? "250px" : "400px",
              overflowY: "auto",
              border: "1px solid lightgray",
              padding: "10px",
              gap: "10px",
            }}
          >
            {filteredMentorList.map((mentor, index) => (
              <div key={`mentor-${index}`}>
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  minHeight="80px"
                >
                  {mentor.character_image.file_path && (
                    <img
                      src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${mentor.character_image.file_path}`}
                      alt="mentor"
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                      }}
                      key={`image-${index}`}
                    />
                  )}
                  <div style={{ fontSize: "0.8rem" }} key={`name-${index}`}>
                    {mentor.name}
                  </div>
                  <CustomBackgroundColorButton
                    key={`select-${index}`}
                    onClick={() => handleSelectMentor(mentor.id)}
                    text={t("chat.contents.create.select")}
                    fontSize="0.8rem"
                  />
                </Box>
                {index !== mentorList.length - 1 && (
                  <Divider key={`divider-${index}`} />
                )}
              </div>
            ))}
            {searchText !== "" && (
              <>
                {filteredMentorList.length !== 0 && <Divider />}
                {filteredMentorList.length === 0 && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="80px"
                  >
                    <div style={{ fontSize: "0.8rem" }}>
                      {t("chat.contents.create.noSearch", { searchText })}
                    </div>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="100%">
          <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
            {t("chat.contents.create.participants")}
          </div>
          <Box
            display="flex"
            flexDirection="row"
            maxWidth="100%"
            minHeight="100px"
            sx={{
              overflowX: "auto",
            }}
            alignItems="center"
            gap={2}
          >
            <Tooltip title={<div style={{ fontSize: "1.1rem" }}>나</div>} arrow>
              <img
                src={userImage}
                alt="user"
                style={{
                  display: "block",
                  width: "70px",
                  height: "70px",
                  borderRadius: "50%",
                }}
              />
            </Tooltip>
            {selectedMentorList.map((mentorId, index) => {
              const mentor = mentorList.find(
                (mentor) => mentor.id === mentorId
              );
              return (
                <Box
                  key={index}
                  sx={{ position: "relative", display: "inline-block" }}
                >
                  {mentor?.character_image.file_path && (
                    <Tooltip
                      key={index}
                      title={
                        <div style={{ fontSize: "1.1rem" }}>{mentor?.name}</div>
                      }
                      arrow
                    >
                      <img
                        src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${mentor?.character_image.file_path}`}
                        alt="mentor"
                        style={{
                          display: "block",
                          width: "70px",
                          height: "70px",
                          borderRadius: "50%",
                        }}
                      />
                    </Tooltip>
                  )}
                  <IconButton
                    size="small"
                    sx={{
                      position: "absolute",
                      top: -10,
                      right: -10,
                      color: "black",
                      "&:hover": {
                        color: "gray",
                      },
                      zIndex: 1,
                    }}
                    onClick={() => handleRemoveMentor(mentorId)}
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CustomBackgroundColorButton
            onClick={() => createChatRoom(selectedMentorList, t)}
            text={t("chat.contents.create.create")}
            fontSize="1rem"
          />
        </Box>
      </Box>
    </>
  );
};

export default DokgabiChatCreateRoom;
