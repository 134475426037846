import React, { useEffect } from "react";
import * as S from "./styled";
import PlanContents from "@/Components/Plan/Contents";
import CreateRoutineDialog from "@/Components/Plan/Dialogs/CreateRoutine";
import CreateTaskDialog from "@/Components/Plan/Dialogs/CreateTask";
import EditTaskDialog from "@/Components/Plan/Dialogs/EditTask";
import EditRoutineIntervalsDialog from "@/Components/Plan/Dialogs/EditRoutineIntervals";
import EditDefaultRoutineIntervalsDialog from "@/Components/Plan/Dialogs/EditDefaultRoutineIntervals";
import { usePlans } from "@/Hooks/usePlans";
import { usePlanData } from "@/Hooks/usePlanData";
import EditPlanNameDialog from "@/Components/Plan/Dialogs/EditPlanName";
import PlanHeader from "@/Components/Plan/Header";
import { noticeModalAtom, waitingModalAtom } from "@/Atoms/RootAtom";
import { useAtom, useSetAtom } from "jotai";
import DeletePlanDialog from "@/Components/Plan/Dialogs/DeletePlan";
import DeleteRoutineDialog from "@/Components/Plan/Dialogs/DeleteRoutine";
import { usePlanDataAtom, usePlansAtom } from "@/Atoms/HookControllerAtom";
import { useLocation } from "react-router-dom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import CreateJobMindmapDialog from "@/Components/Plan/Dialogs/CreateJobMindmap";
import InactivateDialog from "@/Components/Plan/Dialogs/Inactivate";
import CreateTaskInGroupDialog from "@/Components/Plan/Dialogs/CreateTaskInGroup";
import PlanSettingsModal from "@/Components/Plan/Dialogs/Settings";
import AlertNoJobDataModal from "@/Components/Plan/Dialogs/AlertNoJobData";
import CreateFeedModal from "@/Components/Plan/Dialogs/Feed/Create";
import EditFeedModal from "@/Components/Plan/Dialogs/Feed/Edit";
import CreateUltimateModal from "@/Components/Plan/Dialogs/CreateUltimate";
import CreateTaskInRoutineModal from "@/Components/Plan/Dialogs/CreateTaskInRoutine";
import EditUltimateModal from "@/Components/Plan/Dialogs/EditUltimate";
import CreateRoutineInRoutine from "@/Components/Plan/Dialogs/CreateRoutineInRoutine";
import { useTranslation } from "react-i18next";
import DeleteRoutineModal from "@/Components/Plan/Dialogs/Routine/DeleteRoutine";
import DeleteTaskModal from "@/Components/Plan/Dialogs/Routine/DeleteTask";
import CreateNewNodeGroupModal from "@/Components/Plan/Dialogs/CreateNewNodeGroup";

const Dialogs = () => {
  return (
    <>
      <CreateRoutineDialog />
      <CreateTaskDialog />
      <CreateTaskInGroupDialog />
      <EditTaskDialog />
      <EditRoutineIntervalsDialog />
      <EditDefaultRoutineIntervalsDialog />
      <EditPlanNameDialog />
      <DeletePlanDialog />
      <DeleteRoutineDialog />
      <CreateJobMindmapDialog />
      <InactivateDialog />
      <PlanSettingsModal />
      <AlertNoJobDataModal />
      <CreateFeedModal />
      <EditFeedModal />
      <CreateUltimateModal />
      <CreateTaskInRoutineModal />
      <EditUltimateModal />
      <CreateRoutineInRoutine />
      <DeleteRoutineModal />
      <DeleteTaskModal />
      <CreateNewNodeGroupModal />
    </>
  );
};

const Plan = () => {
  const { t } = useTranslation();
  const { isLoading: isLoadingPlans } = usePlans();
  const { isLoading: isLoadingPlanData } = usePlanData();
  const setWaitingModal = useSetAtom(waitingModalAtom);
  const setController = useSetAtom(usePlansAtom);
  const setPlanDataController = useSetAtom(usePlanDataAtom);
  const location = useLocation();
  const planId = GetIdFromQuerystring("id");
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const [, setNoticeModal] = useAtom(noticeModalAtom);

  useEffect(() => {
    setWaitingModal({
      state: isLoadingPlans || isLoadingPlanData,
      text: t("dialogs.common.waiting.loading"),
    });
  }, [isLoadingPlanData, isLoadingPlans, setWaitingModal]);

  useEffect(() => {
    setController(true);
    setPlanDataController(true);
  }, [
    location.pathname,
    planId,
    innerTabId,
    setController,
    setPlanDataController,
  ]);

  useEffect(() => {
    if ([800, 804, 827, 844].includes(innerTabId)) {
      const mindMapGuideSkip = localStorage.getItem("mindMapGuideSkip");

      if (!mindMapGuideSkip) {
        setNoticeModal({
          state: true,
          event: () => {
            localStorage.setItem("mindMapGuideSkip", "true");
          },
          eventText: t("plan.mindMapGuide.skip"),
          redirectUrl: "",
          text: t("plan.mindMapGuide.text"),
          title: t("plan.mindMapGuide.title"),
        });
      }
    }
  }, [innerTabId]);

  return (
    <S.Container>
      <PlanHeader />
      <PlanContents />
      <Dialogs />
    </S.Container>
  );
};

export default Plan;
