import React from "react";
import theme from "@/Styles/theme";
import { Button } from "@mui/material";
import { lighten } from "polished";

interface CustomColorButtonProps {
  text: string;
  onClick: () => void;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: string;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  variant?: "text" | "outlined" | "contained";
}

const CustomColorButton = ({
  text,
  onClick,
  backgroundColor = "transparent",
  color = theme.colors.primary,
  fontSize = "1rem",
  fontWeight = "600",
  style = {},
  icon,
  variant = "text",
}: CustomColorButtonProps) => {
  return (
    <Button
      variant={variant}
      sx={{
        backgroundColor,
        color,
        fontSize,
        fontWeight,
        "&:hover": {
          backgroundColor: lighten(0.1, backgroundColor),
          color: lighten(0.1, color),
        },
        ...style,
      }}
      onClick={onClick}
    >
      {text}
      {icon}
    </Button>
  );
};

export default CustomColorButton;
