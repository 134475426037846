import theme from "@/Styles/theme";
import { TaskStatus } from "@/Types/Plan";
import { Node } from "@xyflow/react";
import dayjs, { Dayjs } from "dayjs";
import { atom } from "jotai";

export const routineViewModeAtom = atom<"calendar" | "gantt">("calendar");
export const routineHeaderModeAtom = atom<"normal" | "small">("normal");

export type RoutineDateMode = "Month" | "Week" | "Day";
export const routineDateModeAtom = atom<RoutineDateMode>("Month");
export const routineCurrentDateAtom = atom<Date>(new Date());

export const routineGanttChartLongestLabelWidthAtom = atom<number>(0);

export interface RoutineData {
  key: Node; // key값
  tasks: Node[]; // key와 연결되어있는 task들
  progress: number; // 진행률
  monthProgress: number; // 월별 진행률
  weekProgress: number; // 주별 진행률
  dayProgress: number; // 일별 진행률
}

// routine: 루틴 조회
// task: 할 일 조회
// taskInRoutine: 루틴에 속한 할 일 조회
// template: 루틴 템플릿
// createTask: 할 일 추가
// createRoutine: 루틴 추가
// editTask: 할 일 수정
// editRoutine: 루틴 수정
// addTaskToRoutine: 루틴에 할 일 추가
// addNewTaskToRoutine: 루틴에 새로운 할 일 추가
// calendarTask: 캘린더에서 할 일 조회
// calendarTodayTask: 캘린더에서 오늘 할 일 조회
// ganttEditRoutine: 간트차트에서 루틴 수정
// ganttEditTask: 간트차트에서 할 일 수정
// ganttAddTaskToRoutine: 간트차트에서 루틴에 할 일 추가
export type RoutineSidebarDataTypes =
  | "routine"
  | "task"
  | "template"
  | "createTask"
  | "createRoutine"
  | "editTask"
  | "editRoutine"
  | "addTaskToRoutine"
  | "addNewTaskToRoutine"
  | "taskInRoutine"
  | "calendarTask"
  | "calendarTodayTask"
  | "ganttEditRoutine"
  | "ganttEditTask"
  | "ganttAddTaskToRoutine";
export interface RoutineSidebarData {
  type: RoutineSidebarDataTypes;
  key: Node | null;
  tasks: Node[];
  taskStatus?: TaskStatus; // 날짜에 따른 할 일 상태
  taskStatuses?: TaskStatus[]; // 날짜에 따른 할 일 상태들
}

export interface RoutineSidebarProps extends RoutineSidebarData {
  id: number;
}

export const routineSidebarAtom = atom<RoutineSidebarProps[]>([]);

interface CreateTaskInRoutineStateProps {
  label: string;
  content: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  termType: number | null;
  termData: string[] | null;
  hashtags: {
    backgroundColor: string;
    textColor: string;
    value: string;
  }[];
  color: string;
  times: string[];
  isLoading: boolean;
}

interface EditTaskInRoutineStateProps extends CreateTaskInRoutineStateProps {
  id: string;
  backendId: number;
  originStartDate: Dayjs;
}

export const initializeCreateTaskInRoutineState = () => ({
  label: "",
  content: "",
  startDate: dayjs(),
  endDate: null,
  termType: null,
  termData: null,
  hashtags: [],
  color: theme.colors.primary,
  times: [],
  isLoading: false,
});

export const createTaskInRoutineStateAtom = atom<CreateTaskInRoutineStateProps>(
  initializeCreateTaskInRoutineState()
);
export const editTaskInRoutineStateAtom =
  atom<EditTaskInRoutineStateProps | null>(null);

interface EditRoutineInRoutineStateProps {
  id: string;
  label: string;
  color: string;
  isLoading: boolean;
  content: string;
  backendId: number;
}

export const editRoutineInRoutineStateAtom =
  atom<EditRoutineInRoutineStateProps | null>(null);

export const connectedRoutinesAtom = atom<
  { id: string; backendId: number; label: string; color: string }[]
>([]);

interface CreateTaskInRoutineEditRoutineProps {
  termType: number | null;
  termData: string[] | null;
}

export const createTaskInRoutineEditRoutineAtom =
  atom<CreateTaskInRoutineEditRoutineProps>({
    termType: null,
    termData: null,
  });

export const editTaskInRoutineEditRoutineAtom =
  atom<CreateTaskInRoutineEditRoutineProps>({
    termType: null,
    termData: null,
  });

interface CreateRoutineInRoutineModalProps {
  label: string;
  color: string;
  content: string;
  isLoading: boolean;
}

export const createRoutineInRoutineModalAtom =
  atom<CreateRoutineInRoutineModalProps | null>(null);

export interface TaskOrderProps {
  backendId: number;
  order: number;
  id: string;
}
export const prevTaskOrderAtom = atom<TaskOrderProps[]>([]);
export const tasksInRoutineAtom = atom<Node[]>([]);
export const addTasksInRoutineAtom = atom<Node[]>([]);
export const addTasksInRoutineLoadingAtom = atom<boolean>(false);

// 루틴 삭제 모달 오픈 여부 관리
export const deleteRoutineModalOpenAtom = atom<string | null>(null);

interface DeleteTaskModalProps {
  id: string | null;
  loading: boolean;
}

// 할 일 삭제 모달 오픈 여부 관리
export const deleteTaskModalOpenAtom = atom<DeleteTaskModalProps>({
  id: null,
  loading: false,
});

// 간트차트에서 리스트 확장 여부 관리
export const ganttExpandListAtom = atom<boolean>(true);

// 간트차트에서 루틴 그룹의 extend 여부 관리
export const ganttExpandedRoutineGroupAtom = atom<string[]>([]);

// 간트차트에서 task의 정렬 순서 관리
export interface TaskOrderInGanttChart {
  routineId: number | null;
  type: string;
  innerTabOrderId: number;
  innerTabId: number;
  taskId: number;
  order: number | null;
  subOrder: number | null;
}

export interface InnerTabTaskOrder {
  innerTabId: number;
  order: TaskOrderInGanttChart[];
}
export const orderTasksInGanttChartAtom = atom<InnerTabTaskOrder[]>([]);
