import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { usePlansAtom } from "@/Atoms/HookControllerAtom";
import {
  RecommendPlanItem,
  recommendPlanModalDataAtom,
  recommendPlanModalStateAtom,
} from "@/Atoms/RecommendPlan/Atom";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import snackbarAtom from "@/Atoms/Snackbar";
import {
  getPlanData,
  importPlan,
  parseGetPlanDataResponse,
} from "@/Queries/PlanQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { TFunction } from "i18next";
import { atom } from "jotai";
import { NavigateFunction } from "react-router-dom";

export const recommendPlanModalOpenAtom = atom(
  null,
  async (get, set, item: RecommendPlanItem) => {
    set(recommendPlanModalStateAtom, item);

    // API 호출
    const response = await handleReactQueryApiResponse(
      getPlanData,
      () => set(error401ModalAtom, true),
      item.innerTabId
    );
    const responseJson = await response.json();
    const parsedResponse = parseGetPlanDataResponse(
      responseJson,
      item.innerTabId
    );
    set(recommendPlanModalDataAtom, {
      nodes: parsedResponse.nodes,
      edges: parsedResponse.edges,
    });
  }
);

export const recommendPlanModalCloseAtom = atom(null, (get, set) => {
  set(recommendPlanModalStateAtom, null);
  set(recommendPlanModalDataAtom, null);
});

export const recommendPlanModalImportAtom = atom(
  null,
  async (get, set, navigate: NavigateFunction, t: TFunction) => {
    const modalData = get(recommendPlanModalDataAtom);
    const modalState = get(recommendPlanModalStateAtom);

    if (!modalData || !modalState) {
      return;
    }
    const planName = modalState.planName;
    set(waitingModalAtom, {
      state: true,
      text: t("recommendPlan.modals.planInfo.snackbar.loading", {
        planName: planName,
      }),
    });
    // api 호출
    try {
      const response = await handleReactQueryApiResponse(
        importPlan,
        () => set(error401ModalAtom, true),
        modalState.planId,
        modalState.innerTabId
      );

      // 1. 모달만 닫아주기
      set(recommendPlanModalCloseAtom);
      set(snackbarAtom, {
        open: true,
        message: t("recommendPlan.modals.planInfo.snackbar.success", {
          planName: planName,
        }),
        severity: "success",
      });
      set(usePlansAtom, true);
    } catch (error) {
      console.error(error);
      set(snackbarAtom, {
        open: true,
        message: t("recommendPlan.modals.planInfo.snackbar.fail", {
          planName: planName,
        }),
        severity: "error",
      });
    } finally {
      set(waitingModalAtom, { state: false, text: "" });
    }

    // 2. 이동하는 로직 추가
    // const newPlanId = 0;
    // const newInnerTabId = 0;
    // navigate(`/plan?id=${newPlanId}&inner_tab_id=${newInnerTabId}`);
  }
);
