import { ResponseStatus } from "../Enums/NetworkEnum";
import { setCookie } from "../Utils/CookieUtils";
import axios from "axios";

export async function getUserInfoWhenStart(access: string) {
  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/getUserInfoWhenStart`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    }
  );
  if (response.status === 200) {
    const result = await response.json();
    return result.results;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

export async function getVerifyRefreshToken(refresh: string) {
  const response = await fetch(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/refresh/verify/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${refresh}`,
      },
    }
  );

  if (response.status === 200) {
    const result = await response.json();

    // 쿠키에 액세스 토큰 저장
    setCookie(
      process.env.REACT_APP_USER_ACCESS_COOKIE_ID,
      result.access_token,
      {
        path: "/",
        sameSite: "Lax",
        domain: process.env.REACT_APP_DOMAIN_NAME,
        secure: true,
      }
    );

    return result.access_token;
  } else if (response.status === 401) {
    return ResponseStatus.UNAUTHORIZED;
  } else if (response.status === 403) {
    return ResponseStatus.FORBIDDEN;
  } else {
    return ResponseStatus.ERROR;
  }
}

// 비밀번호 재설정 할 때 쓰임
export const login = async (username: string, password: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/login/`,
    {
      email: username,
      password: password,
      site_name: process.env.REACT_APP_SITE_NAME,
    }
  );
  return response;
};

export const resetPassword = async (email: string, password: string) => {
  const response = await axios.put(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updatePasswordWithoutToken`,
    {
      email: email,
      password: password,
    }
  );
  return response;
};
