import { useQuery } from "react-query";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { getPlanHipCrews, parseCrewInPlanHip } from "@/Queries/PlanHipQueries";
import { usePlanHipAtom } from "@/Atoms/HookControllerAtom";
import planHipAtom from "@/Atoms/PlanHip/Atom";
import { getUserId } from "@/ViewModels/UserViewModel";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { devConsoleError } from "@/Utils/ConsoleLogInDevelopment";

const usePlanHip = () => {
  const [controller, setController] = useAtom(usePlanHipAtom);
  const setPlanHip = useSetAtom(planHipAtom);
  const userId = useAtomValue(getUserId);
  const setError401Modal = useSetAtom(error401ModalAtom);

  return useQuery(
    ["planHipCrews"],
    () =>
      handleReactQueryApiResponse(
        getPlanHipCrews,
        () => setError401Modal(true),
        userId,
        0
      ),
    {
      enabled: controller,
      onSuccess: async (data) => {
        if (!data.ok) return;
        setController(false);
        const clonedResponse = data.clone();
        try {
          const jsonedData = await clonedResponse.json();
          setPlanHip((prev) => ({
            ...prev,
            myCrews: parseCrewInPlanHip(jsonedData.user_active_crews),
            notMyCrews: parseCrewInPlanHip(jsonedData.user_inactive_crews),
          }));
        } catch (error) {
          devConsoleError("Error parsing JSON:", error);
        }
      },
    }
  );
};

export default usePlanHip;
