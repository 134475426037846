import planAtom from '@/Atoms/Plan';
import innerTabDataAtom from '@/Atoms/Plan/InnerTabDataAtom';
import { curriculumNodeAtom, CurriculumNodeState } from '@/Atoms/Plan/MindmapAtom';
import GetIdFromQuerystring from '@/Utils/GetIdFromQuerystring';
import { Node } from '@xyflow/react';
import { atom } from 'jotai';

//  교육프로그램은 5개만 뜨고, 나머지는 확장된 리스트에 표시되어야한다.
// - 예를들어, 교육프로그램이 60개라면, 5개가 표시되고, 나머지 55개는 리스트 형식으로 표시되어야한다.
export const getCurrentCurriculumStateAtom = atom((get) => {
  const innerTabId = GetIdFromQuerystring('inner_tab_id');
  const curriculumData = get(curriculumNodeAtom).find((data) => data.innerTabId === innerTabId);
  return curriculumData || null;
}, (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find((data) => data.innerTabId === innerTabId);
  if (!currentPlanData) {
    return;
  }

  const nodes = currentPlanData.nodes;
  const edges = currentPlanData.edges
  // 교육프로그램그룹 노드만 필터링
  const curriculumGroupNodes = nodes.filter((node) => node.type === 'group-curriculum');

  // 교육프로그램그룹과 연결된 자식 노드들 중 교육프로그램 타입 노드만 필터링
  const curriculumNodes = nodes.filter((node) => node.type === 'curriculum' || node.type === 'youtube-curriculum');

  // 교육프로그램그룹과 연결된 자식 노드들 중 교육프로그램 타입 노드만 필터링
  const groupToCurriculums: Record<string, Node[]> = {};
  curriculumGroupNodes.forEach((groupNode) => {
    const groupToCurriculum = edges.filter((edge) => edge.source === groupNode.id);
    groupToCurriculums[groupNode.id] = groupToCurriculum.map((edge) => {
      return curriculumNodes.find((node) => node.id === edge.target);
    }).filter((node) => node !== undefined) as Node[];
  });

  const curriculumState: CurriculumNodeState[] = []
  const notVisibleNodes: Node[] = []

  // 교육프로그램그룹 노드의 상태를 저장
  curriculumGroupNodes.forEach((groupNode) => {
    const curriculumNodesByGroup = groupToCurriculums[groupNode.id];
    const visibleIndex = curriculumNodesByGroup.length <= 5 ? [0, curriculumGroupNodes.length - 1] : [curriculumNodesByGroup.length / 2 - 2, curriculumNodesByGroup.length / 2 + 2];
    curriculumNodesByGroup.forEach((curriculumNode, index) => {
      // console.log("curriculumNode", curriculumNode);
      curriculumState.push({
        id: curriculumNode.id,
        visible: visibleIndex[0] <= index && index <= visibleIndex[1],
      });
      if (visibleIndex[0] > index || index > visibleIndex[1]) {
        notVisibleNodes.push(curriculumNode);
      }
    });
  });

  set(curriculumNodeAtom, (prev) => {
    const existingIndex = prev.findIndex((item) => item.innerTabId === innerTabId);
    if (existingIndex !== -1) {
      return prev.map((item, index) => (index === existingIndex ? { innerTabId, state: curriculumState, notVisibleNodes } : item));
    } else {
      return [...prev, { innerTabId, state: curriculumState, notVisibleNodes }];
    }
  });
});
