import { Edge, Node } from "@xyflow/react";
import { atom } from "jotai";
import {
  onEdgesChangeAtom,
  onNodesChangeAtom,
  updateNodeAtom,
} from "../FlowViewModel";
import { Dayjs } from "dayjs";
import jobNodeAtom from "@/Atoms/Nodes/JobNodeAtom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { updatePlanDataMindmapAtom } from "../../InnerTabViewModel";
import createJobMindmapDialogAtom from "@/Atoms/Dialogs/CreateJobMindmap";
import {
  createJobMindmap,
  createPlanByJob,
  getRelatedJobs,
  updateTaskStatus,
} from "@/Queries/PlanQueries";
import { waitingModalAtom } from "@/Atoms/RootAtom";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { devConsoleError } from "@/Utils/ConsoleLogInDevelopment";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { isChatbotTypingAtom } from "@/Atoms/ChatAtom";
import snackbarAtom from "@/Atoms/Snackbar";
import { NavigateFunction } from "react-router-dom";

export const recommendJobNodePrevPageAtom = atom(
  null,
  (get, set, node: Node) => {
    const jobNodes = get(jobNodeAtom);
    const jobNode = jobNodes.find((jobNode) => jobNode.id === node.id);
    if (!jobNode) return;
    const currentPage = jobNode.state.currentPage;
    if (currentPage === 0) return;
    set(
      jobNodeAtom,
      jobNodes.map((jobNode) =>
        jobNode.id === node.id
          ? {
              ...jobNode,
              state: { ...jobNode.state, currentPage: currentPage - 1 },
            }
          : jobNode
      )
    );
  }
);

export const recommendJobNodeNextPageAtom = atom(
  null,
  (get, set, node: Node) => {
    const jobNodes = get(jobNodeAtom);
    const jobNode = jobNodes.find((jobNode) => jobNode.id === node.id);
    if (!jobNode) return;
    const currentPage = jobNode.state.currentPage;
    const totalPage = Math.ceil(jobNode.state.jobList.length / 5);
    if (currentPage === totalPage - 1) return;

    set(
      jobNodeAtom,
      jobNodes.map((jobNode) =>
        jobNode.id === node.id
          ? {
              ...jobNode,
              state: { ...jobNode.state, currentPage: currentPage + 1 },
            }
          : jobNode
      )
    );
  }
);

export const recomendJobNodeDataAtom = atom(
  null,
  async (get, set, node: Node) => {
    set(jobNodeAtom, [
      ...get(jobNodeAtom),
      { id: node.id, state: { status: 0, jobList: [], currentPage: 0 } },
    ]);

    setTimeout(async () => {
      set(isChatbotTypingAtom, true);

      try {
        const response = await handleReactQueryApiResponse(
          getRelatedJobs,
          () => set(error401ModalAtom, true),
          node.data.label as string
        );
        if (!response.ok) {
          throw new Error(
            "관련 직업을 가져오는데 실패하였습니다. 잠시 후 다시 시도해주세요."
          );
        }
        const responseJson = await response.json();

        if (responseJson) {
          const newJobStates = responseJson.map(
            (job: { job: string; rank: number; status: boolean }) => ({
              job: job.job,
              rank: job.rank,
              status: job.status || false,
            })
          );

          set(
            jobNodeAtom,
            [...get(jobNodeAtom)].map((jobNode) =>
              jobNode.id === node.id
                ? {
                    ...jobNode,
                    state: { status: 1, jobList: newJobStates, currentPage: 0 },
                  }
                : jobNode
            )
          );
        }
      } catch (error) {
        set(
          jobNodeAtom,
          [...get(jobNodeAtom)].map((jobNode) =>
            jobNode.id === node.id
              ? {
                  ...jobNode,
                  state: { status: 2, jobList: [], currentPage: 0 },
                }
              : jobNode
          )
        );
      }
      set(isChatbotTypingAtom, false);
    }, 1000);
  }
);

export const updateJobNodeDataAtom = atom(
  null,
  (get, set, node: Node, key: any, value: any) => {
    if (key === "label") {
      const jobNodes = get(jobNodeAtom);

      if (jobNodes.find((jobNode) => jobNode.id === node.id)) {
        const filteredJobNodes = jobNodes.filter(
          (jobNode) => jobNode.id !== node.id
        );

        set(jobNodeAtom, filteredJobNodes);
      }
    }
    const updatedNode: any = {
      ...node.data,
      [key]: value,
    };
    set(updateNodeAtom, updatedNode);
  }
);

export const updateJobNodeDateAtom = atom(
  null,
  (get, set, node: Node, date: Dayjs | null, type: "start" | "end") => {
    const updatedNode: any = {
      ...node.data,
      startDate: type === "start" ? date?.toISOString() : node.data.startDate,
      endDate: type === "end" ? date?.toISOString() : node.data.endDate,
    };
    set(updateNodeAtom, updatedNode);
  }
);

export const updateJobNodeLocationAtom = atom(
  null,
  (get, set, node: Node, address: string) => {
    const updatedNode: any = {
      ...node.data,
      location: {
        ...(node.data.location || {}),
        address: address,
      },
    };
    set(updateNodeAtom, updatedNode);
  }
);

export const updateJobNodeRoutineOptionAtom = atom(
  null,
  (get, set, node: Node, key: number) => {
    let updatedNode;
    if (node.data.termType === key) {
      updatedNode = {
        ...node.data,
        termType: null,
        termData: null,
      };
    } else {
      updatedNode = {
        ...node.data,
        termType: key,
        termData: [] as string[],
      };
    }
    set(updateNodeAtom, updatedNode);
  }
);

export const createJobDialogOpenAtom = atom(
  null,
  (get, set, node: any, job: string) => {
    const connectedEdge = get(onEdgesChangeAtom).find(
      (edge) => edge.source === node.id
    );
    if (connectedEdge) {
      set(createJobMindmapDialogAtom, {
        open: true,
        node: node,
        job: job,
      });
      return;
    }
    set(createJobNodeDataAtom, node, job);
  }
);

// 추천된 직업 클릭 시 플랜을 새로 만들 경우 사용
export const createJobNodeDataWithNewPlanAtom = atom(
  null,
  async (get, set, job: string, navigate: NavigateFunction) => {
    // 새로운 플랜 생성
    try {
      set(waitingModalAtom, {
        state: true,
        text: "플랜 생성 중 입니다. 잠시만 기다려주세요.",
      });
      const response = await handleReactQueryApiResponse(
        createPlanByJob,
        () => set(error401ModalAtom, true),
        job
      );
      if (!response) {
        throw new Error(
          "플랜 생성에 실패하였습니다. 잠시 후 다시 시도해주세요."
        );
      }
      const { plan_id: planId, inner_tab_id: innerTabId } =
        await response.json();
      const newPath = `/plan?id=${planId}&inner_tab_id=${innerTabId}`;
      navigate(newPath);

      // 성공 메시지 및 다이얼로그 닫기
      set(createJobMindmapDialogAtom, null);
      set(snackbarAtom, (prev) => ({
        ...prev,
        open: true,
        message: `${job}에 대한 플랜이 생성되었습니다.`,
      }));
    } catch (error) {
      devConsoleError("createJobNodeDataWithNewPlanAtom", error);
      set(snackbarAtom, (prev) => ({
        ...prev,
        open: true,
        message: "플랜 생성에 실패하였습니다. 잠시 후 다시 시도해주세요.",
      }));
    }
    set(waitingModalAtom, { state: false, text: "" });
  }
);

// 추천된 직업 클릭 시 해당 직업에 대한 데이터 표시
export const createJobNodeDataAtom = atom(
  null,
  async (get, set, node: any, job: string) => {
    try {
      set(waitingModalAtom, {
        state: true,
        text: "마인드맵 생성 중 입니다. 잠시만 기다려주세요.",
      });
      const innerTabId = GetIdFromQuerystring("inner_tab_id");
      const response = await handleReactQueryApiResponse(
        createJobMindmap,
        () => set(error401ModalAtom, true),
        innerTabId,
        job
      );
      if (!response) {
        throw new Error(
          "마인드맵 생성에 실패하였습니다. 잠시 후 다시 시도해주세요."
        );
      }
      const { nodes, edges } = await response.json();

      // updatePlanDataMindmapAtom의 작업 완료를 기다림
      await set(updatePlanDataMindmapAtom, innerTabId, nodes, edges);

      // 성공 메시지 및 다이얼로그 닫기
      set(createJobMindmapDialogAtom, null);
      set(snackbarAtom, (prev) => ({
        ...prev,
        open: true,
        message: `${job}에 대한 마인드맵이 생성되었습니다.`,
      }));
    } catch (error) {
      set(snackbarAtom, (prev) => ({
        ...prev,
        open: true,
        message: "마인드맵 생성에 실패하였습니다. 잠시 후 다시 시도해주세요.",
      }));
    }
    set(waitingModalAtom, { state: false, text: "" });
  }
);

// 해시태그 추가
export const addHashtagNodeAtom = atom(
  null,
  (
    get,
    set,
    node: Node,
    hashtag: { backgroundColor: string; textColor: string; value: string }
  ) => {
    const nodeHashtags =
      (node.data.hashtags as {
        backgroundColor: string;
        textColor: string;
        value: string;
      }[]) || [];
    const updatedNode: any = {
      ...node.data,
      hashtags: [...nodeHashtags, hashtag],
    };
    set(updateNodeAtom, updatedNode);
  }
);

// 해시태그 삭제(pop)
export const removeHashtagNodeAtom = atom(null, (get, set, node: Node) => {
  const nodeHashtags =
    (node.data.hashtags as {
      backgroundColor: string;
      textColor: string;
      value: string;
    }[]) || [];
  const updatedNode: any = {
    ...node.data,
    hashtags: nodeHashtags.slice(0, -1),
  };
  set(updateNodeAtom, updatedNode);
});

// taskStatus 변경
export const updateTaskStatusNodeAtom = atom(
  null,
  async (get, set, nodeId: string, taskStatusId: number, status: number) => {
    const node = get(onNodesChangeAtom).find((n) => n.id === nodeId);
    if (!node) return;
    const response = await handleReactQueryApiResponse(
      updateTaskStatus,
      () => set(error401ModalAtom, true),
      taskStatusId,
      status
    );
    if (response.ok) {
      const updatedNode: any = {
        ...node.data,
        taskStatus: (
          node.data.taskStatus as {
            taskStatusId: number;
            taskId: number;
            date: string;
            status: number;
          }[]
        ).map((task) =>
          task.taskStatusId === taskStatusId
            ? { ...task, status: status }
            : task
        ),
      };
      set(updateNodeAtom, updatedNode);
    }
  }
);
