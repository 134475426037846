import { useQuery } from "react-query";
import { useAtom, useSetAtom } from "jotai";
import { useCrewAtom } from "@/Atoms/HookControllerAtom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { useNavigate } from "react-router-dom";
import { getCrew, parseCrew } from "@/Queries/CrewQueries";
import crewAtom from "@/Atoms/CrewAtom";
import { devConsoleError } from "@/Utils/ConsoleLogInDevelopment";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { Node } from "@xyflow/react";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { setInnerTabDataAtom } from "@/ViewModels/Plan/InnerTabViewModel";

const useCrew = () => {
  const crewId = GetIdFromQuerystring("crew_id");
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const [controller, setController] = useAtom(useCrewAtom);
  const navigate = useNavigate();
  const setCrew = useSetAtom(crewAtom);
  const setInnerTabData = useSetAtom(setInnerTabDataAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);

  return useQuery(
    ["crews"],
    () =>
      handleReactQueryApiResponse(
        getCrew,
        () => setError401Modal(true),
        crewId
      ),
    {
      enabled: controller,
      onSuccess: async (data) => {
        if (!data) return;
        if (data.status !== 200) {
          navigate("/plan-hip");
          return;
        }
        const jsonedData = await data.json();
        const crewData = parseCrew(jsonedData);
        const nodes = jsonedData.nodes_and_edges.nodes.map((node: Node) => ({
          ...node,
          data: {
            ...node.data,
            comments: node.data.comments ? node.data.comments : [],
          },
        }));
        const edges = jsonedData.nodes_and_edges.edges;
        setController(false);
        setCrew(crewData);
        setInnerTabData({ innerTabId, nodes, edges });
      },
      onError: (error) => {
        devConsoleError(error);
        navigate("/plan-hip");
      },
    }
  );
};

export default useCrew;
