import { createNewNodeGroupModalAtom } from '@/Atoms/Plan/MindmapAtom';
import CustomBackgroundColorButton from '@/Components/Common/Buttons/CustomBackgroundColor';
import CustomColorButton from '@/Components/Common/Buttons/CustomColor';
import { createNewNodeGroupAtom, isExistNodeTypeGroupAtom } from '@/ViewModels/Plan/Mindmap/Modals/CreateNewNodeGroupViewModel';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CreateNewNodeGroupModal = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useAtom(createNewNodeGroupModalAtom);
  const isExistNodeTypeGroup = useAtomValue(isExistNodeTypeGroupAtom);
  const nodeType = t(`plan.contents.mindmap.nodes.job.typeLabel.${modal?.node.type || "dream"}`);
  const create = useSetAtom(createNewNodeGroupAtom);

  return (
    modal &&
    <Dialog
      open={!!modal}
      onClose={() => setModal(null)}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>{t("plan.modals.createNewNodeGroup.title", { nodeType })}</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem", display: "flex", flexDirection: "column", gap: "10px" }}>
        <div>
          {t("plan.modals.createNewNodeGroup.description1", { nodeType })}
        </div>
        {
          isExistNodeTypeGroup &&
          <div style={{ fontWeight: "bold" }}>
            {t("plan.modals.createNewNodeGroup.description2", { nodeType })}
          </div>
        }
        <div>
          {t("plan.modals.createNewNodeGroup.description3", { nodeType })}
        </div>
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={() => setModal(null)} color="gray" text={t("plan.modals.createNewNodeGroup.cancel")} fontSize='0.8rem' />
        <CustomBackgroundColorButton onClick={() => create(t)} text={t("plan.modals.createNewNodeGroup.create")} fontSize='0.8rem' disabled={modal.isLoading} />
      </DialogActions>
    </Dialog>
  )
}

export default CreateNewNodeGroupModal;
