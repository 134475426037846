import React, { useEffect } from "react";
import * as S from "./styled";
import {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  Node,
  ReactFlow,
  ReactFlowInstance,
  useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { TemplateNode } from "./Nodes/Template";
import TaskNode from "./Nodes/Task";
import ElementBar from "./ElementBar";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { TextField } from "@mui/material";
import Group from "./Group";
import {
  backgroundClickAtom,
  edgeClickAtom,
  nodeClickAtom,
  onConnectAtom,
  onDragOverAtom,
  onDropAtom,
  onEdgesChangeAtom,
  onNodeDragAtom,
  onNodeDragStopAtom,
  onNodesChangeAtom,
} from "@/ViewModels/Plan/Mindmap/FlowViewModel";
import {
  checkPlanUserIdAtom,
  updatePlanNameAtom,
} from "@/ViewModels/Plan/PlanViewModel";
import { currentPlanAtom, planFitViewUpdatedAtom } from "@/Atoms/Plan";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import JobNode from "./Nodes/Job";
import ImagePrintButton from "./ElementBar/downloadButton";
import useIsMobile from "@/Hooks/useIsMobile";
import CustomEdge from "./Edges";
import { currentZoomAtom } from "@/Atoms/Plan/MindmapAtom";
import { openDokgabiChatModalAtom } from "@/ViewModels/Chat/ModalViewModel";
import MajorNode from "./Nodes/Major";
import CurriculumNode from "./Nodes/Curriculum";
import { useTranslation } from "react-i18next";
import DreamNode from "./Nodes/Dream";
import GoalNode from "./Nodes/Goal";
import UltimateNode from "./Nodes/Ultimate";
import TypeGroupNode from "./Nodes/TypeGroup";
import { getCurrentCurriculumStateAtom } from "@/ViewModels/Plan/Mindmap/Nodes/CurriculumViewModel";
import YoutubeCurriculumNode from "./Nodes/YoutubeCurriculum";
import YoutubeTaskNode from "./Nodes/YoutubeTask";

const baseNodeTypes: Record<string, any> = {
  template: TemplateNode,
  default: TaskNode,
  dream: DreamNode,
  task: TaskNode,
  goal: GoalNode,
  job: JobNode,
  ultimate: UltimateNode,
  curriculum: CurriculumNode,
  major: MajorNode,
  group: Group,
  subject: DreamNode,
  "youtube-task": YoutubeTaskNode,
};

// Proxy를 사용하여 "group-***" 패턴을 Group으로 매핑
const nodeTypes: Record<string, any> = new Proxy(baseNodeTypes, {
  get(target, prop: string) {
    if (prop.startsWith("group-")) {
      return TypeGroupNode; // "group-***" 패턴의 모든 노드는 Group으로 매핑
    }
    if (prop.startsWith("youtube")) {
      return YoutubeCurriculumNode;
    }

    return target[prop]; // 기본 노드 타입 유지
  },
});

const edgeTypes = {
  custom: CustomEdge,
};

const Mindmap = () => {
  const { t } = useTranslation();
  const [nodes, onNodesChange] = useAtom(onNodesChangeAtom);
  const [edges, onEdgesChange] = useAtom(onEdgesChangeAtom);
  const [, onConnect] = useAtom(onConnectAtom);
  const [, onDragOver] = useAtom(onDragOverAtom);
  const [, onDrop] = useAtom(onDropAtom);
  const { fitView, screenToFlowPosition, setCenter, getZoom } = useReactFlow();
  const plan = useAtomValue(currentPlanAtom);
  const [editName, setEditName] = React.useState<string | null>(null);
  const updatePlanName = useSetAtom(updatePlanNameAtom);
  const nodeClick = useSetAtom(nodeClickAtom);
  const edgeClick = useSetAtom(edgeClickAtom);
  const backgroundClick = useSetAtom(backgroundClickAtom);
  const [fitViewUpdated, setFitViewUpdated] = useAtom(planFitViewUpdatedAtom);
  const onNodeDrag = useSetAtom(onNodeDragAtom);
  const onNodeDragStop = useSetAtom(onNodeDragStopAtom);
  const { isMobile } = useIsMobile();
  const isMine = useAtomValue(checkPlanUserIdAtom);
  const setCurrentZoom = useSetAtom(currentZoomAtom);
  const isSelf = GetIdFromQuerystring("is_self") === 2;
  const setDokgabiChatOpen = useSetAtom(openDokgabiChatModalAtom);
  const setCurriculumState = useSetAtom(getCurrentCurriculumStateAtom);

  const handleMove = (event: any) => {
    const zoom = getZoom();
    setCurrentZoom(zoom);
  };

  useEffect(() => {
    // Nodes가 렌더링될 때 `job` 노드를 중심으로 화면 이동
    if (nodes.length > 0 && !fitViewUpdated) {
      const jobNode = nodes.find((node) => node.type === "job");
      if (jobNode) {
        // `job` 노드 중심으로 이동
        const centerPosition = {
          x:
            jobNode.position.x +
            (jobNode.measured?.width ? jobNode.measured.width / 2 : 0),
          y:
            jobNode.position.y +
            (jobNode.measured?.height ? jobNode.measured.height / 2 : 0),
        };
        setCenter(centerPosition.x, centerPosition.y, { zoom: 0.4 }); // 필요에 따라 zoom 값 조정
      } else {
        // 노드가 존재하면 기본 fitView 호출
        fitView({ padding: 0.2 });
      }
      setFitViewUpdated(true);
    }
  }, [nodes, fitViewUpdated, fitView, setCenter, setFitViewUpdated]);

  useEffect(() => {
    if (isSelf) {
      setDokgabiChatOpen();
    }
  }, [isSelf, setDokgabiChatOpen]);

  useEffect(() => {
    setCurriculumState();
  }, [nodes, edges, setCurriculumState]);

  return (
    <S.Container>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={isMine ? onNodesChange : undefined}
        onEdgesChange={isMine ? onEdgesChange : undefined}
        onConnect={isMine ? onConnect : undefined}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeClick={nodeClick}
        onEdgeClick={edgeClick}
        onDrop={(event) => onDrop(event, screenToFlowPosition, t)}
        onDragOver={onDragOver}
        // onNodeDrag={onNodeDrag}
        // onNodeDragStop={onNodeDragStop}
        deleteKeyCode={["Backspace", "Delete"]}
        onPaneClick={backgroundClick}
        minZoom={0.2}
        maxZoom={2}
        onMove={handleMove}
      >
        <Background
          color="#d8d8d8"
          variant={BackgroundVariant.Lines}
          gap={200}
          style={{ background: "#ececec" }}
        />
        <MiniMap position="top-right" />
        <Controls />
      </ReactFlow>
      {/* {!isMobile && isMine && <ElementBar type="delete" />} */}
      {isMine && <ElementBar type="speedDial" />}
      {/* <ElementBar type="careerPath" /> */}
      {isMine && <ElementBar type="group" />}
      {/* <ElementBar type="test" /> */}
      {!isMobile && (
        // 커리어패스가 포함된다면 left: 240px로 수정해야함
        <div
          style={{
            position: "absolute",
            top: "20px",
            left: isMine ? "168px" : "20px",
            padding: "0px 15px",
            height: "56px",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            border: "1px solid #d9d9d9",
            borderRadius: "10px",
            zIndex: 10,
            gap: "5px",
            fontWeight: "bold",
          }}
        >
          {editName !== null ? (
            <>
              <TextField
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
              />
              <CheckOutlinedIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  updatePlanName(editName);
                  if (editName.length > 0) {
                    setEditName(null);
                  }
                }}
              />
            </>
          ) : (
            <>
              {plan?.name || "새 플랜"}
              {isMine && (
                <EditOutlinedIcon
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  onClick={() => setEditName(plan?.name || "")}
                />
              )}
            </>
          )}
        </div>
      )}
      {/* <ImagePrintButton /> */}
    </S.Container>
  );
};

export default Mindmap;
