import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./styled.css";
import { Box, CircularProgress, Divider, Stack } from "@mui/material";
import * as S from "../styled";
import { useAtomValue, useSetAtom } from "jotai";
import { dashboardMenteeGetDailyPlanByYearAndMonthAtom } from "@/ViewModels/Dashboard/Mentee/ViewModel";
import dailyPlanDialogAtom from "@/Atoms/Dialogs/DailyPlan";
import { Node } from "@xyflow/react";
import { dashboardMenteeDailyPlanAtom } from "@/Atoms/Dashboard/MenteeAtom";
import { useTranslation } from "react-i18next";

const Calendar: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const isLoading = useAtomValue(dashboardMenteeDailyPlanAtom).isLoading;
  const dailyPlanData = useAtomValue(
    dashboardMenteeGetDailyPlanByYearAndMonthAtom
  );
  const events = dailyPlanData(currentYear, currentMonth);
  const setDailyPlanDialog = useSetAtom(dailyPlanDialogAtom);
  const { t } = useTranslation();

  return (
    <S.CustomBox sx={{ gap: "10px", height: "100%" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <S.TitleTypo>{t("dashboard.mentee.calander.title")}</S.TitleTypo>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            height: "40px",
          }}
        >
          {isLoading && <CircularProgress />}
        </div>
      </Stack>
      <Divider />
      <S.DescTypo>{t("dashboard.mentee.calander.description")}</S.DescTypo>
      <Box sx={{ height: "100%", overflowY: "auto" }}>
        <FullCalendar
          initialView="dayGridMonth"
          plugins={[dayGridPlugin]}
          events={events}
          eventClick={(info) =>
            setDailyPlanDialog(info.event.extendedProps as Node)
          }
          dayMaxEvents={true}
          dayMaxEventRows={1}
        />
      </Box>
    </S.CustomBox>
  );
};

export default Calendar;
