import React, { useEffect, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import * as S from "./styled";
import Calendar from "./Calendar";
import { AbilityCheck } from "./AbilityCheck";
import { CareerSearch } from "./CareerSearch";
import { RecommendJob } from "./RecommendJob";
import { RecommendContents } from "./RecommendContents";
import { useAtomValue } from "jotai";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import { useTranslation } from "react-i18next";
import { getUserNickname } from "@/ViewModels/UserViewModel";
import { useNavigate } from "react-router-dom";
import PlanTemplates from "./PlanTemplates";

const MenteeDashboard = () => {
  const nickname = useAtomValue(getUserNickname);
  const { t } = useTranslation();

  const calendarRef = useRef<HTMLDivElement | null>(null);
  const [calendarHeight, setCalendarHeight] = useState<number | null>(null);

  useEffect(() => {
    // Calendar의 높이를 기준으로 RecommendContents의 높이를 설정
    if (calendarRef.current) {
      setCalendarHeight(calendarRef.current.offsetHeight);
    }

    // 창 크기 변경 시 높이를 다시 측정
    const handleResize = () => {
      if (calendarRef.current) {
        setCalendarHeight(calendarRef.current.offsetHeight);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <S.TitleHeader>
        <S.WelcomeMessageTypo>
          {t("dashboard.mentee.header.greeting", { nickname: nickname })}
        </S.WelcomeMessageTypo>
        <S.WelcomeMessageTypo>
          {t("dashboard.mentee.header.sentence")}
        </S.WelcomeMessageTypo>
      </S.TitleHeader>

      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <PlanTemplates />
        </Grid>
        {/* 현재 나의 수준은? */}
        {/* <Grid item xs={12} sm={12} md={12} lg={6} sx={{ height: "70%" }}>
          <AbilityCheck />
        </Grid> */}
        {/* 진로 진학 탐색 */}
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ height: "70%" }}>
          <CareerSearch />
        </Grid>
        {/* 추천 직업 */}
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ height: "70%" }}>
          <RecommendJob />
        </Grid>
        {/* 콘텐츠 추천 */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          sx={{ height: calendarHeight ? `${calendarHeight}px` : "70%" }}
        >
          <RecommendContents />
        </Grid>
        {/* 일정표 */}
        <Grid item xs={12} sm={12} md={12} lg={6} ref={calendarRef}>
          <Calendar />
        </Grid>
        {/* 인기 플랜 */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <PopularPlan /> */}
        </Grid>
      </Grid>
    </>
  );
};

export default MenteeDashboard;
