import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import {
  connectedRoutinesAtom,
  ganttExpandedRoutineGroupAtom,
  routineCurrentDateAtom,
  RoutineData,
  routineSidebarAtom,
} from "@/Atoms/Plan/RoutineAtom";
import theme from "@/Styles/theme";
import { TaskStatus } from "@/Types/Plan";
import convertNodesToGanttTasks from "@/Utils/ConvertNodesToGanttTasks";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { Node } from "@xyflow/react";
import dayjs from "dayjs";
import { atom } from "jotai";
import { ganttChartOrderTasksAtom } from "./GanttChartViewModel";

// 간트차트 데이터 가져오기
export const getGanttChartDataAtom = atom((get) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );
  if (!currentPlanData) return [];
  const expandGroupList = get(ganttExpandedRoutineGroupAtom);
  const taskOrder = get(ganttChartOrderTasksAtom);

  const tasks = convertNodesToGanttTasks(
    currentPlanData.nodes,
    currentPlanData.edges,
    expandGroupList,
    taskOrder.order
  );
  return tasks;
});

export const getRoutineListInRoutineAtom = atom((get): RoutineData[] => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );
  if (!currentPlanData) return [];
  const currentRoutineDate = get(routineCurrentDateAtom);

  const { nodes, edges } = currentPlanData;
  const routineList = nodes.filter((node) => node.type === "routine");
  const routineData = routineList.map((routine) => {
    const tasks = edges
      .filter((edge) => edge.source === routine.id)
      .map((edge) => nodes.find((node) => node.id === edge.target))
      .filter((node): node is Node => node !== undefined);
    const keyRoutine = {
      ...routine,
      data: {
        ...routine.data,
        color: (routine.data.color as string) || theme.colors.primary,
      },
    };

    // 진행률 계산
    const taskStatuses = tasks
      .map((task) => task.data.taskStatus as TaskStatus) // 각 task의 taskStatus 배열을 가져옴
      .flat() // 중첩된 배열을 평탄화
      .filter((status) => status.routineId === status.taskId); // taskId와 routineId가 같은 경우만 필터링
    const totalTaskCount = taskStatuses.length;
    const completedTaskCount = taskStatuses.filter(
      (status) => status.status === 1
    ).length;

    // 월별 진행률 계산
    const monthTaskStatuses = taskStatuses.filter(
      (status) => dayjs(status.date).month() === currentRoutineDate.getMonth()
    );
    const monthTotalTaskCount = monthTaskStatuses.length;
    const monthCompletedTaskCount = monthTaskStatuses.filter(
      (status) => status.status === 1
    ).length;

    // 주별 진행률 계산 (주의 시작을 월요일로 설정)
    const weekTaskStatuses = taskStatuses.filter(
      (status) => dayjs(status.date).isSame(currentRoutineDate, "isoWeek") // ISO 주를 기준으로 비교
    );
    const weekTotalTaskCount = weekTaskStatuses.length;
    const weekCompletedTaskCount = weekTaskStatuses.filter(
      (status) => status.status === 1
    ).length;

    // routineDateMode가 "day"인 경우 현재 날짜의 데이터만 필터링
    const dayTaskStatuses = taskStatuses.filter((status) =>
      dayjs(status.date).isSame(currentRoutineDate, "day")
    );
    const dayTotalTaskCount = dayTaskStatuses.length;
    const dayCompletedTaskCount = dayTaskStatuses.filter(
      (status) => status.status === 1
    ).length;

    return {
      key: keyRoutine,
      tasks,
      progress:
        totalTaskCount === 0
          ? 0
          : Math.round((completedTaskCount / totalTaskCount) * 100),
      monthProgress:
        monthTotalTaskCount === 0
          ? 0
          : Math.round((monthCompletedTaskCount / monthTotalTaskCount) * 100),
      weekProgress:
        weekTotalTaskCount === 0
          ? 0
          : Math.round((weekCompletedTaskCount / weekTotalTaskCount) * 100),
      dayProgress:
        dayTotalTaskCount === 0
          ? 0
          : Math.round((dayCompletedTaskCount / dayTotalTaskCount) * 100),
    };
  });
  return routineData;
});

export const getConnectedRoutinesForTaskInRoutineAtom = atom((get): Node[] => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );
  if (!currentPlanData) return [];
  const sidebar = get(routineSidebarAtom);
  if (sidebar.length === 0) return [];
  const data = sidebar[sidebar.length - 1];
  // if (data.type !== "taskInRoutine" && data.type !== "task") return [];
  const dataTypes = [
    "taskInRoutine",
    "task",
    "calendarTask",
    "calendarTodayTask",
  ];
  if (!dataTypes.includes(data.type)) return [];
  const { nodes, edges } = currentPlanData;

  const keyData = data.key;
  if (!keyData) return [];

  const routines = nodes.filter((node) => node.type === "routine");

  // keyData와 연결된 routine 찾기
  const targetEdges = edges.filter((edge) => edge.target === keyData.id);
  if (targetEdges.length === 0) return [];
  const targetRoutine = targetEdges
    .map((edge) => routines.find((routine) => routine.id === edge.source))
    .filter((routine): routine is Node => routine !== undefined);
  return targetRoutine;
});

interface GetRoutineInAutocompleteAtomProps {
  id: string;
  backendId: number;
  label: string;
  isCreate: boolean;
  color: string;
}

export const getRoutinesInAutocompleteAtom = atom(
  (get): GetRoutineInAutocompleteAtomProps[] => {
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const currentPlanData = planData.find(
      (data) => data.innerTabId === innerTabId
    );
    if (!currentPlanData) return [];
    const connectedRoutines = get(connectedRoutinesAtom);

    const { nodes } = currentPlanData;
    const routineList = nodes.filter((node) => node.type === "routine");

    const routineData = routineList.map((routine) => {
      return {
        id: routine.id,
        backendId: routine.data.backendId as number,
        label: routine.data.label as string,
        isCreate: false,
        color: (routine.data.color as string) || theme.colors.primary,
      };
    });

    // 연결된 루틴에서 같은 id를 가진 루틴이 있는지 확인 후 제거
    const connectedRoutineIds = connectedRoutines.map((routine) => routine.id);
    const filteredRoutineData = routineData.filter(
      (routine) => !connectedRoutineIds.includes(routine.id)
    );

    return filteredRoutineData;
  }
);

export const setConnectedRoutinesForTaskInRoutineAtom = atom(
  null,
  (get, set, task: Node) => {}
);

interface CalendarEventProps {
  title: string;
  date: string;
  extendedProps: { colors: string[]; task: Node; date: string };
  start?: string;
  end?: string;
  color?: string;
  allDay?: boolean;
}

// 일정관리에 필요한 데이터 가져오기
export const getMonthlyCalendarDataAtom = atom((get) => {
  const currentDate = get(routineCurrentDateAtom);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const routines = get(getRoutineListInRoutineAtom);
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );
  const sidebar = get(routineSidebarAtom);
  if (!currentPlanData) return [];
  if (sidebar.length > 0 && sidebar[sidebar.length - 1].type === "routine") {
    const selectedRoutineData = sidebar[sidebar.length - 1];
    const selectedRoutineTasks = selectedRoutineData.tasks;

    const events: CalendarEventProps[] = [];
    selectedRoutineTasks.forEach((task: Node) => {
      const taskStatus = task.data.taskStatus as {
        taskStatusId: number;
        taskId: number;
        date: string;
        status: number;
      }[];

      if (taskStatus && taskStatus.length > 0) {
        taskStatus.forEach((status) => {
          events.push({
            title: task.data.label as string,
            date: status.date,
            extendedProps: {
              colors: task.data.color
                ? [task.data.color as string]
                : [theme.colors.primary],
              task,
              date: status.date,
            },
            color: "transparent",
          });
        });
      } else if (task.data.startDate && task.data.endDate) {
        const startDate = dayjs(task.data.startDate as string);
        const endDate = dayjs(task.data.endDate as string).add(1, "day");

        events.push({
          title: task.data.label as string,
          date: startDate.format("YYYY-MM-DD"),
          extendedProps: {
            colors: task.data.color
              ? [task.data.color as string]
              : [theme.colors.primary],
            task,
            date: startDate.format("YYYY-MM-DD"),
          },
          start: startDate.format("YYYY-MM-DD"),
          end: endDate.format("YYYY-MM-DD"),
          color: theme.colors.primary + 80,
        });
      } else if (task.data.startDate) {
        events.push({
          title: task.data.label as string,
          date: task.data.startDate as string,
          extendedProps: {
            colors: task.data.color
              ? [task.data.color as string]
              : [theme.colors.primary],
            task,
            date: task.data.startDate as string,
          },
          color: "transparent",
        });
      }
    });
    return events.filter((event) => {
      const eventDate = dayjs(event.date);
      return eventDate.year() === year && eventDate.month() === month - 1;
    });
  }

  const events: CalendarEventProps[] = [];
  currentPlanData.nodes.forEach((task) => {
    const isInRoutine = routines.some((routine) =>
      routine.tasks.includes(task)
    );
    const colors = isInRoutine
      ? routines
          .filter((routine) => routine.tasks.includes(task))
          .map((routine) => routine.key.data.color as string)
      : task.data.color
      ? [task.data.color as string]
      : [theme.colors.primary];
    const taskStatus = task.data.taskStatus as {
      taskStatusId: number;
      taskId: number;
      date: string;
      status: number;
    }[];

    if (taskStatus && taskStatus.length > 0) {
      taskStatus.forEach((status) => {
        events.push({
          title: task.data.label as string,
          date: status.date,
          extendedProps: {
            colors: colors,
            task,
            date: status.date,
          },
          color: "transparent",
        });
      });
    } else if (task.data.startDate && task.data.endDate) {
      const startDate = dayjs(task.data.startDate as string);
      const endDate = dayjs(task.data.endDate as string).add(1, "day");

      events.push({
        title: task.data.label as string,
        date: startDate.format("YYYY-MM-DD"),
        extendedProps: {
          colors: colors,
          task,
          date: startDate.format("YYYY-MM-DD"),
        },
        start: startDate.format("YYYY-MM-DD"),
        end: endDate.format("YYYY-MM-DD"),
        color: theme.colors.primary + 80,
      });
    } else if (task.data.startDate) {
      const times = task.data.times as number[];
      if (times && times.length > 0) {
        times.forEach((time) => {
          const date = dayjs(task.data.startDate as string)
            .add(time, "hour")
            .format("YYYY-MM-DD HH:mm");

          events.push({
            title: task.data.label as string,
            date,
            extendedProps: {
              colors: colors,
              task,
              date,
            },
            color: "transparent",
          });
        });
      } else {
        events.push({
          title: task.data.label as string,
          date: task.data.startDate as string,
          extendedProps: {
            colors: colors,
            task,
            date: task.data.startDate as string,
          },
          color: "transparent",
        });
      }
    }
  });

  // 현재 월의 데이터 필터링
  return events.filter((event) => {
    const eventDate = dayjs(event.date);
    return eventDate.year() === year && eventDate.month() === month - 1;
  });
});

// 일정관리에 필요한 데이터 가져오기
export const getDailyCalendarDataAtom = atom((get) => {
  const currentDate = get(routineCurrentDateAtom);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const routines = get(getRoutineListInRoutineAtom);
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );
  const sidebar = get(routineSidebarAtom);

  if (!currentPlanData) return [];

  const events: CalendarEventProps[] = [];

  // 특정 루틴이 선택된 경우 해당 루틴의 태스크만 가져옴
  if (sidebar.length > 0 && sidebar[sidebar.length - 1].type === "routine") {
    const selectedRoutineData = sidebar[sidebar.length - 1];
    const selectedRoutineTasks = selectedRoutineData.tasks;
    const selectedRoutine = routines.find(
      (routine) => routine.key.id === selectedRoutineData.key?.id
    );
    if (!selectedRoutine) return [];
    selectedRoutineTasks.forEach((task) => {
      addTaskEvents(task, events, [selectedRoutine]);
    });

    return filterCurrentMonthEvents(events, year, month);
  }

  currentPlanData.nodes.forEach((task) => {
    addTaskEvents(task, events, routines);
  });

  // 현재 월의 데이터 필터링
  return filterCurrentMonthEvents(events, year, month);
});

// 태스크 데이터를 이벤트로 변환하여 추가
const addTaskEvents = (
  task: Node,
  events: CalendarEventProps[],
  routines: RoutineData[],
  color = theme.colors.primary
) => {
  const isInRoutine = routines.some((routine) => routine.tasks.includes(task));
  const colors = isInRoutine
    ? routines
        .filter((routine) => routine.tasks.includes(task))
        .map((routine) => routine.key.data.color as string)
    : task.data.color
    ? [task.data.color as string]
    : [theme.colors.primary];
  const taskStatus = task.data.taskStatus as {
    taskStatusId: number;
    taskId: number;
    date: string;
    status: number;
  }[];

  if (taskStatus && taskStatus.length > 0) {
    taskStatus.forEach((status) => {
      const times = task.data.times as number[];

      if (times && times.length > 0) {
        const sortedTimes = [...times].map(Number).sort((a, b) => a - b); // 정렬 및 숫자로 변환
        let startTime = sortedTimes[0];
        let prevTime = sortedTimes[0];

        for (let i = 1; i <= sortedTimes.length; i++) {
          const currentTime = sortedTimes[i];

          // 연속되지 않으면 구간 종료 또는 배열 끝일 때
          if (currentTime !== prevTime + 1 || i === sortedTimes.length) {
            const startDate = dayjs(status.date as string)
              .add(startTime, "hour")
              .format("YYYY-MM-DD HH:mm");
            const endDate = dayjs(status.date as string)
              .add(prevTime + 1, "hour")
              .format("YYYY-MM-DD HH:mm");

            events.push({
              title: task.data.label as string,
              start: startDate,
              end: endDate,
              date: status.date,
              extendedProps: {
                colors: colors,
                task,
                date: status.date,
              },
              color: "transparent",
            });

            // 다음 구간 시작 시간 초기화
            startTime = currentTime;
          }

          prevTime = currentTime;
        }
      } else {
        events.push({
          title: task.data.label as string,
          date: status.date,
          extendedProps: {
            colors: colors,
            task,
            date: status.date,
          },
          color: "transparent",
          allDay: true,
        });
      }
    });
  } else if (task.data.startDate && task.data.endDate) {
    const startDate = dayjs(task.data.startDate as string);
    const endDate = dayjs(task.data.endDate as string);

    events.push({
      title: task.data.label as string,
      date: startDate.format("YYYY-MM-DD"),
      extendedProps: {
        colors: colors,
        task,
        date: startDate.format("YYYY-MM-DD"),
      },
      start: startDate.format("YYYY-MM-DD"),
      end: endDate.format("YYYY-MM-DD"),
      color: `${color}70`,
    });
  } else if (task.data.startDate) {
    const times = task.data.times as number[];

    if (times && times.length > 0) {
      const sortedTimes = [...times].map(Number).sort((a, b) => a - b);
      let startTime = sortedTimes[0];
      let prevTime = sortedTimes[0];

      for (let i = 1; i <= sortedTimes.length; i++) {
        const currentTime = sortedTimes[i];

        // 연속되지 않은 경우 또는 마지막 시간대일 때
        if (currentTime !== prevTime + 1 || i === sortedTimes.length) {
          const startDate = dayjs(task.data.startDate as string)
            .add(startTime, "hour")
            .format("YYYY-MM-DD HH:mm");
          const endDate = dayjs(task.data.startDate as string)
            .add(prevTime + 1, "hour")
            .format("YYYY-MM-DD HH:mm");

          events.push({
            title: task.data.label as string,
            start: startDate,
            end: endDate,
            extendedProps: {
              colors: colors,
              task,
              date: task.data.startDate as string,
            },
            date: task.data.startDate as string,
            color: "transparent",
          });

          // 다음 구간 시작 시간 초기화
          startTime = currentTime;
        }

        prevTime = currentTime;
      }
    } else {
      // 시간대가 없는 경우 하루 종일로 표시
      events.push({
        title: task.data.label as string,
        date: task.data.startDate as string,
        extendedProps: {
          colors: colors,
          task,
          date: task.data.startDate as string,
        },
        color: "transparent",
        allDay: true,
      });
    }
  }
};

// 현재 월 데이터 필터링
const filterCurrentMonthEvents = (
  events: CalendarEventProps[],
  year: number,
  month: number
) => {
  // return events.filter((event) => {
  //   const eventDate = dayjs(event.date);
  //   return eventDate.year() === year && eventDate.month() === month - 1;
  // });
  const filteredEvents = events.filter((event) => {
    const eventDate = dayjs(event.date);
    return eventDate.year() === year && eventDate.month() === month - 1;
  });
  return filteredEvents;
};

export const getNotConnectedTasksInCurrentRoutineAtom = atom((get) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const currentPlanData = planData.find(
    (data) => data.innerTabId === innerTabId
  );
  const sidebar = get(routineSidebarAtom);
  if (sidebar.length === 0) return [];
  if (!currentPlanData) return [];
  const data = sidebar[sidebar.length - 1];
  const currentRoutine = data.key;
  if (!currentRoutine) return [];
  const { nodes, edges } = currentPlanData;
  const tasks = nodes.filter((node) => node.type === "task");
  const routineTasks = edges
    .filter((edge) => edge.source === currentRoutine.id)
    .map((edge) => nodes.find((node) => node.id === edge.target))
    .filter((node): node is Node => node !== undefined);
  const notConnectedTasks = tasks.filter(
    (task) => !routineTasks.includes(task)
  );
  return notConnectedTasks;
});
