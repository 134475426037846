import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import {
  prevTaskOrderAtom,
  routineSidebarAtom,
  RoutineSidebarData,
  RoutineSidebarProps,
  TaskOrderProps,
  tasksInRoutineAtom,
} from "@/Atoms/Plan/RoutineAtom";
import { updateTaskOrderInRoutine } from "@/Queries/RoutineQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { Node } from "@xyflow/react";
import { atom } from "jotai";

export const pushRoutineSidebarDataAtom = atom(
  null,
  (get, set, data: RoutineSidebarData) => {
    const currentData = get(routineSidebarAtom);
    const newData: RoutineSidebarProps = {
      ...data,
      id: currentData.length,
    };

    set(routineSidebarAtom, [...currentData, newData]);
  }
);

export const popRoutineSidebarDataAtom = atom(null, (get, set) => {
  const currentData = get(routineSidebarAtom);
  const newData = [...currentData.slice(0, currentData.length - 1)];
  set(routineSidebarAtom, newData);
});

export const popAndChangeKeySidebarDataAtom = atom(
  null,
  (get, set, key: Node | null) => {
    const currentData = get(routineSidebarAtom);
    const newData = [...currentData.slice(0, currentData.length - 1)];
    const prevData = newData[newData.length - 1];
    const newPrevData = {
      ...prevData,
      key,
    };
    const finalData = [...newData.slice(0, newData.length - 1), newPrevData];
    set(routineSidebarAtom, finalData);
  }
);

export const popAndChangeTasksInRoutineAtom = atom(
  null,
  (get, set, tasks: Node[]) => {
    const currentData = get(routineSidebarAtom);
    const newData = [...currentData.slice(0, currentData.length - 1)];
    const prevData = newData[newData.length - 1];
    const newPrevData = {
      ...prevData,
      tasks,
    };
    const finalData = [...newData.slice(0, newData.length - 1), newPrevData];
    set(routineSidebarAtom, finalData);
  }
);

export const clearRoutineSidebarDataAtom = atom(null, async (get, set) => {
  const data = get(routineSidebarAtom);
  if (data.length === 0) return;
  const currentData = data[data.length - 1];
  if (currentData.type === "routine") {
    const prevTaskOrder = get(prevTaskOrderAtom);
    const newTaskOrder: TaskOrderProps[] = get(tasksInRoutineAtom).map(
      (task, index) => ({
        backendId: task.data.backendId as number,
        order: index + 1,
        id: task.id,
      })
    );
    // 순서가 변경되었을 경우만 업데이트
    if (JSON.stringify(prevTaskOrder) !== JSON.stringify(newTaskOrder)) {
      const requestBody = newTaskOrder.map((task) => ({
        task_id: task.backendId,
        order: task.order,
      }));
      await handleReactQueryApiResponse(
        updateTaskOrderInRoutine,
        () => set(error401ModalAtom, true),
        currentData.key?.data.backendId as number,
        requestBody
      );
    }
  }
  set(routineSidebarAtom, []);
});
