import { releaseModalAtom } from "@/Atoms/RootAtom";
import React from "react";
import { Box, Button, Chip, Modal, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { setCookie } from "@/Utils/CookieUtils";

/** releaseData 예시 */
export const releaseData = [
  {
    category: "직업 추천",
    text: "직업 추천을 통해 만들어진 커리어 플랜에, 학과별로 선택하면 좋은 선택과목과 직업별로 참여하면 좋은 진로 프로그램 및 교육 동영상이 추가되었습니다.",
    date: "2025-02-11",
  },
  {
    category: "플랜",
    text: "커리어 플랜과 일정을 분리하여, 보다 체계적으로 플랜을 생성할 수 있게 되었습니다.",
    date: "2025-02-11",
  },
  {
    category: "일정 관리",
    text: "일정 관리 기능에서는 약속, 해야 할 일, 혹은 루틴처럼 하고 싶은 일들을 등록하고 관리할 수 있습니다.",
    date: "2025-02-11",
  },
];

const ReleaseModal: React.FC = () => {
  const [releaseState, setReleaseState] = useAtom(releaseModalAtom);

  // 모달 닫기 + 페이지 리로드
  const handleCloseAndReload = () => {
    setCookie("release-version", "1.0", {
      path: "/",
      sameSite: "Lax",
      domain: process.env.REACT_APP_DOMAIN_NAME,
      secure: true,
    });

    setReleaseState(false);
  };

  return (
    <Modal
      open={releaseState}
      onClose={handleCloseAndReload}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "32px",
          outline: "none",
          textAlign: "center",
          borderRadius: "8px",
          width: "700px",
          boxSizing: "border-box",
          // 모바일 대응
          "@media (max-width: 768px)": {
            width: "90%",
            padding: "16px",
          },
        }}
      >
        {/** Stack으로 가로 레이아웃, 모바일에서는 세로로 전환 */}
        <Stack
          direction="row"
          sx={{
            boxSizing: "border-box",
            gap: "32px",
            "@media (max-width: 768px)": {
              flexDirection: "column",
              gap: 0,
            },
          }}
        >
          {/* 왼쪽: 이미지 (30%) - 모바일에선 숨김 */}
          <Box
            sx={{
              width: "30%",
              flexShrink: 0,
              "@media (max-width: 768px)": {
                display: "none",
              },
            }}
          >
            <img
              src={"/careerplanner.png"}
              alt="careerplanner"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Box>

          {/* 오른쪽 (70%): 릴리즈 노트 내용 - 모바일에선 폭 100% */}
          <Box
            sx={{
              width: "70%",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              maxHeight: "70vh", // 리스트가 많을 때 이 영역만 스크롤
              overflowY: "auto",
              boxSizing: "border-box",
              "@media (max-width: 768px)": {
                width: "100%",
              },
            }}
          >
            {/* 타이틀 */}
            <Typography
              id="modal-title"
              variant="h6"
              sx={{ marginBottom: 2, fontWeight: "bold" }}
            >
              릴리즈 노트
            </Typography>

            {/* 릴리즈 리스트 */}
            <Box sx={{ overflowY: "auto" }}>
              {releaseData.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    paddingBottom: 2,
                    borderBottom: "1px solid #ececec",
                    paddingTop: 2,
                  }}
                >
                  {/* 카테고리 Chip */}
                  <Chip
                    label={item.category}
                    sx={{
                      marginBottom: 1,
                      background: "#06C25E",
                      color: "white",
                    }}
                  />
                  {/* 내용 (text) */}
                  <Typography variant="body1" sx={{ fontSize: "0.95rem" }}>
                    {item.text}
                  </Typography>
                  {/* 날짜 (date) */}
                  <Typography
                    variant="caption"
                    sx={{ display: "block", marginTop: 0.5, color: "gray" }}
                  >
                    {item.date}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/* 맨 아래 버튼 */}
            <Box sx={{ marginTop: 3 }}>
              <Button
                variant="contained"
                sx={{
                  background: "#06C25E",
                  color: "white",
                  width: "100%",
                  "&:hover": {
                    background: "#06C25E",
                  },
                }}
                onClick={handleCloseAndReload}
              >
                새로워진 커리어플래너 만나러 가자!
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ReleaseModal;
