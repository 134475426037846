import { MentorProps } from "@/Atoms/MentorAtom";
import { devConsoleError } from "@/Utils/ConsoleLogInDevelopment";
import { getCookie } from "@/Utils/CookieUtils";
import { getVerifyRefreshToken } from "./AuthQueries";

export async function requestChatbot(
  access: string,
  input_message: string,
  user_id: string,
  flow_socket_name: string,
  character: string,
  handleStreamMessage: (
    message: string,
    isFirst: boolean,
    isNotStream: boolean,
    flow_socket_name: string
  ) => void,
  handleJson: (data: any) => void
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      flow_socket_name: flow_socket_name,
      input_message: input_message,
      user_id: user_id,
      character: -1,
    }),
  };

  try {
    let response = await fetch(
      `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/chatbot`,
      requestOptions
    );

    if (response.status === 403) {
      // return response;
      const refreshCookie = getCookie(
        process.env.REACT_APP_USER_REFRESH_COOKIE_ID
      );
      try {
        const newAccessToken = await getVerifyRefreshToken(refreshCookie ?? "");
        const newRequestOptions = {
          ...requestOptions,
          headers: {
            ...requestOptions.headers,
            Authorization: `Bearer ${newAccessToken}`,
          },
        };
        response = await fetch(
          `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/chatbot`,
          newRequestOptions
        );
      } catch (error) {
        devConsoleError("토큰 갱신 중 오류 발생:", error);
      }
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let isFirst = true;
    let message = "";

    while (true) {
      const { done, value } = await reader!.read();

      if (done) {
        // 여기에 음성을 출력하는 기능 추가
        break;
      }

      const chunk = decoder.decode(value, { stream: true });

      if (chunk.startsWith("JSON:")) {
        const jsonString = chunk.replace(/^JSON:/, "").replace(/:JSON$/, "");
        const json = JSON.parse(jsonString);
        handleJson(json.data as { job: string }[]);
      } else {
        message += chunk;
        handleStreamMessage(message, isFirst, false, flow_socket_name);

        isFirst = false;
      }
    }
  } catch (error) {}
}

export const getMentorList = async (
  accessToken: string
): Promise<MentorProps[]> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMentorFlow`,
    requestOptions
  );
  const data = await response.json();
  const mentorList: MentorProps[] = data.results.map((data: any) => ({
    id: data.id,
    character_image: data.character_image,
    name: data.name,
    chatbot_socket_parameter: data.chatbot_socket_parameter,
    maker: data.maker,
  }));
  return mentorList;
};

export async function requestJobWithChatbot(
  access: string,
  input_message: string,
  flow_socket_name: string,
  user_id: string
): Promise<{
  usingDocs: string[];
  topic: string | null;
} | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({
      flow: flow_socket_name,
      message: input_message,
      user_id: user_id,
    }),
  };
  let parsedData;
  try {
    const response = await fetch(
      `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/makeChatbotResposeWithDocs`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("응답을 불러오지 못했습니다.");
    }
    const reader = response.body?.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader!.read();

      if (done) {
        break;
      }

      const chunk = decoder.decode(value, { stream: true });
      const lines = chunk.split("$$$DOK##G!A$$B!I##END$$$").filter(Boolean);

      for (const line of lines) {
        try {
          parsedData = JSON.parse(line);
        } catch (error) {
          devConsoleError("JSON 파싱 오류 발생한 데이터:", line);
          devConsoleError("JSON 파싱 오류:", error);
        }
      }
    }
  } catch (error) {
    devConsoleError("스트리밍 응답 처리 중 오류 발생:", error);
  } finally {
    if (parsedData.using_docs && parsedData.using_docs.length > 0) {
      return { usingDocs: parsedData.using_docs, topic: parsedData.topic };
    }
  }
  return null;
}
