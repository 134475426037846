import { createSignatureAtom, createSignatureMobileModeAtom, signatureAtom, signatureCurrentIndexAtom, SignatureInfo } from '@/Atoms/Signature/Atom';
import { atom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { changeModeSignatureDialogAtom } from './ViewModel';
import snackbarAtom from '@/Atoms/Snackbar';
import { updateSignature } from '@/Queries/SignatureQueries';
import { handleReactQueryApiResponse } from '@/Utils/APIUtil';
import { error401ModalAtom } from '@/Atoms/Dialogs/Error/401Atom';
import { getUserMembershipAtom } from '../UserViewModel';
import { useMenteeDashboardAtom } from '@/Atoms/HookControllerAtom';

// 이름 수정
export const editNameCreateSignatureAtom = atom(null, (get, set, name: string) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  set(createSignatureAtom, {
    ...createSignature,
    name,
  });
});

// 닉네임 수정
export const editNicknameCreateSignatureAtom = atom(null, (get, set, nickname: string) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  set(createSignatureAtom, {
    ...createSignature,
    nickname,
  });
});

// 이미지 수정
export const editImageCreateSignatureAtom = atom(null, (get, set, image: File) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  set(createSignatureAtom, {
    ...createSignature,
    image,
    isUserProfile: false,
    isUploadImage: true,
  });
});

// 기본 이미지로 변경
export const setDefaultImageCreateSignatureAtom = atom(null, (get, set) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  set(createSignatureAtom, {
    ...createSignature,
    image: null,
    isUserProfile: true,
    isUploadImage: false,
  });
});

// previewImage 수정
export const editPreviewImageCreateSignatureAtom = atom(null, (get, set, previewImage: string | null) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  set(createSignatureAtom, {
    ...createSignature,
    previewImage,
    isUserProfile: previewImage === null,
  });
});

// 태그 수정
export const editTagCreateSignatureAtom = atom(null, (get, set, tag: string) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  if (tag.length > 8) {
    return;
  }
  set(createSignatureAtom, {
    ...createSignature,
    tag,
  });
});

// 시그니쳐 타입 수정
export const editCareerAdvancementCreateSignatureAtom = atom(null, (get, set, careerAdvancement: boolean) => {
  if (careerAdvancement === null) {
    return;
  }
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  set(createSignatureAtom, {
    ...createSignature,
    careerAdvancement,
  });
});

// 시그니쳐 정보 수정(string)
export const editSignatureInfoStringCreateSignatureAtom = atom(null, (get, set, key: string, value: string) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  const info = (createSignature.info).map((info) => {
    if (info.key === key) {
      return {
        ...info,
        value,
      }
    }
    return info;
  });
  const orderList = (createSignature.orderList).map((info) => {
    if (info.key === key) {
      return {
        ...info,
        value,
      }
    }
    return info;
  });
  set(createSignatureAtom, {
    ...createSignature,
    info,
    orderList,
  });
});

// 시그니쳐 정보 라벨 수정
export const editSignatureInfoLabelCreateSignatureAtom = atom(null, (get, set, key: string, label: string) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  const info = createSignature.info.map((info) => {
    if (info.key === key) {
      return {
        ...info,
        label,
      }
    }
    return info;
  });
  const orderList = createSignature.orderList.map((info) => {
    if (info.key === key) {
      return {
        ...info,
        label,
      }
    }
    return info;
  });
  set(createSignatureAtom, {
    ...createSignature,
    info,
    orderList,
  });
});

const createSignatureInfo = (label: string): SignatureInfo => {
  return {
    key: uuidv4(),
    label,
    value: "",
    orderBy: 0,
    visible: true,
  }
}

// 시그니쳐 정보 추가
export const addSignatureInfoCreateSignatureAtom = atom(null, (get, set) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  const info = createSignatureInfo("추가항목");
  const newInfo = [...createSignature.info, info].map((info, index) => ({
    ...info,
    orderBy: index,
  }));
  const orderList = [...createSignature.orderList, info];
  // const customOrderList = [...createSignature.customOrderList, info];
  // const careerOrderList = [...createSignature.careerOrderList, info];
  set(createSignatureAtom, {
    ...createSignature,
    info: newInfo,
    orderList,
  });
});

// 시그니쳐 정보 삭제
export const removeSignatureInfoCreateSignatureAtom = atom(null, (get, set, key: string) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  const info = createSignature.info.filter((info) => info.key !== key).map((info, index) => ({
    ...info,
    orderBy: index,
  }));
  const orderList = createSignature.orderList.filter((info) => info.key !== key);
  set(createSignatureAtom, {
    ...createSignature,
    info,
    orderList,
  });
});

// 시그니쳐 정보 순서 변경 후 저장
export const saveSignatureInfoOrderListCreateSignatureAtom = atom((get) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return []; // 기본값을 빈 배열로 설정
  }
  return createSignature.orderList;
}, (get, set, items: SignatureInfo[]) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  set(createSignatureAtom, {
    ...createSignature,
    orderList: items,
  });
});

// 대표 시그니쳐 여부 수정
export const editRepresentativeCreateSignatureAtom = atom(null, (get, set, representative: boolean) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  set(createSignatureAtom, {
    ...createSignature,
    representative,
  });
});

// 시그니쳐 색상 수정
export const editSignatureColorCreateSignatureAtom = atom(null, (get, set, color: string, type: "borderColor" | "backgroundColor" | "fontColor") => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  set(createSignatureAtom, {
    ...createSignature,
    [type]: color,
  });
});

// 시그니쳐 생성
export const createSignatureCreateSignatureAtom = atom(null, async (get, set) => {
  const currentIndex = get(signatureCurrentIndexAtom);
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  // API 호출
  const handleError401Modal = () => set(error401ModalAtom, true);
  const res = await handleReactQueryApiResponse(updateSignature, handleError401Modal, createSignature);
  if (!res.ok) {
    return;
  }
  const responseJson = await res.json();
  const response = responseJson.signatures;
  set(signatureAtom, response);
  set(snackbarAtom, (prev) => ({ ...prev, open: true, message: "시그니쳐가 저장되었습니다." }));
  if (["mentee", "basic", "standard", "professional"].includes(get(getUserMembershipAtom))) {
    set(useMenteeDashboardAtom, true);
  }
  set(createSignatureAtom, null);
  set(changeModeSignatureDialogAtom, "list");
  set(createSignatureMobileModeAtom, "edit");
  setTimeout(() => {
    set(signatureCurrentIndexAtom, currentIndex === -1 ? 0 : currentIndex);
  }, 100);
});

// 항목 숨김 / 표시
export const toggleVisibleSignatureInfoCreateSignatureAtom = atom(null, (get, set, key: string) => {
  const createSignature = get(createSignatureAtom);
  if (!createSignature) {
    return;
  }
  const info = (createSignature.orderList).map((info) => {
    if (info.key === key) {
      return {
        ...info,
        visible: !info.visible,
      }
    }
    return info;
  });
  set(createSignatureAtom, {
    ...createSignature,
    orderList: info,
  });
});
