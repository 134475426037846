import React from 'react';
import * as S from './styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { connectedRoutinesAtom, deleteTaskModalOpenAtom, editTaskInRoutineStateAtom, RoutineSidebarProps } from '@/Atoms/Plan/RoutineAtom';
import { CheckCircle, Delete, Edit } from '@mui/icons-material';
import TitleValueInARow from '../Common/TitleValueInARow';
import { Node } from '@xyflow/react';
import theme from '@/Styles/theme';
import TitleValueInEachRow from '../Common/TitleValueInEachRow';
import { getConnectedRoutinesForTaskInRoutineAtom } from '@/ViewModels/Plan/Routine/DataViewModel';
import { getTextColorBasedOnBackground } from '@/Utils/GetRandomColor';
import { deleteTaskInRoutineAtom, requestChangeTaskStatusInRoutineByIdAtom } from '@/ViewModels/Plan/Routine/TaskViewModel';
import { pushRoutineSidebarDataAtom } from '@/ViewModels/Plan/Routine/SidebarViewModel';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

type Hashtag = {
  backgroundColor: string;
  textColor: string;
  value: string;
}

const getRepeatCycle = (task: Node) => {
  const termType = task.data.termType as number | null;
  const termData = task.data.termData as string[] | number[] | null;

  if (termType === null) return null;
  if (termType === 0) return "매일";
  if (termType === 1 && termData) {
    const days = termData as string[];
    return `매주 ${days.join(", ")}`;
  }
  const days = termData as number[];
  return `매월 ${days.join(", ")}일`;
}

interface Props {
  data: RoutineSidebarProps;
}

const MyTaskInRoutine = ({ data }: Props) => {
  const { t } = useTranslation();
  const connectedRoutines = useAtomValue(getConnectedRoutinesForTaskInRoutineAtom);
  const deleteTask = useSetAtom(deleteTaskInRoutineAtom);
  const changeTaskStatus = useSetAtom(requestChangeTaskStatusInRoutineByIdAtom);
  const openSidebar = useSetAtom(pushRoutineSidebarDataAtom);
  const setEditTask = useSetAtom(editTaskInRoutineStateAtom);
  const setConnectedRoutines = useSetAtom(connectedRoutinesAtom);
  const today = new Date();
  const vaildTaskStatus = data.taskStatus !== undefined && data.taskStatus.date <= dayjs(today).format("YYYY-MM-DD"); // taskStatus가 존재하고 date가 오늘 이전의 날짜인 경우
  const openDeleteTaskModal = useSetAtom(deleteTaskModalOpenAtom);

  const handleEditTask = () => {
    if (!data.key) return;
    setEditTask({
      id: data.key.id,
      label: data.key.data.label as string,
      content: data.key.data.content as string,
      startDate: dayjs(data.key.data.startDate as string),
      originStartDate: dayjs(data.key.data.startDate as string),
      endDate: data.key.data.endDate ? dayjs(data.key.data.endDate as string) : null,
      termType: data.key.data.termType as number | null,
      termData: data.key.data.termData as string[] | null,
      hashtags: data.key.data.hashtags as Hashtag[],
      color: data.key.data.color as string,
      times: data.key.data.times as string[],
      isLoading: false,
      backendId: data.key.data.backendId as number,
    });
    const connected = connectedRoutines.map((routine) => ({ id: routine.id, backendId: routine.data.backendId as number, label: routine.data.label as string, color: routine.data.color as string }));
    setConnectedRoutines(connected);
    openSidebar({
      type: "editTask",
      key: null,
      tasks: [],
    });
  };

  const getTime = (date: string) => {
    const dayjsDate = dayjs(date);
    return t('plan.contents.routine.dateNavigator.dayFormat', {
      year: dayjsDate.year(),
      month: dayjsDate.month() + 1,
      date: dayjsDate.date(),
      day: dayjsDate.date(),
      monthEn: dayjsDate.format('MMMM'),
    });
  }

  const handleOpenDeleteTaskModal = () => {
    if (data.key) {
      openDeleteTaskModal({
        id: data.key.id,
        loading: false,
      });
    }
  }

  return (
    data.key !== null &&
    <div style={{ width: "100%", height: "100%", overflowY: "auto", display: "flex", flexDirection: "column", gap: "20px", position: "relative" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "4px", alignItems: "flex-start" }}>
        <S.TaskTitleContainer>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }} >
            {
              data.taskStatus !== undefined && data.taskStatus.status === 1 &&
              <CheckCircle sx={{ color: theme.colors.primary, width: "20px", height: "20px" }} />
            }
            {data.key.data.label as string}
          </div>
        </S.TaskTitleContainer>
        {
          data.taskStatus !== undefined && data.taskStatus.completionDate !== null &&
          <div style={{ fontSize: "10px", color: "gray" }}>
            {t("plan.contents.routine.sidebar.content.completionDate")}: {getTime(data.taskStatus.completionDate)}
          </div>
        }
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "flex-end" }}>
        {
          vaildTaskStatus && data.taskStatus !== undefined && data.taskStatus.status === 0 &&
          <div style={{ fontSize: "14px", fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={() => data.key !== null && changeTaskStatus
            (data.key, data.taskStatus?.taskStatusId as number, t)
          }>{t("plan.contents.routine.sidebar.content.complete2")}</div>
        }
        {
          vaildTaskStatus && data.taskStatus !== undefined && data.taskStatus.status === 1 &&
          <div style={{ fontSize: "14px", fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={() => data.key !== null && changeTaskStatus
            (data.key, data.taskStatus?.taskStatusId as number, t)
          }>{t("plan.contents.routine.sidebar.content.cancel2")}</div>
        }
        {
          !vaildTaskStatus && data.taskStatus !== undefined &&
          <div style={{ fontSize: "12px", fontWeight: "bold", color: "gray" }}>{t("plan.contents.routine.sidebar.content.notYet", { date: data.taskStatus.date })}</div>
        }
        <Tooltip title={t("plan.contents.routine.sidebar.content.edit2")} placement="top" arrow>
          <Edit sx={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={handleEditTask} />
        </Tooltip>
        <Tooltip title={t("plan.contents.routine.sidebar.content.delete")} placement="top" arrow>
          <Delete sx={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={handleOpenDeleteTaskModal} />
        </Tooltip>
      </div>
      {
        typeof data.key.data.startDate === 'string' &&
        <TitleValueInARow title={t("plan.contents.routine.sidebar.content.startDate")} value={getTime(data.key.data.startDate)} />
      }
      {
        typeof data.key.data.endDate === 'string' &&
        <TitleValueInARow title={t("plan.contents.routine.sidebar.content.endDate")} value={getTime(data.key.data.endDate as string)} />
      }
      {
        // times: string[]
        typeof data.key.data.times === 'object' && data.key.data.times && (data.key.data.times as string[]).length > 0 &&
        <TitleValueInEachRow
          title={t("plan.contents.routine.sidebar.content.time")}
          value={
            <div style={{ width: "100%", height: "50px", overflowX: "auto", display: "flex", flexDirection: "row" }}>
              {Array.from({ length: 24 }).map((_, index) => (
                <div
                  key={index}
                  style={{
                    minWidth: '30px',
                    height: '100%',
                    fontSize: "10px",
                    fontWeight: "bold",
                    backgroundColor: (data.key?.data.times as string[]).includes(index.toString()) ? theme.colors.primary : '#e9e9e9',
                    color: (data.key?.data.times as string[]).includes(index.toString()) ? 'white' : 'black',
                  }}
                >
                  {index}
                </div>
              ))}
            </div>
          }
        />
      }
      {
        getRepeatCycle(data.key) &&
        <TitleValueInARow title={t("plan.contents.routine.sidebar.content.repeat")} value={getRepeatCycle(data.key)} />
      }
      <TitleValueInARow
        title={t("plan.contents.routine.sidebar.content.color")}
        value={
          <S.TaskColor $color={data.key.data.color ? data.key.data.color as string : "black"} onClick={() => { }} />
        }
      />
      {
        typeof data.key.data.hashtags === 'object' && data.key.data.hashtags && (data.key.data.hashtags as Hashtag[]).length > 0 &&
        <TitleValueInEachRow title={t("plan.contents.routine.sidebar.content.hashtag")} value={
          <S.EachRowValueContainer>
            {
              (data.key.data.hashtags as Hashtag[]).map((hashtag, index) => {
                const keyDataColor = data.key?.data.color ? data.key?.data.color as string : "black";
                const color = getTextColorBasedOnBackground(keyDataColor);
                return <S.EachRowValue key={index} $backgroundColor={keyDataColor} $color={color}>
                  {hashtag.value}
                </S.EachRowValue>
              })
            }
          </S.EachRowValueContainer>
        } />
      }
      {
        connectedRoutines.length > 0 &&
        <TitleValueInEachRow title={t("plan.contents.routine.sidebar.content.connectedRoutines")} value={
          <S.EachRowValueContainer>
            {
              connectedRoutines.map((routine, index) => {
                const routineColor = routine.data.color ? routine.data.color as string : "black";
                const color = getTextColorBasedOnBackground(routineColor);
                return <S.EachRowValue key={index} $backgroundColor={routineColor} $color={color}>
                  {routine.data.label as string}
                </S.EachRowValue>
              })
            }
          </S.EachRowValueContainer>
        } />
      }
      {
        typeof data.key.data.content === 'string' && data.key.data.content.length !== 0 &&
        <TitleValueInEachRow title={t("plan.contents.routine.sidebar.content.content")} value={data.key.data.content} />
      }
    </div>
  )
}

export default MyTaskInRoutine;
