import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import {
  connectedRoutinesAtom,
  editTaskInRoutineEditRoutineAtom,
  editTaskInRoutineStateAtom,
  routineSidebarAtom,
} from "@/Atoms/Plan/RoutineAtom";
import snackbarAtom from "@/Atoms/Snackbar";
import { updateTaskV2 } from "@/Queries/PlanQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { atom } from "jotai";
import { setInnerTabDataAtom } from "../InnerTabViewModel";
import {
  clearRoutineSidebarDataAtom,
  popAndChangeKeySidebarDataAtom,
} from "./SidebarViewModel";
import { Edge, Node } from "@xyflow/react";
import { TFunction } from "i18next";
import { ganttChartOrderTasksAtom } from "./GanttChartViewModel";
import { getTermData } from "@/Utils/DatetimeUtil";
import dayjs from "dayjs";

// 루틴 변경 시작
export const editTaskInRoutineEditRoutineStartAtom = atom(null, (get, set) => {
  const state = get(editTaskInRoutineStateAtom);
  if (!state) return;
  const termData = state.termData;
  set(editTaskInRoutineEditRoutineAtom, {
    termType: state.termType,
    termData: termData ? [...termData] : [],
  });
});

// 루틴 일자 초기화
export const editTaskInRoutineEditRoutineClearStateAtom = atom(
  null,
  (get, set) => {
    set(editTaskInRoutineEditRoutineAtom, {
      termType: null,
      termData: null,
    });
  }
);

// 루틴 변경 적용
export const editTaskInRoutineEditRoutineApplyAtom = atom(null, (get, set) => {
  const { termType, termData } = get(editTaskInRoutineEditRoutineAtom);
  const state = get(editTaskInRoutineStateAtom);

  if (!termType || !termData || !state) return;

  // termType === 1 일 경우 [월, 화, 수, 목, 금, 토, 일] 순으로 정렬
  if (termType === 1) {
    termData.sort((a, b) => {
      const week = ["월", "화", "수", "목", "금", "토", "일"];
      return week.indexOf(a) - week.indexOf(b);
    });
  }
  // termType === 2 일 경우 숫자로 정렬
  else {
    termData.sort((a, b) => parseInt(a) - parseInt(b));
  }

  const newState = {
    ...state,
    termData: termData,
  };
  set(editTaskInRoutineStateAtom, newState);
  set(editTaskInRoutineEditRoutineClearStateAtom);
});

// 루틴 일자 변경
export const editTaskInRoutineEditRoutineDataAtom = atom(
  (get) => get(editTaskInRoutineEditRoutineAtom),
  (get, set, day: string) => {
    const editRoutineData = get(editTaskInRoutineEditRoutineAtom);
    if (!editRoutineData.termData) return;
    if (editRoutineData.termData.includes(day)) {
      set(editTaskInRoutineEditRoutineAtom, {
        termType: editRoutineData.termType,
        termData: editRoutineData.termData.filter(
          (interval) => interval !== day
        ),
      });
    } else {
      set(editTaskInRoutineEditRoutineAtom, {
        termType: editRoutineData.termType,
        termData: [...editRoutineData.termData, day],
      });
    }
  }
);

export const editTaskInRoutineUpdateTaskAtom = atom(
  null,
  async (get, set, t: TFunction) => {
    const editTaskData = get(editTaskInRoutineStateAtom);
    const connectedRoutines = get(connectedRoutinesAtom);
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const plan = planData.find((data) => data.innerTabId === innerTabId);
    const sidebar = get(routineSidebarAtom);
    if (!plan || sidebar.length === 0 || !editTaskData) return;
    const data = sidebar[sidebar.length - 1];
    const prevData = sidebar[sidebar.length - 2];
    const orders = get(ganttChartOrderTasksAtom);

    if (editTaskData.label === "") {
      set(snackbarAtom, {
        open: true,
        message: t("plan.contents.routine.snackbar.inputTaskName"),
        severity: "error",
      });
      return;
    }
    if (editTaskData.label.length > 50) {
      set(snackbarAtom, {
        open: true,
        message: t("plan.contents.routine.snackbar.taskNameLength"),
        severity: "error",
      });
      return;
    }
    const loadingState = {
      ...editTaskData,
      isLoading: true,
    };
    set(editTaskInRoutineStateAtom, loadingState);

    const today = dayjs().startOf("day");
    let { startDate, endDate, termType, termData, times } = editTaskData;

    // 경우 1: 한 가지만 설정된 경우
    if (!startDate && endDate && times.length === 0 && !termType) {
      startDate = today;
    } else if (!startDate && !endDate && times.length > 0 && !termType) {
      startDate = today;
    } else if (
      !startDate &&
      !endDate &&
      times.length === 0 &&
      termType !== null &&
      [0, 1, 2].includes(termType)
    ) {
      startDate = today;
      endDate = today.add(1, "month");
      if (termType !== 0 && !termData) termData = getTermData(today, termType);
    }

    // 경우 2: 두 가지가 설정된 경우
    else if (startDate && !endDate && times.length > 0 && !termType) {
      endDate = startDate;
    } else if (
      startDate &&
      !endDate &&
      times.length === 0 &&
      termType !== null &&
      [0, 1, 2].includes(termType)
    ) {
      endDate = startDate.add(1, "month");
      if (termType !== 0 && !termData)
        termData = getTermData(startDate, termType);
    } else if (!startDate && endDate && times.length > 0 && !termType) {
      startDate = today;
    } else if (
      !startDate &&
      endDate &&
      times.length === 0 &&
      termType !== null &&
      [0, 1, 2].includes(termType)
    ) {
      startDate = today;
      if (termType !== 0 && !termData) termData = getTermData(today, termType);
    } else if (
      !startDate &&
      !endDate &&
      times.length > 0 &&
      termType !== null &&
      [0, 1, 2].includes(termType)
    ) {
      startDate = today;
      endDate = today.add(1, "month");
      if (termType !== 0 && !termData) termData = getTermData(today, termType);
    }

    // 경우 3: 세 가지가 설정된 경우
    else if (
      startDate &&
      endDate &&
      times.length === 0 &&
      termType !== null &&
      [0, 1, 2].includes(termType)
    ) {
      if (termType !== 0 && !termData)
        termData = getTermData(startDate, termType);
    } else if (
      !startDate &&
      endDate &&
      times.length > 0 &&
      termType !== null &&
      [0, 1, 2].includes(termType)
    ) {
      startDate = today;
      if (termType !== 0 && !termData) termData = getTermData(today, termType); // 종료일, 시간 설정, 반복 주기 설정
    }

    const requestData = {
      label: editTaskData.label,
      content: editTaskData.content,
      hashtags: editTaskData.hashtags,
      startDate: startDate, // 로컬 시간으로 포맷팅
      endDate: endDate, // 로컬 시간으로 포맷팅
      termType: termType,
      termData: termData,
      color: editTaskData.color,
      times: times,
      routines: connectedRoutines.map((routine) => ({
        routine_id: routine.backendId,
      })),
      type: "task",
    };
    const planNodes = plan.nodes;
    const planEdges = plan.edges;
    const routineOrders = orders.order.filter(
      (order) =>
        order.type === "task_in_routine" &&
        order.taskId === editTaskData.backendId
    );
    const notConnected = routineOrders.filter(
      (order) =>
        !connectedRoutines.some(
          (routine) => routine.backendId === order.routineId
        )
    );
    const notConnectedRoutineIds = notConnected.map(
      (order) => order.innerTabOrderId
    );

    const taskRoutines = planNodes.filter((node) => node.type === "routine");
    const taskEdges = planEdges.filter(
      (edge) => edge.target === editTaskData.id
    );
    // 이전에 연결된 루틴들
    const prevTaskRoutines = taskEdges
      .map((edge) => taskRoutines.find((node) => node.id === edge.source))
      .filter((node): node is Node => node !== undefined);

    // 이전에 연결된 루틴들 중 현재 연결되지 않은 루틴들
    const notConnectedRoutines = prevTaskRoutines.filter(
      (node) =>
        !connectedRoutines.some(
          (routine) => routine.backendId === node.data.backendId
        )
    );

    // 연결되지 않은 루틴들과 task를 연결하는 edge들
    const notConnectedEdges = taskEdges.filter((edge) =>
      notConnectedRoutines.some((node) => node.id === edge.source)
    );

    // plan.edges에서 연결되지 않은 edge들을 제외
    const filteredNotConnectedEdges = planEdges.filter(
      (edge) =>
        !notConnectedEdges.some(
          (notConnectedEdge) => notConnectedEdge.id === edge.id
        )
    );

    const response = await handleReactQueryApiResponse(
      updateTaskV2,
      () => set(error401ModalAtom, true),
      innerTabId,
      editTaskData.backendId,
      requestData,
      notConnectedRoutineIds
    );

    if (!response.ok) {
      set(snackbarAtom, {
        open: true,
        message: t("plan.contents.routine.snackbar.editTaskFail"),
        severity: "error",
      });
      set(editTaskInRoutineStateAtom, { ...editTaskData, isLoading: false });
      return;
    }

    const responseJson = await response.json();

    const { nodes, edges, order } = responseJson;

    const nodesIds = (nodes as Node[]).map((node) => node.id);
    const edgesIds = (edges as Edge[]).map((edge) => edge.id);

    const planEdgesIds = filteredNotConnectedEdges.map((edge) => edge.id);

    const newNodes = planNodes.map((node) => {
      if (nodesIds.includes(node.id)) {
        return (nodes as Node[]).find((n) => n.id === node.id) as Node;
      }
      return node;
    });

    const newOrder = order.map((res: any) => ({
      routineId: res.routine_id,
      type: res.type,
      innerTabOrderId: res.inner_tab_order_id,
      innerTabId: res.inner_tab_id,
      taskId: res.task_id,
      order: res.order,
      subOrder: res.sub_order,
    }));

    const newEdges = filteredNotConnectedEdges.map((edge) => {
      if (edgesIds.includes(edge.id)) {
        return (edges as Edge[]).find((e) => e.id === edge.id) as Edge;
      }
      return edge;
    });
    const notIncludedEdges = (edges as Edge[]).filter(
      (edge) => !planEdgesIds.includes(edge.id)
    );
    const newEdgesWithNotIncluded = [...newEdges, ...notIncludedEdges];

    set(setInnerTabDataAtom, {
      innerTabId,
      nodes: newNodes,
      edges: newEdgesWithNotIncluded,
    });
    set(ganttChartOrderTasksAtom, { innerTabId, order: newOrder });
    set(snackbarAtom, {
      open: true,
      message: t("plan.contents.routine.snackbar.editTaskSuccess"),
      severity: "success",
    });
    const sidebarData = sidebar[sidebar.length - 1];
    if (sidebarData.type === "ganttEditTask") {
      set(clearRoutineSidebarDataAtom);
    } else {
      const newNode = newNodes.find((node) => node.id === editTaskData.id);
      if (!newNode) return;
      set(popAndChangeKeySidebarDataAtom, newNode);
    }
  }
);
