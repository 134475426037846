import { atom } from "jotai";
import { Edge, Node } from "@xyflow/react";

export interface MindmapProps {
  id: number;
  name: string;
}

export const defaultInnerTabNodesAtom = atom<Node[]>([]);
export const firstGetNodeStateAtom = atom<boolean>(true);
export const typeAtom = atom<string | null>(null);
export const selectedNodeAtom = atom<Node | null>(null);
export const selectedEdgeAtom = atom<Edge | null>(null);
export const careerPathAtom = atom<{ id: number, path: string[] } | null>(null);
export const currentZoomAtom = atom<number>(0.4);

export interface CurriculumNodeState {
  id: string;
  visible: boolean;
}

export interface CurriculumNodeProps {
  innerTabId: number;
  state: CurriculumNodeState[];
  notVisibleNodes: Node[];
}

export const curriculumNodeAtom = atom<CurriculumNodeProps[]>([]);

interface CreateNewNodeGroupModalAtomProps {
  node: Node;
  isLoading: boolean;
}

export const createNewNodeGroupModalAtom = atom<CreateNewNodeGroupModalAtomProps | null>(null);
