import { settingsSidebarOpenAtom } from "@/Atoms/Settings";
import {
  getUserEmailAtom,
  getUserImage,
  getUserNickname,
  getUserPosition,
  userLanguageAtom,
  userMembershipEndDateAtom,
} from "@/ViewModels/UserViewModel";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Divider } from "@mui/material";
import { settingsUpdateUserImageAtom } from "@/ViewModels/Settings/ViewModel";
import { useTranslation } from "react-i18next";
import * as S from "./styled";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import { settingsEditProfileDialogOpenAtom } from "@/ViewModels/Settings/ProfileViewModel";
import dayjs from "dayjs";
import { ResetPasswordModalOpenAtom } from "@/ViewModels/Settings/ResetPasswordViewModel";
import {
  getUserGroupNameAtom,
  isUserGroupAtom,
} from "@/ViewModels/User/GroupViewModel";

const SettingsProfile = () => {
  const { t } = useTranslation();
  const membershipEndDate = useAtomValue(userMembershipEndDateAtom);
  const language = useAtomValue(userLanguageAtom);
  const profileImage = useAtomValue(getUserImage);
  const nickname = useAtomValue(getUserNickname);
  const email = useAtomValue(getUserEmailAtom);
  const position = useAtomValue(getUserPosition);
  const openSidebar = useAtomValue(settingsSidebarOpenAtom);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const uploadImage = useSetAtom(settingsUpdateUserImageAtom);
  const openEditProfileDialog = useSetAtom(settingsEditProfileDialogOpenAtom);
  const openResetPassword = useSetAtom(ResetPasswordModalOpenAtom);
  const userGroupName = useAtomValue(getUserGroupNameAtom);
  const isUserGroup = useAtomValue(isUserGroupAtom);

  const elements: {
    title: string;
    value: string;
    onClick?: () => void;
    type?: "edit" | "extend" | "reset" | null;
  }[] = [
    {
      title: "settings.contents.profile.nickname",
      value: nickname,
      onClick: () => openEditProfileDialog("nickname"),
      type: "edit",
    },
    {
      title: "settings.contents.profile.language",
      value: t(`settings.contents.profile.${language ? language : "KR"}`),
      onClick: () => openEditProfileDialog("language"),
      type: "edit",
    },
    { title: "settings.contents.profile.email", value: email },
    {
      title: "settings.contents.profile.membership",
      value: t(`settings.contents.profile.membershipType.${position}`),
    },
    {
      title: t("settings.contents.profile.group"),
      value: userGroupName,
      onClick: () => {},
      // type: "reset"
    },
    {
      title: "settings.contents.profile.membershipEndDate",
      value: dayjs(membershipEndDate).format("YYYY-MM-DD"),
      onClick: () => (window.location.href = "https://pay.dokgabi.ai"),
      type: position === "멘티" && isUserGroup ? null : "extend",
    },
    {
      title: t("settings.contents.profile.resetPassword"),
      value: "",
      onClick: () => openResetPassword(),
      type: "reset",
    },
  ];

  return (
    <S.Container $openSidebar={openSidebar}>
      <S.Title>{t("settings.contents.profile.title")}</S.Title>
      <S.InnerContainer>
        <S.ProfileImageContainer>
          <S.ProfileImage src={profileImage} alt="profile" />
          <input
            ref={inputRef}
            accept="image/*"
            type="file"
            id="select-image"
            style={{ display: "none", objectFit: "cover" }}
            onChange={(e) => uploadImage(e, t)}
          />
          <label htmlFor="select-image">
            <S.EditButtonContainer>
              <EditIcon sx={S.EditButtonStyle} />
            </S.EditButtonContainer>
          </label>
        </S.ProfileImageContainer>
        <Divider sx={{ width: "100%" }} />
        <div
          style={{
            flex: 1,
            overflowY: "auto",
            width: "95%",
            overflowX: "hidden",
          }}
        >
          {elements.map((element, index) => (
            <React.Fragment key={index}>
              <S.ContentRow>
                <S.ContentTitle>{t(element.title)}</S.ContentTitle>
                <S.ContentValue>{element.value}</S.ContentValue>
                {element.onClick &&
                  element.type &&
                  (element.type === "edit" ? (
                    <S.ContentButton>
                      <CustomBackgroundColorButton
                        text={t("settings.contents.profile.buttons.edit")}
                        fontSize="0.8rem"
                        onClick={element.onClick}
                      />
                    </S.ContentButton>
                  ) : element.type === "extend" ? (
                    <S.ContentButton>
                      <CustomBackgroundColorButton
                        text={t("settings.contents.profile.buttons.extend")}
                        fontSize="0.8rem"
                        onClick={element.onClick}
                      />
                    </S.ContentButton>
                  ) : (
                    element.type === "reset" && (
                      <S.ContentButton>
                        <CustomBackgroundColorButton
                          text={t("settings.contents.profile.buttons.reset")}
                          fontSize="0.8rem"
                          onClick={element.onClick}
                        />
                      </S.ContentButton>
                    )
                  ))}
              </S.ContentRow>
              <Divider sx={{ width: "100%" }} />
            </React.Fragment>
          ))}
        </div>
      </S.InnerContainer>
    </S.Container>
  );
};

export default SettingsProfile;
