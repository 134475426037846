import { useQuery } from "react-query";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { getPlanHipCrews, parseCrewInPlanHip } from "@/Queries/PlanHipQueries";
import { useHelperAtom, usePlanHipAtom } from "@/Atoms/HookControllerAtom";
import planHipAtom from "@/Atoms/PlanHip/Atom";
import { getUserId } from "@/ViewModels/UserViewModel";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { useNavigate } from "react-router-dom";
import { drawerOpenAtom } from "@/Atoms/DrawerAtom";

const useHelper = () => {
  const [controller, setController] = useAtom(useHelperAtom);
  const setPlanHip = useSetAtom(planHipAtom);
  const userId = useAtomValue(getUserId);
  const setError401Modal = useSetAtom(error401ModalAtom);
  const navigate = useNavigate();
  const setDrawerOpen = useSetAtom(drawerOpenAtom);

  return useQuery(
    ["planHipCrews"],
    () =>
      handleReactQueryApiResponse(
        getPlanHipCrews,
        () => setError401Modal(true),
        userId,
        0
      ),
    {
      enabled: controller,
      onSuccess: async (data) => {
        if (!data.ok) return;
        const jsonedData = await data.json();
        setController(false);
        const crews = parseCrewInPlanHip(jsonedData.user_active_crews);
        // setPlanHip((prev) => ({
        //   ...prev,
        //   myCrews: parseCrewInPlanHip(data.user_active_crews),
        //   notMyCrews: parseCrewInPlanHip(data.user_inactive_crews),
        // }));
        const crew_id = GetIdFromQuerystring("crew_id");
        const inner_tab_id = GetIdFromQuerystring("inner_tab_id");

        if (!crew_id || !inner_tab_id) {
          navigate("/plan-hip");
          return;
        }
        const crew = crews.find((crew) => crew.crewId === crew_id);
        if (!crew) {
          navigate("/plan-hip");
          return;
        }
        const inner_tab = crews.find((tab) => tab.innerTabId === inner_tab_id);
        if (!inner_tab) {
          navigate("/plan-hip");
          return;
        }
        setDrawerOpen(true);
      },
    }
  );
};

export default useHelper;
