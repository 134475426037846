// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fc .fc-toolbar.fc-header-toolbar {
  margin-top: 15px;
  padding: 10px 20px;

  font-size: 9px;
  background-color: #06c25e;
  border-radius: 30px;
}

/* .fc .fc-button {
  background-color: transparent;
  color: #06c25e;
  border: none;
}

.fc .fc-button:hover {
  background-color: transparent;
}

.fc .fc-button-primary:active:focus {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #06c25e !important;
  border-color: transparent !important;
}


.fc .fc-today-button:disabled {
  background-color: transparent;
  color: #06c25e;
}

.fc-daygrid-day-frame {
  min-height: 100px; 
} 
*/
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Mentee/Calendar/styled.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;;EAElB,cAAc;EACd,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;CA2BC","sourcesContent":[".fc .fc-toolbar.fc-header-toolbar {\r\n  margin-top: 15px;\r\n  padding: 10px 20px;\r\n\r\n  font-size: 9px;\r\n  background-color: #06c25e;\r\n  border-radius: 30px;\r\n}\r\n\r\n/* .fc .fc-button {\r\n  background-color: transparent;\r\n  color: #06c25e;\r\n  border: none;\r\n}\r\n\r\n.fc .fc-button:hover {\r\n  background-color: transparent;\r\n}\r\n\r\n.fc .fc-button-primary:active:focus {\r\n  border: none !important;\r\n  box-shadow: none !important;\r\n  background-color: transparent !important;\r\n  color: #06c25e !important;\r\n  border-color: transparent !important;\r\n}\r\n\r\n\r\n.fc .fc-today-button:disabled {\r\n  background-color: transparent;\r\n  color: #06c25e;\r\n}\r\n\r\n.fc-daygrid-day-frame {\r\n  min-height: 100px; \r\n} \r\n*/\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
