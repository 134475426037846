import React from "react";
import * as S from "./styled";
import FullCalendar from "@fullcalendar/react";
import { useAtomValue, useSetAtom } from "jotai";
import {
  routineDateModeAtom,
  routineHeaderModeAtom,
  routineViewModeAtom,
} from "@/Atoms/Plan/RoutineAtom";
import RoutineHeader from "./Header";
import RoutineList from "./RoutineList";
import RoutineSidebar from "./Sidebar";
import MonthlyCalendar from "./Contents/MonthlyCalendar";
import DailyCalendar from "./Contents/DailyCalendar";
import "./styles.css";
import WeeklyCalendar from "./Contents/WeeklyCalendar";
import GanttChart from "./Contents/GanttChart";
import { setGanttChartOrderTasksAtom } from "@/ViewModels/Plan/Routine/GanttChartViewModel";

const Routine = () => {
  const viewMode = useAtomValue(routineViewModeAtom);
  const dateMode = useAtomValue(routineDateModeAtom);
  const calendarRef = React.useRef<FullCalendar>(null);
  const [currentWidth, setCurrentWidth] = React.useState(window.innerWidth);
  const [calendarHeight, setCalendarHeight] = React.useState(700);
  const headerMode = useAtomValue(routineHeaderModeAtom);
  const getGanttChartTaskOrder = useSetAtom(setGanttChartOrderTasksAtom);

  React.useEffect(() => {
    if (calendarRef.current) {
      const view =
        dateMode === "Month"
          ? "dayGridMonth"
          : dateMode === "Week"
          ? "timeGridWeek"
          : "timeGridDay";
      calendarRef.current.getApi().changeView(view);
    }
  }, [dateMode]);

  // 헤더와 사이드바의 높이 계산
  React.useEffect(() => {
    const calculateHeight = () => {
      const containerElement = document.querySelector(".routine-container");
      const headerElement = document.querySelector(".routine-header");
      if (containerElement && headerElement) {
        setCurrentWidth(containerElement.clientWidth - 40);
        setCalendarHeight(
          containerElement.clientHeight - headerElement.clientHeight - 60
        );
      }
    };

    calculateHeight();
    getGanttChartTaskOrder();
    window.addEventListener("resize", calculateHeight);
    return () => window.removeEventListener("resize", calculateHeight);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const containerElement = document.querySelector(".routine-container");
    const headerElement = document.querySelector(".routine-header");
    if (containerElement && headerElement) {
      setCalendarHeight(
        containerElement.clientHeight - headerElement.clientHeight - 60
      );
    }
  }, [dateMode]);

  return (
    // <S.Container $isCalendar={viewMode === "calendar"}>
    <S.Container
      $isCalendar={true}
      $dateMode={dateMode}
      className="routine-container"
    >
      <RoutineHeader calendarRef={calendarRef} />
      {headerMode === "small" && <RoutineList width={currentWidth} />}
      {viewMode === "calendar" && (
        <div style={{ width: "100%" }}>
          {dateMode === "Month" && (
            <MonthlyCalendar calendarRef={calendarRef} />
          )}
          {dateMode === "Week" && (
            <WeeklyCalendar
              calendarRef={calendarRef}
              calendarHeight={calendarHeight}
            />
          )}
          {dateMode === "Day" && (
            <DailyCalendar
              calendarRef={calendarRef}
              calendarHeight={calendarHeight}
            />
          )}
        </div>
      )}
      {viewMode === "gantt" && <GanttChart />}

      <RoutineSidebar />

      {/* 챗봇 버튼 때문에 아래 공간 필요할 듯 */}
      {viewMode === "calendar" && (
        <div style={{ width: "100px", minHeight: "100px" }} />
      )}
    </S.Container>
  );
};

export default Routine;
