import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import React from "react";
import { getMonthlyCalendarDataAtom } from "@/ViewModels/Plan/Routine/DataViewModel";
import { useAtomValue, useSetAtom } from "jotai";
import { pushRoutineSidebarDataAtom } from "@/ViewModels/Plan/Routine/SidebarViewModel";
import { Node } from "@xyflow/react";
import { TaskStatus } from "@/Types/Plan";
import dayjs from "dayjs";
import { CheckCircle } from "@mui/icons-material";
import * as S from "./styled";
import theme from "@/Styles/theme";
import koLocale from "@fullcalendar/core/locales/ko";
import enLocale from "@fullcalendar/core/locales/en-gb";
import { userLanguageAtom } from "@/ViewModels/UserViewModel";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { devConsoleLog } from "@/Utils/ConsoleLogInDevelopment";

interface MonthlyCalendarProps {
  calendarRef: React.RefObject<FullCalendar>;
}

const MonthlyCalendar = ({ calendarRef }: MonthlyCalendarProps) => {
  const calendarEvents = useAtomValue(getMonthlyCalendarDataAtom);
  const openSidebar = useSetAtom(pushRoutineSidebarDataAtom);
  const userLanguage = useAtomValue(userLanguageAtom);

  const handleDetailClick = (task: Node, date: string) => {
    const taskStatus = task.data.taskStatus as TaskStatus[];
    const dateTaskStatus = taskStatus.find(
      (status) => status.date === dayjs(date).format("YYYY-MM-DD")
    );

    openSidebar({
      type: "task",
      key: task,
      tasks: [],
      taskStatus: dateTaskStatus,
    });
  };

  const handleDateClick = (info: DateClickArg) => {
    alert(`You clicked on ${info.dateStr}`);
  };

  devConsoleLog(calendarEvents);

  return (
    <FullCalendar
      ref={calendarRef}
      initialView="dayGridMonth"
      plugins={[dayGridPlugin, interactionPlugin]}
      events={calendarEvents}
      dateClick={handleDateClick}
      eventClick={(info) =>
        handleDetailClick(
          info.event.extendedProps.task as Node,
          info.event.extendedProps.date
        )
      }
      dayMaxEvents={true}
      dayMaxEventRows={1}
      headerToolbar={false}
      locale={userLanguage === "KR" ? koLocale : enLocale}
      editable={true}
      selectable={true}
      selectMirror={true}
      select={(info) => {}}
      // moreLinkClick={(info) => {
      //   console.log("More button clicked", info);

      //   const { clientX, clientY } = info.jsEvent as PointerEvent;

      //   // MutationObserver를 통해 .fc-more-popover 요소가 렌더링될 때 스타일 적용
      //   const observer = new MutationObserver((mutationsList) => {
      //     for (const mutation of mutationsList) {
      //       if (mutation.type === "childList") {
      //         const morePopover = document.querySelector(".fc-more-popover") as HTMLElement;
      //         if (morePopover) {
      //           observer.disconnect(); // 팝오버를 찾으면 감시 종료
      //           console.log("More popover element", morePopover);

      //           morePopover.style.left = `600px`;
      //           morePopover.style.top = `300px`;
      //           morePopover.style.position = "absolute"; // 팝오버의 위치를 절대값으로 설정
      //           morePopover.style.transform = "none"; // 기본 FullCalendar의 transform offset 제거
      //         }
      //       }
      //     }
      //   });

      //   // .fc의 DOM 변화를 감시
      //   const calendarContainer = document.querySelector(".fc");
      //   if (calendarContainer) {
      //     observer.observe(calendarContainer, { childList: true, subtree: true });
      //   }

      //   return "popover"; // "popover"를 반환하면 기본 FullCalendar 동작 유지
      // }}
      eventClassNames={(arg) => {
        if (arg.event.start && arg.event.end) {
          // 긴 이벤트인 경우 클래스 추가
          return ["fc-long-event"];
        }
        return [];
      }}
      eventContent={(eventInfo) => {
        const { colors, task, date } = eventInfo.event.extendedProps;
        const taskStatus = task.data.taskStatus as TaskStatus[];
        const dateTaskStatus = taskStatus.find(
          (status) => status.date === dayjs(date).format("YYYY-MM-DD")
        );
        const isCompleted = dateTaskStatus?.status === 1;

        return (
          <S.CalenderEvent>
            {/* 동그라미 컬러 */}
            {colors.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {(colors as string[]).slice(0, 3).map((color, index) => (
                  <div
                    key={index}
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: color,
                      borderRadius: "50%",
                      marginLeft: index === 0 ? 0 : "-6px",
                    }}
                  ></div>
                ))}
                {(colors as string[]).length > 3 && (
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "gray",
                      borderRadius: "50%",
                      marginLeft: "-6px",
                      fontSize: "6px",
                    }}
                  >
                    +{(colors as string[]).length - 3}
                  </div>
                )}
              </div>
            )}
            {/* 타이틀 */}
            <div
              style={{
                flex: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {eventInfo.event.title}
            </div>
            {isCompleted && (
              <CheckCircle
                sx={{
                  color: theme.colors.primary,
                  width: "10px",
                  height: "10px",
                }}
              />
            )}
          </S.CalenderEvent>
        );
      }}
    />
  );
};

export default MonthlyCalendar;
