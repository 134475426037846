import React, { useEffect, useRef, useState } from "react";
import * as S from "./styled";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Input,
  LinearProgress,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  Handle,
  HandleType,
  NodeToolbar,
  Position,
  useUpdateNodeInternals,
} from "@xyflow/react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ko";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import theme from "../../../../../../Styles/theme";
import { togetherDialogAtom } from "@/Atoms/Dialogs/Together";
import { editRoutineIntervalsDialogAtom } from "@/Atoms/Dialogs/Edit/RoutineOption";
import getRemainingDays from "@/Utils/GetRemainingDays";
import getProgressPercentage from "@/Utils/GetProgressPercentage";
import {
  careerPathAtom,
  createNewNodeGroupModalAtom,
  selectedNodeAtom,
} from "@/Atoms/Plan/MindmapAtom";
import { detachNodeInGroupAtom } from "@/ViewModels/Plan/Mindmap/GroupViewModel";
import { updateNodeAtom } from "@/ViewModels/Plan/Mindmap/FlowViewModel";
import inactivateDialogAtom from "@/Atoms/Dialogs/Inactivate";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import CustomBackgroundColorButton from "@/Components/Common/Buttons/CustomBackgroundColor";
import getRandomColorWithText from "@/Utils/GetRandomColor";
import {
  addHashtagNodeAtom,
  removeHashtagNodeAtom,
  updateTaskStatusNodeAtom,
} from "@/ViewModels/Plan/Mindmap/JobNodeViewModel";
import TextMarkdown from "@/Components/Common/Markdown";
import { languageAtom } from "@/Atoms/RootAtom";
import { textEditorOpenAtom } from "@/ViewModels/Dialogs/TextEditorViewModel";
import useIsMobile from "@/Hooks/useIsMobile";
import { checkPlanUserIdAtom } from "@/ViewModels/Plan/PlanViewModel";
import * as C from "./container";
import {
  CalendarTodayOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { deleteTaskAtom } from "@/ViewModels/Plan/Mindmap/ViewModel";
import { useTranslation } from "react-i18next";
import { isExistParentNodeTypeGroupAtom } from "@/ViewModels/Plan/Mindmap/Modals/CreateNewNodeGroupViewModel";

const formatDateToKorean2 = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 +1
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}년 ${month}월 ${day}일`;
};

const TaskNode = (data: any) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const contentRef = useRef<HTMLDivElement>(null); // 확장된 높이를 참조할 수 있는 useRef
  const updateNodeInternals = useUpdateNodeInternals();
  const detachNodeInGroup = useSetAtom(detachNodeInGroupAtom);
  const [, updateNode] = useAtom(updateNodeAtom);
  const language = useAtomValue(languageAtom);
  const selectedNode = useAtomValue(selectedNodeAtom);
  const careerPath = useAtomValue(careerPathAtom);
  const type = data.type;
  const setEditRoutineDialog = useSetAtom(editRoutineIntervalsDialogAtom);
  const setTogetherDialog = useSetAtom(togetherDialogAtom);
  const setInactivateDialog = useSetAtom(inactivateDialogAtom);
  const openTextEditor = useSetAtom(textEditorOpenAtom);
  const [inputValue, setInputValue] = useState("");
  const addHashtag = useSetAtom(addHashtagNodeAtom);
  const removeHashtag = useSetAtom(removeHashtagNodeAtom);
  const updateStatus = useSetAtom(updateTaskStatusNodeAtom);
  const today = dayjs().format("YYYY-MM-DD");
  const isExistTodayStatus =
    data.data.taskStatus.length !== 0 &&
    data.data.taskStatus.find((status: any) => status.date === today);
  const todayStatus = isExistTodayStatus
    ? data.data.taskStatus.find((status: any) => status.date === today)
    : null;
  const handleDeleteNode = useSetAtom(deleteTaskAtom);
  const isMine = useAtomValue(checkPlanUserIdAtom);
  const isExpanded = selectedNode?.id === data.id || data.data.isNewCreated;
  const openCreateNewNodeGroupModal = useSetAtom(createNewNodeGroupModalAtom);
  const isExistNodeTypeGroup = useAtomValue(isExistParentNodeTypeGroupAtom)(
    data
  );

  // 엔터키 입력 시 태그 추가
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault();
      const { backgroundColor, textColor } = getRandomColorWithText();
      if (
        !data.data.hashtags.find(
          (tag: {
            backgroundColor: string;
            textColor: string;
            value: string;
          }) => tag.value === inputValue.trim()
        )
      ) {
        addHashtag(data, {
          backgroundColor,
          textColor,
          value: inputValue.trim(),
        });
      }
      setInputValue(""); // 입력창 초기화
    } else if (event.key === "Backspace" && inputValue === "") {
      if (data.data.hashtags.length > 0) {
        event.preventDefault();
        removeHashtag(data);
      }
    }
  };

  const [localLabel, setLocalLabel] = useState(data.data.label);
  const [localLocation, setLocalLocation] = useState(
    data.data.location.address
  );

  useEffect(() => {
    updateNode({
      ...data.data,
      label: localLabel,
    });
  }, [localLabel]);

  useEffect(() => {
    updateNode({
      ...data.data,
      location: {
        ...data.data.location,
        address: localLocation,
      },
    });
  }, [localLocation]);

  const handleUpdateNode = (key: keyof typeof data.data, value: any) => {
    setLocalLabel(value);
  };

  const handleLocationUpdate = (address: string) => {
    setLocalLocation(address);
  };

  const handleDateUpdate = (date: Dayjs | null, type: "start" | "end") => {
    if (type === "start") {
      let termData = data.data.termData;

      const tempDate = dayjs(date?.toISOString()).locale("ko");

      if (data.data.termType === 1) {
        const weekday = tempDate.format("ddd");
        termData = [weekday];
      } else if (data.data.termType === 2) {
        const dayOfMonth = tempDate.format("D");
        termData = [dayOfMonth];
      }

      const updatedNode = {
        ...data.data,
        startDate: date ? date?.toISOString() : null,
        termData: date ? termData : null,
      };

      updateNode(updatedNode);
    } else if (type === "end") {
      const updatedNode = {
        ...data.data,
        endDate: date ? date?.toISOString() : null,
        // termData: termData,
      };

      updateNode(updatedNode);
    }
  };

  // 경로에 있는지 여부에 따른 활성/비활성 스타일 결정
  const isPathActive = careerPath?.path.includes(data.id);
  const isCareerPathNull = careerPath === null;

  const handleDetachNodeInGroup = (e: React.MouseEvent) => {
    e.stopPropagation();
    detachNodeInGroup(data.id);
  };

  const setRoutineOption = (key: number) => {
    let updatedNode;

    if (data.data.termType === key) {
      updatedNode = {
        ...data.data,
        termType: null,
        termData: null,
      };
    } else {
      let termData = null;

      if (data.data.startDate) {
        const date = dayjs(data.data.startDate).locale("ko");

        if (key === 1) {
          const weekday = date.format("ddd");
          termData = [weekday];
        } else if (key === 2) {
          const dayOfMonth = date.format("D");
          termData = [dayOfMonth];
        }
      }

      updatedNode = {
        ...data.data,
        termType: key,
        termData: termData,
      };
    }

    updateNode(updatedNode);
  };

  return (
    data.data.activate !== 2 && (
      <>
        <C.NodeToolbars data={data} />
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            transition: "filter 0.5s ease", // 어두워지는 효과
            filter:
              isCareerPathNull || isPathActive ? "none" : "brightness(50%)", // 경로에 없으면 어둡게
          }}
          onClick={(e) => {
            if (selectedNode?.id === data.id) {
              e.stopPropagation();
            }
          }}
        >
          <S.Container
            id={`${data.id}`}
            $isActivate={data.data.activate === 1}
            $isSelecting={selectedNode?.id === data.id}
            $color={theme.colors[type]}
            style={{
              transition: "max-height 0.5s ease", // 애니메이션 추가
              // maxHeight: maxHeight, // 동적 max-height 설정
              overflow: "hidden", // 넘치는 내용 숨기기
            }}
            ref={contentRef} // contentRef를 참조
          >
            <Stack
              direction={"row"}
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    background: theme.colors[data.type],
                    padding: "5px 10px",
                    color: "white",
                    borderRadius: "20px",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  {t(`plan.contents.mindmap.nodes.job.typeLabel.${data.type}`)}
                </Box>
                {!isExistNodeTypeGroup && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      openCreateNewNodeGroupModal({
                        node: data,
                        isLoading: false,
                      });
                    }}
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      padding: "0px",
                      color: theme.colors.primary,
                    }}
                  >
                    {t("plan.contents.mindmap.nodes.createNewNodeGroup")}
                  </Button>
                )}
              </div>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setInactivateDialog({ open: true, node: data });
                }}
              >
                {data.data.activate === 1 ? (
                  <VisibilityOffOutlined
                    sx={{ width: "30px", height: "30px" }}
                  />
                ) : (
                  <VisibilityOutlined sx={{ width: "30px", height: "30px" }} />
                )}
              </IconButton>
            </Stack>
            {/* 핸들: source/target을 동적으로 설정 */}
            {C.handlePositions(isMobile).map((handle) =>
              ["source", "target"].map((type) => (
                <Handle
                  key={`${data.id}-${handle.id}-${type}`}
                  type={type as HandleType}
                  position={handle.position}
                  id={`${data.id}-${handle.id}-${type}`}
                  className="handle"
                  style={{
                    ...handle.additionalStyle,
                    backgroundColor:
                      selectedNode && selectedNode.id === data.id
                        ? "#d9d9d9"
                        : "transparent",
                  }}
                />
              ))
            )}
            {isMine && isExpanded ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Input
                    value={localLabel}
                    onChange={(e) => handleUpdateNode("label", e.target.value)}
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "1.3rem",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  {t("plan.contents.mindmap.nodes.job.setDate")}
                </div>
                <DatePicker
                  label={t("plan.contents.mindmap.nodes.job.startDate")}
                  sx={{ width: "100%" }}
                  value={dayjs(data.data.startDate)}
                  onChange={(date) => handleDateUpdate(date, "start")}
                  shouldDisableDate={
                    data.data.endDate
                      ? (day) => day.isAfter(data.data.endDate)
                      : undefined
                  }
                  format="YYYY.MM.DD"
                />
                <DatePicker
                  label={t("plan.contents.mindmap.nodes.job.endDate")}
                  sx={{ width: "100%" }}
                  value={dayjs(data.data.endDate)}
                  onChange={(date) => handleDateUpdate(date, "end")}
                  shouldDisableDate={
                    data.data.startDate
                      ? (day) => day.isBefore(data.data.startDate)
                      : undefined
                  }
                  format="YYYY.MM.DD"
                />
                <S.ContentContainer
                  onClick={() =>
                    openTextEditor(data.id, data.data.content as string)
                  }
                >
                  {data.data.content === "" ? (
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {t("plan.contents.mindmap.nodes.job.content")}
                    </Typography>
                  ) : (
                    <TextMarkdown text={data.data.content as string} />
                  )}
                </S.ContentContainer>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ width: "20%", fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("plan.contents.mindmap.nodes.job.routine.title")}
                  </Typography>
                  <ToggleButtonGroup
                    exclusive
                    value={data.data.termType}
                    onChange={(event, newViewType) =>
                      setRoutineOption(newViewType)
                    }
                    color="primary"
                  >
                    <ToggleButton
                      value={0}
                      selected={data.data.termType === 0}
                      onClick={() => setRoutineOption(0)}
                    >
                      {t("plan.contents.mindmap.nodes.job.routine.everyday")}
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      selected={data.data.termType === 1}
                      onClick={() => setRoutineOption(1)}
                    >
                      {t("plan.contents.mindmap.nodes.job.routine.everyweek")}
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      selected={data.data.termType === 2}
                      onClick={() => setRoutineOption(2)}
                    >
                      {t("plan.contents.mindmap.nodes.job.routine.everymonth")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                {(data.data.termType === 1 || data.data.termType === 2) && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                        {t("plan.contents.mindmap.nodes.job.repeat")}
                      </Typography>
                      <CustomBackgroundColorButton
                        text={t("plan.contents.mindmap.nodes.job.buttons.edit")}
                        onClick={() =>
                          setEditRoutineDialog({
                            open: data.id,
                            termType: data.data.termType,
                            termData: data.data.termData,
                          })
                        }
                        fontSize="1rem"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                        {data.data.termType === 1
                          ? t(
                              "plan.contents.mindmap.nodes.job.routine.everyweek"
                            )
                          : t(
                              "plan.contents.mindmap.nodes.job.routine.everymonth"
                            )}
                      </Typography> */}
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: `repeat(${
                            data.data.termData && data.data.termData.length < 7
                              ? data.data.termData.length
                              : 7
                          }, 1fr)`,
                          gap: "5px",
                        }}
                      >
                        {data.data.termData?.map((interval: string) => (
                          <Typography
                            key={interval}
                            sx={{ fontSize: "1rem", fontWeight: "bold" }}
                          >
                            {interval}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </>
                )}

                <TextField
                  id="outlined-basic"
                  label={t("plan.contents.mindmap.nodes.job.location")}
                  variant="outlined"
                  value={localLocation ?? ""}
                  onChange={(e) => handleLocationUpdate(e.target.value)}
                  style={{ width: "100%" }}
                />

                <S.TagInputContainer>
                  {data.data.hashtags.map(
                    (
                      tag: {
                        backgroundColor: string;
                        textColor: string;
                        value: string;
                      },
                      idx: number
                    ) => {
                      return (
                        <S.TagItem
                          key={idx}
                          style={{
                            backgroundColor: tag.backgroundColor,
                            color: tag.textColor,
                          }}
                        >
                          <span>{tag.value}</span>
                        </S.TagItem>
                      );
                    }
                  )}
                  <S.TagInput
                    style={{ backgroundColor: "transparent" }}
                    placeholder={
                      data.data.hashtags.length === 0
                        ? t("plan.contents.mindmap.nodes.job.hashtag")
                        : ""
                    }
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </S.TagInputContainer>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomColorButton
                    text={t("plan.contents.mindmap.nodes.job.together")}
                    onClick={() => setTogetherDialog({ node: data })}
                    fontSize="1.2rem"
                  />
                </div>
              </>
            ) : !isMine && selectedNode?.id === data.id ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{ fontSize: "1.2rem", textAlign: "start" }}
                  >
                    {data.data.label}
                  </Typography>
                </div>

                {(data.data.startDate as string) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <CalendarTodayOutlined sx={{ fontSize: "1rem" }} />
                    <Typography style={{ fontSize: "0.8rem" }}>
                      {formatDateToKorean2(
                        new Date(data.data.startDate as string)
                      )}
                      부터
                    </Typography>
                  </div>
                )}
                {(data.data.endDate as string) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <CalendarTodayOutlined sx={{ fontSize: "1rem" }} />
                    <Typography style={{ fontSize: "0.8rem" }}>
                      {formatDateToKorean2(
                        new Date(data.data.endDate as string)
                      )}
                      까지
                    </Typography>
                  </div>
                )}
                {data.data.termType !== null && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "20%",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      루틴 간격
                    </Typography>
                    <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                      {data.data.termType === 0
                        ? "매일"
                        : data.data.termType === 1
                        ? "매주"
                        : "매월"}
                    </Typography>
                  </Box>
                )}

                {(data.data.termType === 1 || data.data.termType === 2) && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                        반복
                      </Typography>
                      <CustomBackgroundColorButton
                        text={language === "KR" ? "수정" : "edit"}
                        onClick={() =>
                          setEditRoutineDialog({
                            open: data.id,
                            termType: data.data.termType,
                            termData: data.data.termData,
                          })
                        }
                        fontSize="1rem"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                        {data.data.termType === 1 ? "매주" : "매월"}
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: `repeat(${
                            data.data.termData && data.data.termData.length < 7
                              ? data.data.termData.length
                              : 7
                          }, 1fr)`,
                          gap: "5px",
                        }}
                      >
                        {data.data.termData?.map((interval: string) => (
                          <Typography
                            key={interval}
                            sx={{ fontSize: "1rem", fontWeight: "bold" }}
                          >
                            {interval}
                            {data.data.termType === 1 ? "" : "일"}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{ fontSize: "1.2rem", textAlign: "start" }}
                  >
                    {data.data.label}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    fontSize: "1rem",
                    justifyContent: isExistTodayStatus
                      ? "space-between"
                      : "flex-end",
                    color: `${
                      !data.data.startDate || !data.data.endDate
                        ? "black"
                        : theme.colors.primary
                    }`,
                    fontWeight: "bold",
                  }}
                >
                  <div style={{ color: "black" }}>
                    {isExistTodayStatus
                      ? todayStatus?.status === 1
                        ? t("plan.contents.mindmap.nodes.job.buttons.done")
                        : t("plan.contents.mindmap.nodes.job.buttons.wait")
                      : ""}
                  </div>
                  <div>
                    {!data.data.startDate || !data.data.endDate
                      ? t("plan.contents.mindmap.nodes.job.noSetDate")
                      : getRemainingDays(
                          data.data.startDate,
                          data.data.endDate
                        )}
                  </div>
                </div>
                <div style={{ marginTop: "10px", width: "100%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={getProgressPercentage(
                      data.data.startDate,
                      data.data.endDate
                    )}
                    sx={{
                      height: "20px",
                      borderRadius: "5px",
                      backgroundColor: "#e0e0e0",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: theme.colors.primary, // 커스텀 색상 적용
                      },
                    }}
                  />
                </div>
              </>
            )}
          </S.Container>
        </Stack>
      </>
    )
  );
};

export default TaskNode;
