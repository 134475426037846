import { useQuery } from "react-query";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  getUserId,
  getUserPosition,
  userLanguageAtom,
} from "@/ViewModels/UserViewModel";
import {
  useMenteeDashboardAtom,
  useRootAtom,
} from "@/Atoms/HookControllerAtom";
import {
  getCareerSearch,
  getContentSearch,
  getJobSearch,
  getMenteeDashboardData,
} from "@/Queries/DashboardQueries";
import {
  dashboardMenteeCareerSearchAtom,
  dashboardMenteeContentSearchAtom,
  dashboardMenteeDailyPlanAtom,
  dashboardMenteeJobSearchAtom,
  dashboardMenteePlanTemplatesAtom,
} from "@/Atoms/Dashboard/MenteeAtom";
import {
  getPlanData,
  getPlans,
  getRecommendPlans,
  parseGetPlansResponse,
} from "@/Queries/PlanQueries";
import { getSignature } from "@/Queries/SignatureQueries";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { Signature, SignatureInfo } from "@/Atoms/Signature/Atom";
import { Node } from "@xyflow/react";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";

const convertInfoToObject = (
  infoArray: SignatureInfo[]
): Record<string, string> => {
  return infoArray.reduce((acc, curr) => {
    if (acc[curr.label]) {
      acc[curr.label] = `${acc[curr.label]}, ${curr.value}`;
    } else {
      acc[curr.label] = curr.value;
    }
    return acc;
  }, {} as Record<string, string>);
};

interface Keyword {
  key: string;
  value: string[];
}

const convertNewInfoToKeywords = (infoArray: SignatureInfo[]): Keyword[] => {
  const keywordMap = infoArray.reduce((acc, curr) => {
    if (acc[curr.label]) {
      acc[curr.label].push(curr.value);
    } else {
      acc[curr.label] = [curr.value];
    }
    return acc;
  }, {} as Record<string, string[]>);

  return Object.entries(keywordMap).map(([key, value]) => ({ key, value }));
};

const useMenteeDashboard = () => {
  const userId = useAtomValue(getUserId);
  const [controller, setController] = useAtom(useMenteeDashboardAtom);
  const setCareerSearch = useSetAtom(dashboardMenteeCareerSearchAtom);
  const setJobSearch = useSetAtom(dashboardMenteeJobSearchAtom);
  const setContentSearch = useSetAtom(dashboardMenteeContentSearchAtom);
  // const setMyLevel = useSetAtom(dashboardMenteeMyLevelAtom);
  const setDailyPlan = useSetAtom(dashboardMenteeDailyPlanAtom);
  const userPosition = useAtomValue(getUserPosition);
  const language = useAtomValue(userLanguageAtom);
  const setError401Modal = useSetAtom(error401ModalAtom);
  const rootController = useAtomValue(useRootAtom);
  const setPlanTemplates = useSetAtom(dashboardMenteePlanTemplatesAtom);

  const handleError401Modal = () => setError401Modal(true);

  return useQuery(
    ["menteeDashboard", userId],
    () => handleReactQueryApiResponse(getSignature, handleError401Modal),
    {
      enabled:
        controller &&
        ["멘티", "basic", "standard", "professional"].includes(userPosition) &&
        !rootController,
      onSuccess: async (data) => {
        if (!data.ok) {
          setCareerSearch({ careerSearch: [], isLoading: false });
          setJobSearch({ jobSearch: [], isLoading: false });
          setContentSearch({ contentSearch: [], isLoading: false });
          return;
        }
        setController(false);
        const jsonedData = await data.json();
        const signatureData = jsonedData.signatures as Signature[];
        const careerAdvancementSignature = signatureData.find(
          (signature) => signature.careerAdvancement
        );
        if (careerAdvancementSignature) {
          const newData = {
            country: language,
            ...convertInfoToObject(careerAdvancementSignature.info),
          };

          if (language === "KR") {
            const krData = {
              country: "KR",
              keywords: convertNewInfoToKeywords(
                careerAdvancementSignature.info
              ),
            };
            const menteeDataResponse = await handleReactQueryApiResponse(
              getMenteeDashboardData,
              handleError401Modal,
              krData
            );
            if (!menteeDataResponse.ok) {
              setCareerSearch({ careerSearch: [], isLoading: false });
              setJobSearch({ jobSearch: [], isLoading: false });
              setContentSearch({ contentSearch: [], isLoading: false });
            }
            const menteeData = await menteeDataResponse.json();
            // console.log("menteeData", menteeData);

            setCareerSearch({
              careerSearch: menteeData.career,
              isLoading: false,
            });
            setJobSearch({ jobSearch: menteeData.job, isLoading: false });
            setContentSearch({
              contentSearch: menteeData.content,
              isLoading: false,
            });
          } else {
            const careerResponse = await handleReactQueryApiResponse(
              getCareerSearch,
              handleError401Modal,
              newData
            );
            if (!careerResponse.ok) {
              setCareerSearch({ careerSearch: [], isLoading: false });
            } else {
              const careerJsonedData = await careerResponse.json();
              setCareerSearch({
                careerSearch: careerJsonedData.results,
                isLoading: false,
              });
            }

            const jobResponse = await handleReactQueryApiResponse(
              getJobSearch,
              handleError401Modal,
              newData
            );
            if (!jobResponse.ok) {
              setJobSearch({ jobSearch: [], isLoading: false });
            } else {
              const jobJsonedData = await jobResponse.json();
              setJobSearch({
                jobSearch: jobJsonedData.results,
                isLoading: false,
              });
            }

            const contentResponse = await handleReactQueryApiResponse(
              getContentSearch,
              handleError401Modal,
              newData
            );
            if (!contentResponse.ok) {
              setContentSearch({ contentSearch: [], isLoading: false });
            } else {
              const contentJsonedData = await contentResponse.json();
              setContentSearch({
                contentSearch: contentJsonedData.results,
                isLoading: false,
              });
            }
          }

          // const levelResponse = await handleReactQueryApiResponse(getMyLevel, handleError401Modal, newData);
          // if (levelResponse === null) {
          //   setMyLevel({ myLevel: { usingOurService: [], notUsing: [] }, isLoading: false });
          // } else {
          //   const levelJsonedData = await levelResponse.json();
          //   const levelData = levelJsonedData.results;
          //   const { using_our_service, not_using } = levelData;
          //   setMyLevel({ myLevel: { usingOurService: using_our_service, notUsing: not_using }, isLoading: false });
          // }
        }
        // 플랜 템플릿 불러오기
        const planTemplateResponse = await handleReactQueryApiResponse(
          getRecommendPlans,
          handleError401Modal,
          language
        );
        if (planTemplateResponse.ok) {
          const planTemplateJsonedData = await planTemplateResponse.json();
          setPlanTemplates(planTemplateJsonedData.recommended_plans);
        }

        // 일정관리에 필요한 데이터를 미리 불러옴
        const response = await handleReactQueryApiResponse(
          getPlans,
          handleError401Modal,
          userId
        );
        if (response.ok) {
          const responseJson = await response.json();
          const dailyPlanResponse = parseGetPlansResponse(userId, responseJson);
          const plans = dailyPlanResponse
            .map((res) =>
              res.innerTabs.map((innerTab, index) => ({
                planId: res.id,
                innerTabId: innerTab.id,
                name: index === 0 ? res.name : innerTab.name,
                viewType: innerTab.viewType,
              }))
            )
            .flat();
          const plansData = (
            await Promise.all(
              plans.map(async (plan) => {
                const planData = await handleReactQueryApiResponse(
                  getPlanData,
                  handleError401Modal,
                  plan.innerTabId
                );
                if (!planData.ok) {
                  return [];
                }

                const data = await planData.json();

                const groupNodes = data.nodes.filter(
                  (node: Node) => node.type === "group"
                );
                const nonGroupNodes = data.nodes.filter(
                  (node: Node) => node.type !== "group"
                );
                const nodes = [...groupNodes, ...nonGroupNodes].map(
                  (node: Node) => ({
                    ...node,
                    data: {
                      ...node.data,
                      hashtags: node.data.hashtags || [],
                      planId: plan.planId,
                      innerTabId: plan.innerTabId,
                      innerTabName: plan.name,
                      innerTabViewType: plan.viewType,
                    },
                  })
                ) as Node[];
                return nodes.filter((node) => node.data.startDate !== null);

                return nodes;
              })
            )
          ).flat();

          setDailyPlan({ dailyPlan: plansData, isLoading: false });
        } else {
          setDailyPlan({ dailyPlan: [], isLoading: false });
        }
      },
    }
  );
};

export default useMenteeDashboard;
