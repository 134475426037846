import {
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  Connection,
  addEdge,
  MarkerType,
  useStoreApi,
  useReactFlow,
  InternalNode,
  NodeRemoveChange,
  NodePositionChange,
} from "@xyflow/react";
import { atom, Getter, Setter } from "jotai";
import { v4 as uuidv4 } from "uuid";
import { editRoutineIntervalsDialogAtom } from "@/Atoms/Dialogs/Edit/RoutineOption";
import { LocationProps } from "@/Atoms/RootAtom";
import {
  careerPathAtom,
  selectedEdgeAtom,
  selectedNodeAtom,
  typeAtom,
} from "@/Atoms/Plan/MindmapAtom";
import theme from "@/Styles/theme";
import getLongestPath, { EdgeData, NodeData } from "@/Utils/GetLongestPath";
import { updatePlanDataAtom } from "@/ViewModels/Plan/Mindmap/ViewModel";
import { initializeGroupNodeAtom } from "../GroupViewModel";
import {
  createEdge,
  createTask,
  deleteEdge,
  deleteNode,
  deleteNodesAndEdges,
  updatePartialTasks,
  updateTask,
} from "@/Queries/PlanQueries";
import GetIdFromQuerystring from "@/Utils/GetIdFromQuerystring";
import { getUserId } from "@/ViewModels/UserViewModel";
import { devConsoleError } from "@/Utils/ConsoleLogInDevelopment";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import snackbarAtom from "@/Atoms/Snackbar";
import { debounceAsync } from "@/Utils/DebounceAPI";
import innerTabDataAtom from "@/Atoms/Plan/InnerTabDataAtom";
import {
  setInnerTabDataAtom,
  updatePlanDataMindmapAtom,
} from "../../InnerTabViewModel";
import {
  deletedNodesAtom,
  nodeDebounceTimersAtom,
  updatePartialNodesAtom,
} from "@/Atoms/Plan";
import { TFunction } from "i18next";

export const initializeNewData = (
  userId: number,
  id: string,
  label: string
) => {
  return {
    id: id,
    backendId: 0,
    userId: userId,
    label: label,
    startDate: null,
    endDate: null,
    content: "",
    isNewCreated: true,
    termType: null,
    termData: null,
    location: {
      address: "",
      latitude: null,
      longitude: null,
    } as LocationProps,
    taskStatus: [],
    hashtags: [],
  };
};

const nodeType = (type: string, t: TFunction) => {
  return t("plan.contents.mindmap.nodes.names." + type);
};

const initializeTaskNodeAtom = (userId: number, type: string, t: TFunction) => {
  const id = uuidv4();
  return {
    id: `${type}-${id}`,
    type: type,
    position: { x: 0, y: 0 },
    data: initializeNewData(
      userId,
      `${type}-${id}`,
      t("plan.contents.mindmap.nodes.nodeInput", {
        nodeType: nodeType(type, t),
      })
    ),
    measured: {
      width: 410,
      height: 176,
    },
  };
};

export const initializeTaskNodeWithPositionAtom = (
  userId: number,
  type: string,
  position: { x: number; y: number },
  t: TFunction
) => {
  const id = uuidv4();
  return {
    id: `${type}-${id}`,
    type: type,
    position: position,
    // data: initializeNewData(userId, `${type}-${id}`, `${nodeType(type)}을 입력해주세요.`),
    data: initializeNewData(
      userId,
      `${type}-${id}`,
      t("plan.contents.mindmap.nodes.nodeInput", {
        nodeType: nodeType(type, t),
      })
    ),
    measured: {
      width: 410,
      height: 176,
    },
  };
};

// 클릭해서 노드를 생성하는 메서드
export const handleClickCreateNodeAtom = atom(
  null,
  async (
    get,
    set,
    type: string,
    position: { x: number; y: number },
    t: TFunction
  ) => {
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const plan = planData.find(
      (planData) => planData.innerTabId === innerTabId
    );
    if (!plan) return;
    const newNode = initializeTaskNodeWithPositionAtom(
      get(getUserId),
      type,
      position,
      t
    );

    try {
      const response = await handleReactQueryApiResponse(
        createTask,
        () => set(error401ModalAtom, true),
        innerTabId,
        newNode
      );
      if (!response.ok) {
        set(snackbarAtom, (prev) => ({
          ...prev,
          open: true,
          message: "생성에 실패했습니다.",
          severity: "error",
        }));
        return;
      }
      const responseJson = await response.json();
      const updateNewNode = {
        ...newNode,
        data: {
          ...newNode.data,
          backendId: responseJson.backendId,
        },
      };

      const newNodes = [...plan.nodes, updateNewNode];
      set(setInnerTabDataAtom, {
        innerTabId,
        nodes: newNodes,
        edges: plan.edges,
      });
    } catch (error) {
      set(snackbarAtom, (prev) => ({
        ...prev,
        open: true,
        message: "생성에 실패했습니다.",
        severity: "error",
      }));
    }
  }
);

export const debouncedUpdatePlanAtom = debounceAsync(async (set) => {
  set(updatePlanDataAtom);
}, 1000); // 1초 동안 변화가 없을 때만 업데이트 실행

// 단일 task 업데이트 함수
export const updateTaskAtom = atom(
  null,
  async (get, set, updatedNode: Node) => {
    await handleReactQueryApiResponse(
      updateTask,
      () => set(error401ModalAtom, true),
      updatedNode
    );
  }
);

// debounce task update
export const debouncedUpdateTaskAtom = async (
  get: Getter,
  set: Setter,
  updatedNode: Node
) => {
  const nodeDebouncedTimers = get(nodeDebounceTimersAtom);

  if (nodeDebouncedTimers.has(updatedNode.id)) {
    clearTimeout(nodeDebouncedTimers.get(updatedNode.id));
  }

  const timer = setTimeout(async () => {
    await set(updateTaskAtom, updatedNode);
    nodeDebouncedTimers.delete(updatedNode.id);
  }, 1000);

  nodeDebouncedTimers.set(updatedNode.id, timer);
};

// 복수의 task 업데이트 함수
export const updateTasksAtom = atom(
  null,
  async (get, set, updatedNodes: Node[]) => {
    if (updatedNodes.length === 0) return;
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    await handleReactQueryApiResponse(
      updatePartialTasks,
      () => set(error401ModalAtom, true),
      innerTabId,
      updatedNodes
    );
  }
);

// debounce tasks update
export const debouncedUpdateTasksAtom = debounceAsync(
  async (get: Getter, set: Setter) => {
    const nodesToUpdate = get(updatePartialNodesAtom);
    // deletedNodesAtom에 있는 노드는 업데이트 대상에서 제외
    const deletedNodes = get(deletedNodesAtom);
    const nodesToUpdateFiltered = nodesToUpdate.filter(
      (node) => !deletedNodes.has(node.data.backendId as number)
    );

    if (nodesToUpdateFiltered.length > 0) {
      await set(updateTasksAtom, nodesToUpdateFiltered);
      set(updatePartialNodesAtom, []); // 전송 후 초기화
    }
  },
  1000
);

export const onNodesChangeAtom = atom(
  (get) => {
    const innerTabData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const plan = innerTabData.find(
      (planData) => planData.innerTabId === innerTabId
    );
    if (!plan) return [];
    return plan.nodes;
  },
  async (get, set, changes: NodeChange<Node>[]) => {
    const selectedNode = get(selectedNodeAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const planData = get(innerTabDataAtom);
    const plan = planData.find(
      (planData) => planData.innerTabId === innerTabId
    );
    if (!plan) return;

    const groups = plan.nodes.filter((node) => node.type === "group");

    // 노드가 삭제되었고, 삭제 대상 노드가 선택된 노드일 경우에만 삭제
    if (changes.find((change) => change.type === "remove")) {
      const findChange = changes.find((change) => change.type === "remove");
      if (selectedNode && findChange && findChange.id === selectedNode.id) {
        if (selectedNode.data.backendId !== 0) {
          if (selectedNode.type === "group") {
            const childNodes = plan.nodes.filter(
              (node) => node.parentId === selectedNode.id
            );
            await handleReactQueryApiResponse(
              deleteNode,
              () => set(error401ModalAtom, true),
              [
                selectedNode.data.backendId as number,
                ...childNodes.map((node) => node.data.backendId as number),
              ],
              innerTabId,
              get(getUserId)
            );
            set(deletedNodesAtom, (prev) => {
              const newSet = new Set(prev);
              const deletedNodes = [
                selectedNode.data.backendId as number,
                ...childNodes.map((node) => node.data.backendId as number),
              ];
              deletedNodes.forEach((nodeId) => newSet.add(nodeId));
              return newSet;
            });
          } else {
            const connectedEdges = plan.edges
              .filter(
                (edge) =>
                  edge.source === selectedNode.id ||
                  edge.target === selectedNode.id
              )
              .map((edge) => edge.data?.backendId as number);
            await handleReactQueryApiResponse(
              deleteNodesAndEdges,
              () => set(error401ModalAtom, true),
              [selectedNode.data.backendId as number],
              connectedEdges,
              innerTabId,
              get(getUserId)
            );
            set(deletedNodesAtom, (prev) => {
              const newSet = new Set(prev);
              newSet.add(selectedNode.data.backendId as number);
              return newSet;
            });
          }
        }
        set(selectedNodeAtom, null);
        const updatedNodes = applyNodeChanges(changes, plan.nodes);
        const groupNodes = updatedNodes.filter((n) => n.type === "group");
        const otherNodes = updatedNodes.filter((n) => n.type !== "group");
        set(setInnerTabDataAtom, {
          innerTabId,
          nodes: [...groupNodes, ...otherNodes],
          edges: plan.edges,
        });
      }
      return;
    }

    if (
      changes.find((change) => change.type === "position") &&
      groups.length > 0
    ) {
      const changeNode = changes.find((change) => change.type === "position");
      const node = plan.nodes.find((n) => n.id === changeNode?.id);

      if (!node) return;

      if (node.type === "group") {
        const updatedNodes = applyNodeChanges(changes, plan.nodes);
        const groupNodes = updatedNodes.filter((n) => n.type === "group");
        const otherNodes = updatedNodes.filter((n) => n.type !== "group");
        set(setInnerTabDataAtom, {
          innerTabId,
          nodes: [...groupNodes, ...otherNodes],
          edges: plan.edges,
        });
        return;
      }

      const { x: nodeX, y: nodeY } = node.position;
      const { width: nodeWidth, height: nodeHeight } = node.measured || {
        width: 0,
        height: 0,
      };
      if (!nodeWidth || !nodeHeight) return;

      const parentGroup =
        groups.find((group) => group.id === node.parentId) || null;

      // 노드 경계 계산
      const nodeBounds = {
        minX: parentGroup ? parentGroup.position.x + nodeX : nodeX,
        maxX: parentGroup
          ? parentGroup.position.x + nodeX + nodeWidth
          : nodeX + nodeWidth,
        minY: parentGroup ? parentGroup.position.y + nodeY : nodeY,
        maxY: parentGroup
          ? parentGroup.position.y + nodeY + nodeHeight
          : nodeY + nodeHeight,
      };

      for (const group of groups) {
        const { x: groupX, y: groupY } = group.position;
        const { width: groupWidth, height: groupHeight } = group.measured || {
          width: 0,
          height: 0,
        };
        if (!groupWidth || !groupHeight) continue;

        const groupBounds = {
          minX: groupX,
          maxX: groupX + groupWidth,
          minY: groupY,
          maxY: groupY + groupHeight,
        };

        // 노드의 일부라도 그룹 내에 포함되는지 확인
        const isWithinGroup =
          nodeBounds.maxX > groupBounds.minX &&
          nodeBounds.minX < groupBounds.maxX &&
          nodeBounds.maxY > groupBounds.minY &&
          nodeBounds.minY < groupBounds.maxY;

        if (isWithinGroup && changeNode?.dragging) {
          let newGroup = {
            ...group,
            style: {
              ...group.style,
              filter: "drop-shadow(0 0 10px rgba(0,0,0,0.5))",
              isActive: true,
            },
          };
          if (node.parentId === group.id) {
            // 그룹 내 노드가 이동 중일 때, 그룹 영역에서 벗어날 경우, 그룹 영역 확장
            const newGroupBounds = {
              minX: Math.min(groupBounds.minX, nodeBounds.minX),
              maxX: Math.max(groupBounds.maxX, nodeBounds.maxX),
              minY: Math.min(groupBounds.minY, nodeBounds.minY),
              maxY: Math.max(groupBounds.maxY, nodeBounds.maxY),
            };

            const newGroupPosition = {
              x: newGroupBounds.minX,
              y: newGroupBounds.minY,
            };

            const newGroupSize = {
              width: newGroupBounds.maxX - newGroupBounds.minX,
              height: newGroupBounds.maxY - newGroupBounds.minY,
            };
            newGroup = {
              ...newGroup,
              width: newGroupSize.width,
              height: newGroupSize.height,
              position: newGroupPosition,
              measured: newGroupSize,
            };
          }

          const updatedNodes = applyNodeChanges(changes, plan.nodes).map((n) =>
            n.id === group.id ? newGroup : n
          );
          const groupNodes = updatedNodes.filter((n) => n.type === "group");
          const otherNodes = updatedNodes.filter((n) => n.type !== "group");
          set(setInnerTabDataAtom, {
            innerTabId,
            nodes: [...groupNodes, ...otherNodes],
            edges: plan.edges,
          });
        } else if (isWithinGroup && node.parentId !== group.id) {
          // 그룹 내 노드를 추가하고 그룹 크기 확장이 아닌, 노드가 그룹 내부에 포함되도록 이동 조정
          let newNodeX =
            nodeBounds.minX < groupBounds.minX
              ? groupBounds.minX
              : nodeBounds.maxX > groupBounds.maxX
              ? groupBounds.maxX - nodeWidth
              : nodeX;
          let newNodeY =
            nodeBounds.minY < groupBounds.minY
              ? groupBounds.minY
              : nodeBounds.maxY > groupBounds.maxY
              ? groupBounds.maxY - nodeHeight
              : nodeY;

          // 그룹의 스타일 업데이트
          const newGroup = {
            ...group,
            style: {
              ...group.style,
              filter: "none",
              isActive: false,
            },
          };

          // 그룹 내 상대적인 위치로 노드를 설정
          const newNode = {
            ...node,
            position: {
              x: newNodeX - groupX,
              y: newNodeY - groupY,
            },
            parentId: group.id,
          };

          const updatedNodes = applyNodeChanges(changes, plan.nodes)
            .map((n) => (n.id === group.id ? newGroup : n))
            .map((n) => (n.id === node.id ? newNode : n));
          const groupNodes = updatedNodes.filter((n) => n.type === "group");
          const otherNodes = updatedNodes.filter((n) => n.type !== "group");
          set(setInnerTabDataAtom, {
            innerTabId,
            nodes: [...groupNodes, ...otherNodes],
            edges: plan.edges,
          });

          return;
        } else {
          const newGroup = {
            ...group,
            style: {
              ...group.style,
              filter: "none",
            },
          };
          const updatedNodes = applyNodeChanges(changes, plan.nodes).map((n) =>
            n.id === group.id ? newGroup : n
          );
          const groupNodes = updatedNodes.filter((n) => n.type === "group");
          const otherNodes = updatedNodes.filter((n) => n.type !== "group");
          set(setInnerTabDataAtom, {
            innerTabId,
            nodes: [...groupNodes, ...otherNodes],
            edges: plan.edges,
          });
        }
      }
    } else if (changes.find((change) => change.type === "dimensions")) {
      const dimensionsChange = changes.find(
        (change) => change.type === "dimensions"
      );
      if (dimensionsChange && dimensionsChange.id.includes("group")) {
        const updatedNodes = applyNodeChanges(changes, plan.nodes);
        const groupNodes = updatedNodes.filter((n) => n.type === "group");
        const otherNodes = updatedNodes.filter((n) => n.type !== "group");
        set(setInnerTabDataAtom, {
          innerTabId,
          nodes: [...groupNodes, ...otherNodes],
          edges: plan.edges,
        });
        const modifiedNodes = changes
          .filter((change) => change.type !== "add") // 관심 있는 변경 타입 필터링
          .map((change) => updatedNodes.find((node) => node.id === change.id)) // 변경된 노드 찾기
          .filter((node) => node !== undefined) as Node[];
        set(updatePartialNodesAtom, (prevNodes) => {
          const nodeMap = new Map(prevNodes.map((node) => [node.id, node]));
          modifiedNodes.forEach((node) => {
            nodeMap.set(node.id, node); // 최신 값으로 갱신
          });
          return Array.from(nodeMap.values());
        });
        if (changes.find((change) => change.type === "select") === undefined) {
          debouncedUpdateTasksAtom(get, set);
        }
        return;
      }
    }
    if (changes.find((change) => change.type === "dimensions") === undefined) {
      const updatedNodes = applyNodeChanges(changes, plan.nodes);
      const groupNodes = updatedNodes.filter((n) => n.type === "group");
      const otherNodes = updatedNodes.filter((n) => n.type !== "group");
      set(setInnerTabDataAtom, {
        innerTabId,
        nodes: [...groupNodes, ...otherNodes],
        edges: plan.edges,
      });
      const modifiedNodes = changes
        .filter((change) => change.type !== "add") // 관심 있는 변경 타입 필터링
        .map((change) => updatedNodes.find((node) => node.id === change.id)) // 변경된 노드 찾기
        .filter((node) => node !== undefined) as Node[];
      set(updatePartialNodesAtom, (prevNodes) => {
        const nodeMap = new Map(prevNodes.map((node) => [node.id, node]));
        modifiedNodes.forEach((node) => {
          nodeMap.set(node.id, node); // 최신 값으로 갱신
        });
        return Array.from(nodeMap.values());
      });
      if (changes.find((change) => change.type === "select") === undefined) {
        debouncedUpdateTasksAtom(get, set);
      }
    }
  }
);

export const onEdgesChangeAtom = atom(
  (get) => {
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const plan = planData.find(
      (planData) => planData.innerTabId === innerTabId
    );
    if (!plan) return [];
    return plan.edges;
  },
  async (get, set, changes: EdgeChange<Edge>[]) => {
    const selectedEdge = get(selectedEdgeAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const planData = get(innerTabDataAtom);
    const plan = planData.find(
      (planData) => planData.innerTabId === innerTabId
    );
    if (!plan) return;

    // 엣지가 삭제되었고, 삭제 대상 엣지가 선택된 엣지일 경우에만 삭제
    if (changes[0].type === "remove") {
      // 선택된 엣지가 있고, 삭제하려는 엣지와 선택된 엣지가 같을 경우에만 삭제
      if (selectedEdge && changes[0].id === selectedEdge.id) {
        if (selectedEdge.data?.backendId !== 0) {
          await handleReactQueryApiResponse(
            deleteEdge,
            () => set(error401ModalAtom, true),
            [selectedEdge.data?.backendId as number],
            innerTabId
          ); // 백엔드에서 엣지 삭제
        }
        set(selectedEdgeAtom, null); // 선택된 엣지를 null로 설정
        set(setInnerTabDataAtom, {
          innerTabId,
          nodes: plan.nodes,
          edges: applyEdgeChanges(changes, plan.edges),
        });
      }
      return;
    } else {
      // 삭제 이외의 경우 처리
      set(setInnerTabDataAtom, {
        innerTabId,
        nodes: plan.nodes,
        edges: applyEdgeChanges(changes, plan.edges),
      });
    }
  }
);

export const onConnectAtom = atom(
  null,
  async (get, set, params: Connection) => {
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const plan = planData.find(
      (planData) => planData.innerTabId === innerTabId
    );
    if (!plan) return;
    const newEdge = {
      ...params,
      id: `${params.sourceHandle || params.source}-${
        params.targetHandle || params.target
      }-${new Date().getTime()}`,
      data: {
        backendId: 0,
      },
      source: params.target,
      target: params.source,
      sourceHandle: `${params.targetHandle?.replace("target", "")}source`,
      targetHandle: `${params.sourceHandle?.replace("source", "")}target`,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
        color: process.env.REACT_APP_MAIN_COLOR,
      },
      style: {
        strokeWidth: 3,
        stroke: theme.colors.primary,
      },
      type: "smoothstep",
    } as Edge;
    try {
      const nodes = get(onNodesChangeAtom);
      const edges = get(onEdgesChangeAtom);
      const sourceNode = nodes.find((node) => node.id === params.source);
      const targetNode = nodes.find((node) => node.id === params.target);
      if (!sourceNode || !targetNode) return;
      const sourceBackendId = sourceNode.data.backendId as number;
      const requestStatuses = [] as {
        upper_task_id: number;
        lower_task_ids: number[];
      }[];

      // targetNode를 통해 연결된 상위 노드들을 찾음
      // 상위 노드를 탐색하는 재귀 함수
      const findParentNodes = async (node: Node, visited: Set<string>) => {
        if (visited.has(node.id)) return; // 이미 방문한 노드는 스킵
        visited.add(node.id);

        // 해당 노드의 상위 노드들을 찾음
        const parentEdges = edges.filter((edge) => edge.target === node.id);
        const parentNodes = parentEdges
          .map((edge) => nodes.find((n) => n.id === edge.source))
          .filter(Boolean) as Node[];

        for (const parentNode of parentNodes) {
          if (parentNode.type === "task") {
            // 상위 노드가 "task"이면 재귀 호출
            findParentNodes(parentNode, visited);
          } else if (parentNode.type === "ultimate") {
            // 상위 노드가 "ultimate"일 경우 taskStatus 수정 요청을 추가
            const requestBody = {
              upper_task_id: node.data.backendId as number,
              lower_task_ids: [node.data.backendId as number, sourceBackendId],
            };
            requestStatuses.push(requestBody);
          }
        }
      };

      // 탐색 시작
      const visitedNodes = new Set<string>();
      findParentNodes(targetNode, visitedNodes);

      // newEdge를 백엔드에 추가
      const response = await handleReactQueryApiResponse(
        createEdge,
        () => set(error401ModalAtom, true),
        innerTabId,
        newEdge,
        requestStatuses
      );
      if (!response.ok) {
        set(snackbarAtom, (prev) => ({
          ...prev,
          open: true,
          message: "연결에 실패했습니다.",
          severity: "error",
        }));
        return;
      }
      const responseJson = await response.json();
      const responseBackendId = responseJson.relationId as number;
      const responnseNodes = responseJson.nodes as Node[];
      const updatedEdge = {
        ...newEdge,
        data: {
          backendId: responseBackendId,
        },
      };
      const updatedNodes = nodes.map(
        (node) => responnseNodes.find((n) => n.id === node.id) || node
      );
      const newEdges = addEdge(updatedEdge, edges);

      set(setInnerTabDataAtom, {
        innerTabId,
        nodes: updatedNodes,
        edges: newEdges,
      });
      // update
      // set(updatePlanDataMindmapAtom, innerTabId, plan.nodes, newEdges);
    } catch (error) {
      set(snackbarAtom, (prev) => ({
        ...prev,
        open: true,
        message: "연결에 실패했습니다.",
        severity: "error",
      }));
    }
  }
);

export const onDragStartAtom = atom(
  null,
  (get, set, event: React.DragEvent<HTMLElement>, nodeType: string) => {
    set(typeAtom, nodeType);
    event.dataTransfer.effectAllowed = "move";
  }
);

export const onDragOverAtom = atom(null, (get, set, event: any) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
});

export const onDropAtom = atom(
  null,
  async (get, set, event: any, screenToFlowPosition: any, t: TFunction) => {
    const type = get(typeAtom) as keyof typeof typeMap;
    event.preventDefault();
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const plan = planData.find(
      (planData) => planData.innerTabId === innerTabId
    );
    if (!plan) return;

    if (!type) {
      return;
    }

    const position: { x: number; y: number } = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    const typeMap = (type: string) => {
      switch (type) {
        case "group":
          return initializeGroupNodeAtom(get(getUserId), {
            x: position.x,
            y: position.y,
          });
        // case "curriculum":
        //   return initializeCurriculumNodeAtom(get(getUserId), type, t);
        default:
          return initializeTaskNodeAtom(get(getUserId), type, t);
      }
    };

    const selectedNode = typeMap(type);

    if (selectedNode) {
      const newId = `${type}-${uuidv4()}`;

      const newNode = {
        ...selectedNode,
        id: `${newId}`,
        position: position,
        data: {
          ...selectedNode.data,
          id: `${newId}`,
          isNewCreated: true,
          termType: null,
          termData: null,
          location: {
            address: "",
            latitude: null,
            longitude: null,
          },
        },
      };

      try {
        const response = await handleReactQueryApiResponse(
          createTask,
          () => set(error401ModalAtom, true),
          innerTabId,
          newNode
        );
        if (!response.ok) {
          set(snackbarAtom, (prev) => ({
            ...prev,
            open: true,
            message: "생성에 실패했습니다.",
            severity: "error",
          }));
          return;
        }
        const responseJson = await response.json();
        const updateNewNode = {
          ...newNode,
          data: {
            ...newNode.data,
            backendId: responseJson.backendId,
          },
        };

        const newNodes = [...plan.nodes, updateNewNode];
        set(setInnerTabDataAtom, {
          innerTabId,
          nodes: newNodes,
          edges: plan.edges,
        });
      } catch (error) {
        set(snackbarAtom, (prev) => ({
          ...prev,
          open: true,
          message: "생성에 실패했습니다.",
          severity: "error",
        }));
      }
    }
  }
);

export const updateNodeAtom = atom(null, (get, set, updatedNode: any) => {
  // 기존 노드 상태를 가져옴
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((planData) => planData.innerTabId === innerTabId);
  if (!plan) return;
  const currentNodes = plan.nodes;
  const currentNode = currentNodes.find((node) => node.id === updatedNode.id);
  if (!currentNode) return;

  const newUpdateNode = {
    ...currentNode,
    data: {
      ...currentNode.data,
      ...updatedNode,
      isNewCreated: false,
    },
  };

  // 특정 노드를 업데이트
  const updatedNodes = currentNodes.map((node: Node) => {
    if (node.id === updatedNode.id) {
      return newUpdateNode;
    }
    return node;
  });

  // 업데이트된 노드를 다시 저장
  set(setInnerTabDataAtom, {
    innerTabId,
    nodes: updatedNodes,
    edges: plan.edges,
  });

  debouncedUpdateTaskAtom(get, set, newUpdateNode);
});

export const getCareerPathAtom = atom(null, (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((planData) => planData.innerTabId === innerTabId);
  if (!plan) return;
  const nodes = plan.nodes;
  const edges = plan.edges;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  if (!id) {
    devConsoleError("No mindmap id found");
    return;
  }

  const nodesData: NodeData[] = nodes.map((node) => ({
    id: node.id,
    type: node.type || "",
    activate: node.data.activate as number,
  }));
  const edgesData: EdgeData[] = edges.map((edge) => ({
    id: edge.id,
    source: edge.source,
    target: edge.target,
  }));

  // 경로 계산 후 atom 상태로 저장
  const deepestPath = getLongestPath(nodesData, edgesData);

  // 계산된 경로를 deepestPathAtom에 저장
  set(careerPathAtom, {
    id: Number(id),
    path: deepestPath.map((item) => item.id),
  });
  const notIncludedEdges = edgesData.filter(
    (edge) => !deepestPath.map((item) => item.id).includes(edge.id)
  );
});

export const refreshCareerPathAtom = atom(null, (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((planData) => planData.innerTabId === innerTabId);
  if (!plan) return;
  const edges = plan.edges;
  const careerPath = get(careerPathAtom);

  if (!careerPath) {
    return;
  }

  const notIncludedEdges = edges.filter(
    (edge) => !careerPath.path.includes(edge.id)
  );
  // notIncludedEdges.forEach((item) => {
  //   careerPathChangeEdge(item.id, theme.colors.primary, (fn) => set(edgesAtom, fn));
  // });

  set(careerPathAtom, null);
});

export const nodeClickAtom = atom(
  null,
  (get, set, event: React.MouseEvent, node: Node) => {
    const selectedNode = get(selectedNodeAtom);
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const plan = planData.find(
      (planData) => planData.innerTabId === innerTabId
    );
    if (!plan) return;

    if (selectedNode && selectedNode.id === node.id) {
      const updatedPlanData = plan.nodes.map((n) => {
        if (n.id === node.id) {
          return {
            ...n,
            data: {
              ...n.data,
              isNewCreated: false,
            },
          };
        }
        return n;
      });
      set(setInnerTabDataAtom, {
        innerTabId,
        nodes: updatedPlanData,
        edges: plan.edges,
      });
      set(selectedNodeAtom, null);
    } else {
      set(selectedNodeAtom, node);
      // const nodeId = node.id.split("-")[0];
      const nodeType = (node.type as string) || "task";
      const updatedPlanData = plan.edges.map((e) => {
        if (e.source === node.id || e.target === node.id) {
          return {
            ...e,
            style: {
              stroke: theme.colors[nodeType] || theme.colors.customPurple,
              strokeWidth: 3,
            },
            animated: false,
          };
        }
        return {
          ...e,
          style: { stroke: theme.colors.primary, strokeWidth: 3 },
          animated: false,
        };
      });
      set(setInnerTabDataAtom, {
        innerTabId,
        nodes: plan.nodes,
        edges: updatedPlanData,
      });
      set(selectedEdgeAtom, null);
    }
  }
);

export const edgeClickAtom = atom(
  null,
  (get, set, event: React.MouseEvent, edge: Edge) => {
    const selectedEdge = get(selectedEdgeAtom); // 현재 선택된 엣지
    const selectedNode = get(selectedNodeAtom); // 현재 선택된 노드
    const planData = get(innerTabDataAtom);
    const innerTabId = GetIdFromQuerystring("inner_tab_id");
    const plan = planData.find(
      (planData) => planData.innerTabId === innerTabId
    );
    if (!plan) return;

    const isSelected = selectedEdge?.id === edge.id;

    // 엣지 선택/해제 처리
    // if (isSelected) {
    //   set(selectedEdgeAtom, null);
    // } else {
    set(selectedEdgeAtom, edge);
    // }

    const updatedPlanData = plan.edges.map((e) => {
      if (e.id === edge.id) {
        return {
          ...e,
          style: isSelected
            ? { stroke: theme.colors.primary, strokeWidth: 3 } // 선택 해제 시 기본 스타일로 복원
            : { stroke: "red", strokeWidth: 5, strokeDasharray: "10 10" }, // 선택 시 빨간색으로 변경
          animated: !isSelected,
        };
      }
      return e;
    });
    set(setInnerTabDataAtom, {
      innerTabId,
      nodes: plan.nodes,
      edges: updatedPlanData,
    });

    // 엣지가 선택된 경우, 노드를 선택 해제
    if (selectedNode) {
      set(selectedNodeAtom, null);
    }
  }
);

export const backgroundClickAtom = atom(null, (get, set) => {
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((planData) => planData.innerTabId === innerTabId);
  if (!plan) return;
  const updatedPlanData = {
    innerTabId: innerTabId,
    nodes: plan.nodes.map((n) => {
      return {
        ...n,
        data: {
          ...n.data,
          isNewCreated: false,
        },
      };
    }),
    edges: plan.edges.map((e) => {
      return {
        ...e,
        style: { stroke: theme.colors.primary, strokeWidth: 3 },
        animated: false,
      };
    }),
  };
  set(setInnerTabDataAtom, updatedPlanData);
  set(selectedNodeAtom, null);
  set(selectedEdgeAtom, null);
});

// 루틴 옵션 변경 함수
export const editRoutineIntervalsInNodeAtom = atom(null, (get, set) => {
  const editRoutineDialog = get(editRoutineIntervalsDialogAtom);
  const planData = get(innerTabDataAtom);
  const innerTabId = GetIdFromQuerystring("inner_tab_id");
  const plan = planData.find((planData) => planData.innerTabId === innerTabId);
  if (!plan) return;
  const nodes = plan.nodes;
  const editNode = nodes.find((node) => node.id === editRoutineDialog.open);
  if (!editNode || !editRoutineDialog.termData) return;

  // 업데이트 전 interval 정렬
  const weekDays = ["월", "화", "수", "목", "금", "토", "일"];
  editRoutineDialog.termData =
    editRoutineDialog.termType === 1
      ? editRoutineDialog.termData.sort(
          (a, b) => weekDays.indexOf(a) - weekDays.indexOf(b)
        )
      : (editRoutineDialog.termData = editRoutineDialog.termData.sort(
          (a, b) => parseInt(a) - parseInt(b)
        ));

  const updatedNode = {
    ...editNode,
    data: {
      ...editNode.data,
      termType: editRoutineDialog.termType,
      termData: editRoutineDialog.termData,
    },
  };
  const updatedNodes = nodes.map((n) =>
    n.id === updatedNode.id ? updatedNode : n
  );
  set(setInnerTabDataAtom, {
    innerTabId,
    nodes: updatedNodes,
    edges: plan.edges,
  });
  set(editRoutineIntervalsDialogAtom, {
    open: null,
    termType: null,
    termData: null,
  });
});

type EdgeInfo = {
  id: string;
  source: string;
  target: string;
} | null;

export const getClosestEdgeAtom = atom<null, [Node], EdgeInfo>(
  null,
  (get, set, node: Node): EdgeInfo => {
    if (!node || !node.id) return null;
    const store = useStoreApi();
    const { nodeLookup } = store.getState();
    const { getInternalNode } = useReactFlow();

    // 현재 노드의 InternalNode 가져오기
    const internalNode: InternalNode<Node> | null = getInternalNode(
      node.id
    ) as InternalNode<Node>;
    if (!internalNode) return null;

    // 가장 가까운 노드를 계산하여 `closestNode`에 저장
    const closestNode = Array.from(nodeLookup.values()).reduce(
      (
        res: { distance: number; node: InternalNode<Node> | null },
        n: InternalNode<Node>
      ) => {
        if (n.id !== internalNode.id) {
          const dx =
            n.internals.positionAbsolute.x -
            internalNode.internals.positionAbsolute.x;
          const dy =
            n.internals.positionAbsolute.y -
            internalNode.internals.positionAbsolute.y;
          const dist = Math.sqrt(dx * dx + dy * dy);

          // 150 이내의 거리로 가까운 노드를 탐색
          if (dist < res.distance && dist < 200) {
            return { distance: dist, node: n };
          }
        }
        return res;
      },
      { distance: Infinity, node: null }
    );

    if (!closestNode.node) return null;

    // 가까운 노드의 소스 여부에 따라 엣지 반환
    const closeNodeIsSource =
      closestNode.node.internals.positionAbsolute.x <
      internalNode.internals.positionAbsolute.x;

    return {
      id: closeNodeIsSource
        ? `${closestNode.node.id}-${node.id}`
        : `${node.id}-${closestNode.node.id}`,
      source: closeNodeIsSource ? closestNode.node.id : node.id,
      target: closeNodeIsSource ? node.id : closestNode.node.id,
    };
  }
);

// onNodeDrag atom
export const onNodeDragAtom = atom(null, (get, set, _, node: Node) => {
  const closeEdge = set(getClosestEdgeAtom, node);

  if (!closeEdge) {
    return; // closeEdge가 null이면 아무 작업도 하지 않음
  }

  // set(edgesAtom, (es) => {
  //   const nextEdges = es.filter((e) => e.className !== 'temp');

  //   if (
  //     closeEdge &&
  //     !nextEdges.find(
  //       (ne) =>
  //         ne.source === closeEdge.source && ne.target === closeEdge.target,
  //     )
  //   ) {
  //     // closeEdge.className = 'temp';
  //     nextEdges.push(closeEdge);
  //   }

  //   return nextEdges;
  // });
});

// onNodeDragStop atom
export const onNodeDragStopAtom = atom(null, (get, set, _, node: Node) => {
  const closeEdge = set(getClosestEdgeAtom, node);

  if (!closeEdge) {
    return; // closeEdge가 null이면 아무 작업도 하지 않음
  }

  // set(edgesAtom, (es) => {
  //   const nextEdges = es.filter((e) => e.className !== 'temp');

  //   if (
  //     closeEdge &&
  //     !nextEdges.find(
  //       (ne) =>
  //         ne.source === closeEdge.source && ne.target === closeEdge.target,
  //     )
  //   ) {
  //     nextEdges.push(closeEdge);
  //   }

  //   return nextEdges;
  // });
});
