import React from "react";
import * as S from "./styled";
import { IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import IeumLogo from "@assets/Images/ieum_logo.png";
import { useAtomValue, useSetAtom } from "jotai";
import planHipAtom, { PlanHipCrew } from "@/Atoms/PlanHip/Atom";
import { getTextColorBasedOnBackground } from "@/Utils/GetRandomColor";
import { moveToCrewAtom } from "@/ViewModels/PlanHip/ViewModel";
import useIsMobile from "@/Hooks/useIsMobile";

interface CrewsBoxProps {
  title: string;
  myCrews: boolean;
}

const CrewsBox = ({ title, myCrews }: CrewsBoxProps) => {
  const { isMobile } = useIsMobile();
  const crews: PlanHipCrew[] =
    useAtomValue(planHipAtom)[myCrews ? "myCrews" : "notMyCrews"];
  const ITEMS_PER_PAGE = isMobile ? 1 : 5;
  const totalRecomendedPages = Math.ceil(crews.length / ITEMS_PER_PAGE);
  const [currentPage, setCurrentPage] = React.useState(0);
  const moveCrew = useSetAtom(moveToCrewAtom);
  const navigate = useNavigate();
  const componentRef = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = React.useState(0);

  const crewsPerPage = myCrews
    ? crews.slice(
        currentPage * ITEMS_PER_PAGE,
        (currentPage + 1) * ITEMS_PER_PAGE
      )
    : crews;

  const nextPage = () => {
    if (currentPage + 1 < totalRecomendedPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  React.useEffect(() => {
    const handleResize = () => {
      if (componentRef.current) {
        setWidth(componentRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <S.Container ref={componentRef}>
      {myCrews && (
        <IconButton
          onClick={prevPage}
          disabled={currentPage === 0}
          sx={{ position: "absolute", top: "50%", left: "-10px" }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <S.Title>{title}</S.Title>
      <S.CrewsContainer>
        {crewsPerPage.map((crew, index) => (
          <S.CrewContainer
            key={index}
            onClick={() => moveCrew(crew.crewId, navigate)}
          >
            {/* <S.CrewLogo src={crew.imageUrl ? crew.imageUrl : IeumLogo} alt="crew" /> */}
            <S.CrewLogo
              src={
                crew.imageUrl
                  ? `${
                      process.env.REACT_APP_CAREER_PLANNER_PLAN_MEDIA_ADDRESS
                    }${crew.imageUrl.split("/media")[1]}`
                  : IeumLogo
              }
              alt="crew"
            />
            <Typography sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>
              {crew.name}
            </Typography>
            <Typography sx={{ fontSize: "0.8rem", fontWeight: "600" }}>
              {crew.content}
            </Typography>
            <Typography
              sx={{ width: "100%", fontSize: "0.7rem", fontWeight: "bold" }}
            >
              {crew.current}명 참여 / {crew.headCount}명 제한
            </Typography>

            <S.HashTags>
              {crew.hashtags &&
                crew.hashtags.map((tag, idx) => (
                  <S.HashTag
                    key={idx}
                    $color={crew.color}
                    style={{ color: getTextColorBasedOnBackground(crew.color) }}
                  >
                    #{tag}
                  </S.HashTag>
                ))}
            </S.HashTags>
          </S.CrewContainer>
        ))}
      </S.CrewsContainer>
      {myCrews && (
        <>
          <S.NumberOfPagesContainer>
            {Array.from({ length: totalRecomendedPages }).map((_, i) => (
              <S.PageNavigator
                key={i}
                $active={currentPage === i}
                onClick={() => setCurrentPage(i)}
              />
            ))}
          </S.NumberOfPagesContainer>
          <IconButton
            onClick={nextPage}
            disabled={currentPage === totalRecomendedPages - 1}
            sx={{ position: "absolute", top: "50%", right: "-10px" }}
          >
            <ChevronRightIcon />
          </IconButton>
        </>
      )}
    </S.Container>
  );
};

export default CrewsBox;
