import { TaskOrderInGanttChart } from "@/Atoms/Plan/RoutineAtom";

export const getTaskOrderByInnerTabId = async (access: string, innerTabId: number) => {
  const response = await fetch(`${process.env.REACT_APP_INNER_TAB_ADDRESS}/order/${innerTabId}`, {
    headers: {
      'accept': 'application/json',
      'Authorization': `Bearer ${access}`
    }
  });
  return response;
}

export const updateTaskOrder = async (access: string, order: TaskOrderInGanttChart[], innerTabId: number) => {
  const requestBody = order.map((task) => ({
    routine_id: task.routineId,
    type: task.type,
    inner_tab_order_id: task.innerTabOrderId,
    inner_tab_id: task.innerTabId,
    task_id: task.taskId,
    order: task.order,
    sub_order: task.subOrder
  }))
  const response = await fetch(`${process.env.REACT_APP_INNER_TAB_ADDRESS}/order/${innerTabId}`, {
    method: 'PUT',
    headers: {
      'accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access}`
    },
    body: JSON.stringify({
      order: requestBody
    })
  });
  return response;
}
