import { atom } from "jotai";
import { deleteAuthCookies, getCookie } from "../Utils/CookieUtils";
import { userAtom, UserGroupProps } from "../Atoms/UserAtom";
import {
  errorModalAtom,
  fromLandingPageInterviewDataAtom,
  initializeStateAtom,
  waitingModalAtom,
} from "../Atoms/RootAtom";
import { handleApiResponse } from "@/Utils/APIUtil";
import { getUserInfoWhenStart } from "@/Queries/AuthQueries";
import { openSignatureDialogAtom } from "./Signature/ViewModel";
import { getUserMembershipAtom } from "./UserViewModel";

export const checkCookieAndAutoLoginAtom = atom(
  (get) => get(initializeStateAtom),
  async (get, set) => {
    const refreshCookie = getCookie(
      process.env.REACT_APP_USER_REFRESH_COOKIE_ID
    );
    const accessCookie = getCookie(process.env.REACT_APP_USER_ACCESS_COOKIE_ID);
    if (refreshCookie && accessCookie) {
      const errorFunction = () => {
        deleteAuthCookies();
        set(errorModalAtom, {
          state: true,
          event: null,
          eventText: "",
          redirectUrl: "",
          text: "오류가 발생하셨습니다.\n서비스를 사용하시고 싶으시면 다시 로그인 해주세요.",
          title: "오류 발생",
        });
      };

      const successFunction = (results: any) => {
        const resultGroups =
          results.groups && results.groups.length > 0
            ? results.groups.map(
                (group: {
                  id: number;
                  group_name: string;
                  group_code: string;
                  current_head_count: number;
                  people: number;
                  subscription_end_date: string;
                  update_date: string;
                }) => ({
                  id: group.id,
                  groupName: group.group_name,
                  groupCode: group.group_code,
                  currentHeadCount: group.current_head_count,
                  maxHeadCount: group.people,
                  subscriptionEndDate: new Date(group.subscription_end_date),
                  updateDate: new Date(group.update_date),
                })
              )
            : [];
        set(userAtom, {
          id: results.id,
          email: results.user.username,
          image: results.profile_image ?? "",
          membership: results.membership,
          nickname: results.nickname,
          language: results.language ?? "KR",
          membership_end_date: new Date(results.end_date),
          groups: resultGroups,
        });

        if (new Date() > new Date(results.end_date)) {
          set(errorModalAtom, {
            state: true,
            event: () =>
              (window.location.href = process.env.REACT_APP_PARENT_PAY_ADDRESS),
            eventText: "플랜 갱신",
            redirectUrl: "",
            text: "플랜을 갱신해주세요.\n만료되었어요.",
            title: "플랜 만료",
          });
        }
      };

      const newAccessToken = await handleApiResponse(
        refreshCookie,
        set,
        () => getUserInfoWhenStart(accessCookie),
        () => errorFunction(),
        (results) => successFunction(results)
      );

      if (newAccessToken) {
        await handleApiResponse(
          null,
          set,
          () => getUserInfoWhenStart(newAccessToken),
          () => errorFunction(),
          (results) => successFunction(results)
        );
      }
      set(initializeStateAtom, true);
      const isFirst = localStorage.getItem("isFirst");
      const membership = get(getUserMembershipAtom);

      if (isFirst === null) {
        localStorage.setItem(
          "isFirst",
          membership === "mentee" ? "true" : "false"
        );
        set(openSignatureDialogAtom);
      }
    } else {
      // set(initializeStateAtom, true);
      // 개발용 코드
      // set(getUserByIdAtom, 97); // mentee
      // set(getUserByIdAtom, 79); // teacher
      // set(getUserByIdAtom, 78); // mentor
      // getUserByIdAtom이 안될 때
      // set(userAtom, {
      //   id: 97,
      //   nickname: "chanwoong test",
      //   image: ``,
      //   membership: "mentee",
      //   membership_end_date: new Date("2032-12-31"),
      //   birth_date: new Date("1995-06-23"),
      //   email: "chanwoong.jeong@epreep.com",
      // });
    }
  }
);

export const initializeLandingAppAtom = atom(null, (get, set) => {
  window.addEventListener("message", (event) => {
    const allowedOrigins =
      process.env.REACT_APP_PARENT_WEB_ADDRESSES.split(",");

    if (!allowedOrigins.includes(event.origin)) {
      return;
    }

    const quesAns = event.data?.quesAns;

    if (quesAns !== undefined) {
      set(fromLandingPageInterviewDataAtom, quesAns);
    }
  });
});

export const clearWaitingModalAtom = atom(null, (get, set) => {
  set(waitingModalAtom, {
    state: false,
    text: "잠시만 기다려주세요.",
  });
});
