import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import CustomColorButton from "@/Components/Common/Buttons/CustomColor";
import { useTranslation } from "react-i18next";

interface ConditionSelectionModalProps {
  open: boolean;
  conditions: string[];
  onClose: () => void;
  onSelect: (selectedCondition: string) => void;
}

const ConditionSelectionModal: React.FC<ConditionSelectionModalProps> = ({ open, conditions, onClose, onSelect }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>정렬</DialogTitle>
      <DialogContent sx={{ fontSize: "0.8rem" }}>
        <div>정렬 조건을 선택하세요.</div>
        <List>
          {conditions.map((condition) => (
            <ListItem key={condition} disablePadding>
              <ListItemButton onClick={() => onSelect(condition)}>
                <ListItemText primary={t(`plan.contents.routine.gantt.conditions.${condition}`)} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <CustomColorButton onClick={onClose} color="gray" text="취소" fontSize="0.8rem" />
      </DialogActions>
    </Dialog>
  );
};

export default ConditionSelectionModal;
