import { Task } from "gantt-task-react";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { Edge, Node } from "@xyflow/react";
import theme from "@/Styles/theme";
import { darken, lighten } from "polished";
import { TaskStatus } from "@/Types/Plan";
import { TaskOrderInGanttChart } from "@/Atoms/Plan/RoutineAtom";

dayjs.extend(minMax);

export interface GanttTask extends Task {
  order: TaskOrderInGanttChart;
  depth: number;
}

/** ✅ 루틴을 재귀적으로 정렬하는 함수 */
const processRoutineRecursively = (
  task: GanttTask,
  allTasks: GanttTask[],
  visitedTasks: Set<number> = new Set()
): GanttTask[] => {
  if (task.order.type !== "routine") {
    return [task]; // ✅ 일반 태스크는 그대로 반환
  }

  // ✅ 무한 루프 방지: 이미 방문한 루틴이면 리턴
  if (visitedTasks.has(task.order.taskId)) {
    // console.warn(`⚠️ 순환 참조 감지: ${task.order.taskId}, ${task.name}`);
    return [task]; // 중복 방지
  }
  visitedTasks.add(task.order.taskId);

  // ✅ 현재 루틴에 속한 task 및 하위 루틴 찾기
  const routineTasks = allTasks
    .filter((t) => t.order.routineId === task.order.taskId)
    .sort((a, b) => (a.order.subOrder ?? 0) - (b.order.subOrder ?? 0));

  const subRoutines = routineTasks.filter((t) => t.order.type === "routine");
  const nonRoutineTasks = routineTasks.filter(
    (t) => t.order.type !== "routine"
  );

  // ✅ 하위 루틴을 재귀적으로 정렬하여 추가 (순환 참조 방지)
  const subRoutineTasksWithChildren = subRoutines.flatMap((subRoutine) =>
    processRoutineRecursively(subRoutine, allTasks, visitedTasks)
  );

  return [task, ...subRoutineTasksWithChildren, ...nonRoutineTasks];
};

/** ✅ 루틴 및 태스크를 정렬하는 최종 함수 */
const buildRecursiveTaskHierarchy = (tasks: GanttTask[]): GanttTask[] => {
  const visitedTasks = new Set<number>(); // ✅ 방문한 루틴 추적

  return (
    tasks
      // .filter(
      //   (task) =>
      //     task.order.routineId === task.order.taskId ||
      //     task.order.type === "independent_task"
      // ) // ✅ 최상위 루틴 & 독립 태스크 필터링
      // .sort((a, b) => (a.order.order ?? 0) - (b.order.order ?? 0)) // ✅ order 기준 정렬
      .flatMap((task) => processRoutineRecursively(task, tasks, visitedTasks))
  ); // ✅ 재귀 정렬 실행
};

/** ✅ 루틴을 처리하는 재귀 함수 */
const processRoutineNode = (
  node: Node,
  edges: Edge[],
  nodes: Node[],
  taskOrder: TaskOrderInGanttChart[],
  expandGroupList: string[],
  tasks: GanttTask[],
  nonGroupTaskNodeIds: Set<string>,
  parentRoutineId: string | null,
  depth: number
) => {
  // ✅ 특정 루틴에 연결된 Task 찾기
  const childTasks = edges
    .filter((edge) => edge.source === node.id)
    .map((edge) => nodes.find((task) => task.id === edge.target))
    .filter((task): task is Node => task !== undefined);

  // ✅ StartDate 및 EndDate 설정
  const startDate =
    dayjs.min(
      childTasks.map((task) =>
        task.data.startDate ? dayjs(task.data.startDate as string) : dayjs()
      )
    ) || null;
  const endDate =
    dayjs.max(
      childTasks.map((task) =>
        task.data.endDate ? dayjs(task.data.endDate as string) : dayjs()
      )
    ) || null;

  // ✅ Progress 계산
  const childTaskStatuses = childTasks.flatMap((task) =>
    (task.data.taskStatus as TaskStatus[]).filter(
      (status) => status.routineId === status.taskId
    )
  );
  const completedTaskStatuses = childTaskStatuses.filter(
    (status) => status.status === 1
  );
  const progress =
    childTaskStatuses.length === 0
      ? 0
      : (completedTaskStatuses.length / childTaskStatuses.length) * 100;

  // ✅ 루틴 Task 생성
  const groupTask: Task = {
    id: node.id,
    name: (node.data.label as string) || "",
    start: startDate ? startDate.toDate() : new Date(),
    end: endDate ? endDate.toDate() : new Date(),
    progress: progress,
    type: "project",
    hideChildren: expandGroupList.includes(node.id),
    project: parentRoutineId ? parentRoutineId : undefined,
    styles: {
      progressColor: node.data.color
        ? darken(0.2, node.data.color as string)
        : theme.colors.darkPrimary,
      progressSelectedColor: node.data.color
        ? darken(0.3, node.data.color as string)
        : theme.colors.darkPrimary,
      backgroundColor: node.data.color
        ? (node.data.color as string)
        : theme.colors.primary,
      backgroundSelectedColor: node.data.color
        ? lighten(0.1, node.data.color as string)
        : theme.colors.primary,
    },
  };

  const groupOrder = taskOrder.find(
    (order) => order.taskId === node.data.backendId
  );

  if (groupOrder) {
    tasks.push({
      ...groupTask,
      order: groupOrder,
      depth: depth,
    });
  }

  childTasks.map((task) => {
    if (task.type === "routine") {
      processRoutineNode(
        task,
        edges,
        nodes,
        taskOrder,
        expandGroupList,
        tasks,
        nonGroupTaskNodeIds,
        node.id,
        depth + 1
      );
    } else {
      const unsetDate =
        task.data.startDate === null || task.data.endDate === null;
      const taskStatuses = (task.data.taskStatus as TaskStatus[]) || [];
      const myTaskStatuses = taskStatuses.filter(
        (status) => status.routineId === status.taskId
      );
      const completedTaskStatuses = myTaskStatuses.filter(
        (status) => status.status === 1
      );
      const progress =
        myTaskStatuses.length === 0
          ? 0
          : (completedTaskStatuses.length / myTaskStatuses.length) * 100;

      const childTask: Task = {
        id: task.id + "|" + node.id,
        name: unsetDate ? "" : (task.data.label as string) || "",
        start: task.data.startDate
          ? dayjs(task.data.startDate as string).toDate()
          : dayjs().toDate(),
        end: task.data.endDate
          ? dayjs(task.data.endDate as string).toDate()
          : dayjs().toDate(),
        progress: progress,
        type: "task",
        project: node.id,
        dependencies: [],
        isDisabled: false,
        styles: {
          progressColor:
            (node.data.color as string) || theme.colors.darkPrimary,
          progressSelectedColor: lighten(
            0.1,
            (node.data.color as string) || theme.colors.darkPrimary
          ),
          backgroundColor: unsetDate ? "none" : "gray",
        },
      };

      const order = taskOrder.find(
        (order) =>
          order.taskId === task.data.backendId &&
          order.routineId === node.data.backendId
      );
      if (order) {
        tasks.push({
          ...childTask,
          order: order,
          depth: depth + 1,
        });
      }
      nonGroupTaskNodeIds.add(task.id);
    }
  });

  // //✅ 루틴 내부의 루틴과 일반 태스크 분리
  // const routineChilds = childTasks.filter((task) => task.type === "routine");
  // const nonRoutineChilds = childTasks.filter((task) => task.type !== "routine");

  // // ✅ 루틴 내부에 루틴이 존재할 경우 재귀적으로 탐색
  // routineChilds.forEach((routineNode) => {
  //   processRoutineNode(
  //     routineNode,
  //     edges,
  //     nodes,
  //     taskOrder,
  //     expandGroupList,
  //     tasks,
  //     nonGroupTaskNodeIds,
  //     node.id,
  //     depth + 1
  //   );
  // });

  // // ✅ 루틴에 속한 일반 Task 추가
  // nonRoutineChilds.forEach((task) => {
  //   const unsetDate =
  //     task.data.startDate === null || task.data.endDate === null;
  //   const taskStatuses = (task.data.taskStatus as TaskStatus[]) || [];
  //   const myTaskStatuses = taskStatuses.filter(
  //     (status) => status.routineId === status.taskId
  //   );
  //   const completedTaskStatuses = myTaskStatuses.filter(
  //     (status) => status.status === 1
  //   );
  //   const progress =
  //     myTaskStatuses.length === 0
  //       ? 0
  //       : (completedTaskStatuses.length / myTaskStatuses.length) * 100;

  //   const childTask: Task = {
  //     id: task.id + "|" + node.id,
  //     name: unsetDate ? "" : (task.data.label as string) || "",
  //     start: task.data.startDate
  //       ? dayjs(task.data.startDate as string).toDate()
  //       : dayjs().toDate(),
  //     end: task.data.endDate
  //       ? dayjs(task.data.endDate as string).toDate()
  //       : dayjs().toDate(),
  //     progress: progress,
  //     type: "task",
  //     project: node.id,
  //     dependencies: [],
  //     isDisabled: false,
  //     styles: {
  //       progressColor: (node.data.color as string) || theme.colors.darkPrimary,
  //       progressSelectedColor: lighten(
  //         0.1,
  //         (node.data.color as string) || theme.colors.darkPrimary
  //       ),
  //       backgroundColor: unsetDate ? "none" : "gray",
  //     },
  //   };

  //   const order = taskOrder.find(
  //     (order) =>
  //       order.taskId === task.data.backendId &&
  //       order.routineId === node.data.backendId
  //   );
  //   if (order) {
  //     tasks.push({
  //       ...childTask,
  //       order: order,
  //       depth: depth + 1,
  //     });
  //   }
  //   nonGroupTaskNodeIds.add(task.id);
  // });
};

const findRecursiveParentNodes = (
  node: Node,
  edges: Edge[],
  nodes: Node[]
): Node[] => {
  const parentEdges = edges.filter((edge) => edge.target === node.id);
  const parentNodes = parentEdges
    .map((edge) => nodes.find((node) => node.id === edge.source))
    .filter((node): node is Node => node !== undefined);
  return parentNodes.flatMap((parentNode) => [
    ...findRecursiveParentNodes(parentNode, edges, nodes),
    parentNode,
  ]);
};

/** ✅ Nodes 배열을 Gantt의 Task 배열로 변환 */
const convertNodesToGanttTasks = (
  nodes: Node[],
  edges: Edge[],
  expandGroupList: string[],
  taskOrder: TaskOrderInGanttChart[]
): GanttTask[] => {
  const tasks: GanttTask[] = [];
  const groupNodes = nodes.filter((node) => node.type === "routine");
  const taskNodes = nodes.filter((node) => node.type === "task");
  const nonGroupTaskNodeIds = new Set<string>();

  // ✅ 루틴 Task 생성
  groupNodes.forEach((node) => {
    // 그룹 노드의 edge를 통해 상위 노드 모두 찾기
    const parentNodes = findRecursiveParentNodes(node, edges, nodes);
    if (parentNodes.some((parentNode) => parentNode.type === "routine")) {
      return;
    }
    processRoutineNode(
      node,
      edges,
      nodes,
      taskOrder,
      expandGroupList,
      tasks,
      nonGroupTaskNodeIds,
      null,
      0
    );
  });

  // ✅ 독립적인 Task 추가
  nodes
    .filter((task) => !nonGroupTaskNodeIds.has(task.id))
    .forEach((task, index) => {
      const order = taskOrder.find(
        (order) =>
          order.taskId === task.data.backendId && order.routineId === null
      );

      if (order) {
        tasks.push({
          id: task.id,
          name: (task.data.label as string) || "",
          start: task.data.startDate
            ? dayjs(task.data.startDate as string).toDate()
            : dayjs().toDate(),
          end: task.data.endDate
            ? dayjs(task.data.endDate as string).toDate()
            : dayjs().toDate(),
          progress: 0,
          type: "task",
          dependencies: [],
          isDisabled: false,
          styles: { backgroundColor: "gray" },
          order: order,
          depth: 0,
        });
      }
    });

  // task의 id가 중복인 경우 제거
  const uniqueTaskIds = new Set<string>();
  const uniqueTasks = tasks.filter((task) => {
    if (uniqueTaskIds.has(task.id)) {
      return false;
    }
    uniqueTaskIds.add(task.id);
    return true;
  });

  // // ✅ 루틴 ID별로 그룹핑 후 최적 정렬 수행
  const newTasks = buildRecursiveTaskHierarchy(uniqueTasks);

  return newTasks;
};

export default convertNodesToGanttTasks;
