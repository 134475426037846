import afterSchoolAtom, { Course, Fee, Student } from "@/Atoms/AfterSchool";
import createCourseDialogAtom, {
  ApplicationPeriodInfo,
  createCourseDialogInitializeState,
} from "@/Atoms/Dialogs/Create/Course";
import snackbarAtom from "@/Atoms/Snackbar";
import dayjs from "dayjs";
import { atom } from "jotai";
import { v4 as uuidv4 } from "uuid";

const parseSubsidyContentToJSON = (subsidyContent: string) => {
  const lines = subsidyContent.trim().split("\n");

  const students: Student[] = [];
  const fees: Fee[] = [];
  let isFeesSection = false;

  lines.forEach((line) => {
    const cells = line.trim().split(/\s+/); // Split by whitespace

    // Check if we're in the fees section based on the number of columns
    if (cells.length === 2 && cells[0] === "" && cells[1] === "") {
      isFeesSection = true;
    }

    if (isFeesSection) {
      // Parse the fees section
      if (cells.length === 2) {
        fees.push({
          studentsCount: parseInt(cells[0], 10),
          fee: parseInt(cells[1], 10),
        });
      }
    } else {
      // Parse the students section
      if (cells.length === 3) {
        students.push({
          studentId: cells[0],
          name: cells[1],
          freePass: cells[2],
        });
      }
    }
  });

  return {
    students,
    fees,
  };
};

export const createCourseDialogOpenAtom = atom(null, (get, set) => {
  set(createCourseDialogAtom, createCourseDialogInitializeState(true));
});

export const createCourseDialogCloseAtom = atom(null, (get, set) => {
  set(createCourseDialogAtom, createCourseDialogInitializeState(false));
});

// save course
export const createCourseDialogSaveAtom = atom(null, async (get, set) => {
  const course = get(createCourseDialogAtom);

  if (course.teacher === "") {
    set(snackbarAtom, {
      open: true,
      message: "담당교사를 입력해주세요.",
      severity: "error",
    });
    return;
  }

  if (course.courseName === "") {
    set(snackbarAtom, {
      open: true,
      message: "과정명을 입력해주세요.",
      severity: "error",
    });
    return;
  }

  // course의 operationTimes sort
  const sortedOperatingTimes = Object.fromEntries(
    Object.entries(course.operatingTimes).map(([day, times]) => [
      day,
      times.sort((a, b) => a - b),
    ])
  );

  const newCourse: Course = {
    ...course,
    id: uuidv4(),
    operatingTimes: sortedOperatingTimes,
    subsidy: parseSubsidyContentToJSON(course.subsidyContent),
    classes: [],
  };

  set(afterSchoolAtom, (prev) => ({
    ...prev,
    courses: [...prev.courses, newCourse],
  }));
  set(snackbarAtom, {
    open: true,
    message: "과정이 추가되었습니다.",
    severity: "success",
  });
  set(createCourseDialogAtom, createCourseDialogInitializeState(false));
});

// edit teacher
export const createCourseDialogTeacherAtom = atom(
  (get) => get(createCourseDialogAtom).teacher,
  (get, set, teacher: string) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      teacher,
    }));
  }
);

// application period
export const createCourseDialogApplicationPeriodAtom = atom(
  (get) => get(createCourseDialogAtom).applicationPeriod
);

// edit application period (start, end date)
export const createCourseDialogApplicationPeriodDateAtom = atom(
  null,
  (get, set, date: Date, type: "start" | "end") => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      applicationPeriod: {
        ...prev.applicationPeriod,
        [type]: {
          ...prev.applicationPeriod[type],
          date: dayjs(date).format("YYYY-MM-DD"),
        },
      },
    }));
  }
);

// edit application period (start, end hour and minute)
export const createCourseDialogApplicationPeriodTimeAtom = atom(
  null,
  (
    get,
    set,
    time: number,
    type: "start" | "end",
    option: "hour" | "minute"
  ) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      applicationPeriod: {
        ...prev.applicationPeriod,
        [type]: {
          ...prev.applicationPeriod[type],
          [option]: time,
        },
      },
    }));
  }
);

// edit application period detail
export const createCourseDialogApplicationPeriodDetailAtom = atom(
  null,
  (get, set, option: number) => {
    set(createCourseDialogAtom, (prev) => {
      const currentOptionValue =
        prev.applicationPeriod.detail[
          `option${option}` as keyof ApplicationPeriodInfo["detail"]
        ];
      return {
        ...prev,
        applicationPeriod: {
          ...prev.applicationPeriod,
          detail: {
            ...prev.applicationPeriod.detail,
            [`option${option}`]: !currentOptionValue,
          },
        },
      };
    });
  }
);

// edit application method
export const createCourseDialogApplicationMethodAtom = atom(
  (get) => get(createCourseDialogAtom).applicationMethod,
  (get, set, applicationMethod: 0 | 1 | 2 | 3 | 4 | 5) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      applicationMethod,
    }));
  }
);

// edit course period
export const createCourseDialogCoursePeriodAtom = atom(
  (get) => get(createCourseDialogAtom).coursePeriod,
  (get, set, date: Date, type: "startDate" | "endDate") => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      coursePeriod: {
        ...prev.coursePeriod,
        [type]: dayjs(date).format("YYYY-MM-DD"),
      },
    }));
  }
);

// edit course name
export const createCourseDialogCourseNameAtom = atom(
  (get) => get(createCourseDialogAtom).courseName,
  (get, set, courseName: string) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      courseName,
    }));
  }
);

// edit view option (isVisible)
export const createCourseDialogViewOptionIsVisibleAtom = atom(
  (get) => get(createCourseDialogAtom).viewOption.isVisible,
  (get, set, isVisible: boolean) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      viewOption: {
        ...prev.viewOption,
        isVisible,
      },
    }));
  }
);

// edit view option (grade)
export const createCourseDialogViewOptionGradeAtom = atom(
  (get) => get(createCourseDialogAtom).viewOption.grade,
  (get, set, grade: 0 | 1 | 2 | 3) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      viewOption: {
        ...prev.viewOption,
        grade,
      },
    }));
  }
);

// edit selection group
export const createCourseDialogSelectionGroupAtom = atom(
  (get) => get(createCourseDialogAtom).selectionGroup,
  (get, set, selectionGroup: string) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      selectionGroup,
    }));
  }
);

// edit operating times
export const createCourseDialogOperatingTimesAtom = atom(
  (get) => get(createCourseDialogAtom).operatingTimes,
  (get, set, day: string, time: number) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      operatingTimes: {
        ...prev.operatingTimes,
        [day]: prev.operatingTimes[day].includes(time)
          ? prev.operatingTimes[day].filter((t) => t !== time)
          : [...prev.operatingTimes[day], time],
      },
    }));
  }
);

// edit operating times (specific day)
export const createCourseDialogOperatingTimesDayAtom = atom(
  null,
  (get, set, day: string) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      operatingTimes: {
        ...prev.operatingTimes,
        [day]: Array.from({ length: 14 }, (_, i) => i),
      },
    }));
  }
);

// edit operating times (specific time)
export const createCourseDialogOperatingTimesTimeAtom = atom(
  null,
  (get, set, time: number) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      operatingTimes: Object.fromEntries(
        Object.entries(prev.operatingTimes).map(([day, times]) => [
          day,
          times.includes(time) ? times : [...times, time],
        ])
      ),
    }));
  }
);

// edit cost disclosure
export const createCourseDialogCostDisclosureAtom = atom(
  (get) => get(createCourseDialogAtom).isCostDisclosured,
  (get, set, isCostDisclosured: boolean) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      isCostDisclosured,
    }));
  }
);

// edit announcement
export const createCourseDialogAnnouncementAtom = atom(
  (get) => get(createCourseDialogAtom).announcement,
  (get, set, announcement: string) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      announcement,
    }));
  }
);

// edit subsidy content
export const createCourseDialogSubsidyContentAtom = atom(
  (get) => get(createCourseDialogAtom).subsidyContent,
  (get, set, subsidyContent: string) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      subsidyContent,
    }));
  }
);

// edit course evaluation
export const createCourseDialogCourseEvaluationAtom = atom(
  (get) => get(createCourseDialogAtom).courseEvaluation,
  (get, set, courseEvaluation: string) => {
    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      courseEvaluation,
    }));
  }
);

// add question about course evaluation
export const createCourseDialogCourseEvaluationAddQuestionAtom = atom(
  null,
  (get, set) => {
    const courseEvaluation = get(createCourseDialogAtom).courseEvaluation;

    // Correctly check for newlines and calculate the number of questions
    const numberOfQuestions = courseEvaluation
      ? courseEvaluation.split("\n").length - 1
      : 0;

    set(createCourseDialogAtom, (prev) => ({
      ...prev,
      courseEvaluation:
        prev.courseEvaluation +
        (numberOfQuestions + 1) +
        ". (필수)문항을 입력해주세요. ①: 매우 그렇다. ②: 그렇다. ③: 보통이다. ④: 그렇지 않다. ⑤: 전혀 그렇지 않다.\n",
    }));
  }
);
