import {
  chatRoomListAtom,
  createChatRoomSectionAtom,
  currentChatRoomAtom,
  MessageType,
} from "@/Atoms/ChatAtom";
import { atom } from "jotai";
import { sendMessageAtom } from "../ChatViewModel";
import {
  dokgabiChatModalAtom,
  initialDokgabiChatModal,
} from "@/Atoms/DokgabiChat/Atom";
import { handleReactQueryApiResponse } from "@/Utils/APIUtil";
import { getChatbotHistory } from "@/Queries/HelperQueries";
import { error401ModalAtom } from "@/Atoms/Dialogs/Error/401Atom";
import { getUserEmailAtom } from "../UserViewModel";

// 채팅방을 오픈하는 함수
export const openDokgabiChatModalAtom = atom(null, async (get, set) => {
  const chatRoomList = get(chatRoomListAtom);
  const createChatRoomSection = get(createChatRoomSectionAtom);
  if (chatRoomList.length !== 0 && !createChatRoomSection) {
    const currentChatRoom = get(currentChatRoomAtom);
    if (!currentChatRoom) return;
    if (currentChatRoom.messages.length === 0) {
      set(sendMessageAtom, "");
    }
    // set(chatDialogAtom, true);
  }
  set(dokgabiChatModalAtom, (prev) => ({ ...prev, isOpen: true }));
});

// 채팅방을 닫는 함수
export const closeDokgabiChatModalAtom = atom(null, (get, set) => {
  set(dokgabiChatModalAtom, initialDokgabiChatModal);
});

interface ChatbotHistoryProps {
  chattingUser: MessageType;
  date: string;
  image: string;
  name: string;
  text: string;
}

const parseChatHistory = (chatHistory: ChatbotHistoryProps[]) => {
  return chatHistory.map((chat, index) => {
    return {
      id: index,
      text: chat.text,
      type: chat.chattingUser,
      jobButtons: null,
      date: chat.date,
    };
  });
};

// 챗봇 대화방 이동 함수
export const dokgabiChatMoveChatRoomAtom = atom(
  null,
  async (get, set, chatRoomId: number) => {
    const chatRoomList = get(chatRoomListAtom);
    const handleError401Modal = () => set(error401ModalAtom, true);
    const selectedChatRoom = chatRoomList.find(
      (chatRoom) => chatRoom.id === chatRoomId
    );
    if (!selectedChatRoom) return;
    const result = await handleReactQueryApiResponse(
      getChatbotHistory,
      handleError401Modal,
      selectedChatRoom.chatbots[0].id,
      get(getUserEmailAtom),
      1
    );
    const newSelectedChatRoom = {
      ...selectedChatRoom,
      messages:
        selectedChatRoom.messages.length > parseChatHistory(result).length
          ? selectedChatRoom.messages
          : parseChatHistory(result),
      chatHistoryPageNum: selectedChatRoom.chatHistoryPageNum,
    };
    if (newSelectedChatRoom.messages.length === 0) {
      set(sendMessageAtom, "");
    }
    set(currentChatRoomAtom, newSelectedChatRoom);
    set(dokgabiChatModalAtom, (prev) => ({ ...prev, mode: "chat" }));
  }
);
